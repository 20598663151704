export type LocalizationKey =
  | AccessibilityMessage
  | AddMealsMessage
  | AdjacentSeatMessage
  | ArticlesMessage
  | B2BAdminCompanyInfoMessage
  | B2BAdminCreateCodesMessage
  | B2BAdminFormMessage
  | B2BAdminGenericMessage
  | B2BAdminInvoicingInfoMessage
  | B2BAdminRemoveCodesMessage
  | B2BAdminRemoveUserModalMessage
  | B2BAdminTravelCodeMessage
  | B2BAdminUsersMessage
  | BookingMessage
  | BookingMealsMessage
  | BreakfastMessage
  | BusConnectionsMessage
  | BusConnectionNotAvailableNoticesMessage
  | CabinsMessage
  | CancellationInsuranceMessage
  | CarbonCalculatorMessage
  | ChangeJourneyMessage
  | ChangePasswordMessage
  | ChangeYourPasswordMessage
  | CiamErrorsMessage
  | CompanyInformationFormSectionMessage
  | ConnectionListMessage
  | ConnectionListFilterMessage
  | ContentComponentsMessage
  | ContentPagesMessage
  | CookieConsentMessage
  | CustomerServiceFormMessage
  | DataProtectionFormMessage
  | DateSelectMessage
  | DiscountCodeMessage
  | DynamicMapMessage
  | EditPaymentReferencesMessage
  | ErrorsMessage
  | ExampleCarsMessage
  | FailedPurchaseFormMessage
  | FeedbackMessage
  | FooterMessage
  | FormsMessage
  | FormValidationMessage
  | FrontPageMessage
  | GeneralFeedbackFormMessage
  | GeneralFeedbackFormSubjectMessage
  | GeneralFeedbackFormTopicMessage
  | GeneralFeedbackFormTravelReasonMessage
  | GenericMessage
  | GroupSalesFormMessage
  | GroupSalesPassengerSelectStepMessage
  | GroupSalesPurchaseFlowMessage
  | GroupSalesStepNavMessage
  | GroupSeatPreferenceMessage
  | HeaderMessage
  | DynamicMapMessage
  | InvoicePaymentMessage
  | JourneyLegServicesMessage
  | JourneyPlannerMessage
  | JourneySearchMessage
  | JourneySummaryMessage
  | ReplacementInfoMessage
  | LoginMessage
  | MealCategoriesMessage
  | MealOptionsMessage
  | MultiTicketMessage
  | MultiTicketPurchaseFlowMessage
  | MyJourneyMessage
  | MyJourneysMessage
  | NewsArticlesMessage
  | NextAndPreviousDayButtonsMessage
  | NoticesMessage
  | OtherCompensationFormMessage
  | PassengerSummaryMessage
  | PassengerTypeLongInfoMessage
  | PassengerTypeMessage
  | PassengerTypeShortInfoMessage
  | PasswordMessage
  | PaymentConfirmationMessage
  | PaymentMethodMessage
  | PaymentNotificationsMessage
  | PaymentReceiptMessage
  | PlaceChangeMessage
  | PriceCalendarMessage
  | PriceSummaryMessage
  | ProductMessage
  | ProductTypeMessage
  | ProfileMessage
  | ProfilePurchaseFlowMessage
  | ProfileSelectionMessage
  | PurchaseChannelMessage
  | ReCaptchaMessage
  | ReceiptTypeMessage
  | RegistrationMessage
  | SalesSessionErrorsMessage
  | SeatMessage
  | SeatServiceDescriptionsMessage
  | SeriesTicketProductNameMessage
  | ServiceLongMessage
  | ServicesMessage
  | ServiceShortMessage
  | StationMessage
  | StationScheduleMessage
  | StationSearchMessage
  | StationServicesMessage
  | TicketCancellationFormMessage
  | TimetableSearchMessage
  | TrafficMessage
  | TrainDelayCompensationFormMessage
  | TrainMapMessage
  | TrainTypeMessage
  | TravelAgentMessage
  | UnitMessage
  | UpdateProfileMessage
  | VehicleJourneyMessage
  | VehicleTypeMessage
  | WagonMapMessage
  | B2bCustomerInviteFormMessage;

export enum ChangePasswordMessage {
  ERROR_DEFAULT = 'login.resetPassword.errors.default',
}

export enum UpdateProfileMessage {
  NOTIFICATION_SUCCESS_TITLE = 'profile.update.notifications.success.title',
  NOTIFICATION_ERROR_TITLE = 'profile.update.notifications.error.title',
}

export enum ChangeYourPasswordMessage {
  CIAM_DESCRIPTION = 'profile.password.ciam.description',
  CIAM_BUTTON_TEXT = 'profile.password.ciam.buttonText',
  CIAM_RESET_SUCCESS_TITLE = 'profile.password.ciam.success.title',
  CIAM_RESET_SUCCESS_BODY = 'profile.password.ciam.success.body',
  CIAM_RESET_FAIL_TITLE = 'profile.password.ciam.fail.title',
  CIAM_RESET_FAIL_BODY = 'profile.password.ciam.fail.body',
}

export enum MyJourneysMessage {
  TITLE = 'profile.myJourneys.title',
  ONGOING_JOURNEYS = 'myJourneys.ongoingJourneys',
  UPCOMING_JOURNEYS = 'myJourneys.upcomingJourneys',
  PAST_JOURNEYS = 'myJourneys.pastJourneys',
  CANCELLED_JOURNEYS = 'myJourneys.cancelledJourneys',
  MULTI_TICKETS = 'myJourneys.multiTickets',
  UNPAID_GROUP_TICKETS = 'myJourneys.unpaidGroupTickets',
  SHOW_RECEIPT = 'myJourneys.showReceipt',
  SHOW_JOURNEY = 'myJourneys.showJourney',
  VEHICLE = 'booking.vehicle',
  VEHICLE_PLURAL = 'booking.vehicle.plural',
  EMPTY_UPCOMING_JOURNEYS = 'myJourneys.placeholder.upcomingJourneys',
  FAILED_TO_LOAD_JOURNEY = 'myJourneys.failedToLoadJourney',
  FAILED_TO_LOAD_MULTI_TICKET = 'myJourneys.failedToLoadMultiTicket',
  SEASON_TICKET = 'myJourneys.seasonTicket',
  TRAVEL_PASS_TICKET = 'myJourneys.travelPassTicket',
  NAV_TITLE = 'profile.navTitle',
  SEARCH_ORDER_TITLE = 'myJourneys.searchOrder.title',
  SEARCH_ORDER_BUTTON = 'myJourneys.searchOrder.button',
  SEARCH_ORDER_NOT_FOUND = 'myJourneys.searchOrder.notFound',
  AVAILABLE_IN_MATKALLA = 'myJourneys.availableInMatkalla',
  WHOLE_FINLAND = 'myJourneys.travelPass.wholeFinland',
  NO_RESULTS = 'myJourneys.noResults',
  NO_TRIPS = 'myJourneys.noTrips',
  DEPARTURE_DATE_FILTER_BUTTON = 'myJourneys.departureDateFilter.button',
  DEPARTURE_DATE_FILTER_START_LABEL = 'myJourneys.departureDateFilter.startLabel',
  DEPARTURE_DATE_FILTER_END_LABEL = 'myJourneys.departureDateFilter.endLabel',
  DEPARTURE_DATE_FILTER_ADD_DATE_RANGE = 'myJourneys.departureDateFilter.addDateRange',
  DEPARTURE_DATE_FILTER_MODAL_TITLE_UPCOMING = 'myJourneys.departureDateFilter.modalTitle.upcoming',
  DEPARTURE_DATE_FILTER_MODAL_TITLE_PAST = 'myJourneys.departureDateFilter.modalTitle.past',
  DEPARTURE_DATE_FILTER_REMOVE = 'myJourneys.departureDateFilter.remove',
  SHOW_MY_TRIPS = 'myJourneys.showMyTrips',
  COMPANY_ID_SEARCH = 'myJourneys.companyIdSearch',
  TICKET_IMPORT_MODAL_TITLE = 'myJourney.ticketImport.modalTitle',
  TICKET_IMPORT_MODAL_DESCRIPTION = 'myJourney.ticketImport.modalDescription',
  IMPORT_TICKET = 'myJourney.ticketImport.importTicket',
  IMPORTING_TICKET_FAILED = 'myJourneys.ticketImport.importingTicketFailed',
  IMPORT_ERROR_TICKET_IS_ALREADY_LINKED_TO_ANOTHER_ACCOUNT = 'myJourneys.ticketImport.ticketAlreadyLinkedToAnotherAccount',
  IMPORT_ERROR_ORDER_NUMBER_AND_REFERENCE_NUMBER_WONT_MATCH = 'myJourneys.ticketImport.orderNumberAndPaymentReferenceNumberWontMatch',
  IMPORT_ERROR_UNKNOWN_ERROR = 'myJourneys.ticketImport.unknownImportError',
  NO_UPCOMING_TRIPS_DESCRPTION = 'myJourneys.noUpcomingTrips.description',
  BUY_TRIP = 'myJourneys.buyTrip',
  RADALLA_TITLE = 'myJourneys.radalla.title',
  RADALLA_BODY = 'myJourneys.radalla.text',
  RADALLA_BUTTON = 'myJourneys.radalla.button',
  MULTI_TICKET_AD_TITLE = 'myJourneys.multiticketAd.title',
  MULTI_TICKET_AD_TICKET_TYPE = 'myJourneys.multiticketAd.ticketType',
  MULTI_TICKET_AD_ORDER_NEW = 'myJourneys.multiticketAd.orderNew',
}

export enum MyJourneyMessage {
  ORDER_INFO = 'myJourney.orderInfo',
  ORDER_NUMBER = 'booking.orderNumber',
  RECEIPT = 'myJourney.receipt',
  RECEIPT_MISSING = 'myJourney.receiptMissing',
  SHARE_TICKETS = 'myJourney.shareTickets',
  SHARE_TICKETS_MESSAGE_TITLE = 'myJourney.shareTickets.messageTitle',
  SHARE_TICKETS_MESSAGE_BODY = 'myJourney.shareTickets.messageBody',
  SHARE_TICKETS_MODAL_TITLE = 'myJourney.shareTicketsModal.title',
  SHARE_TICKETS_MODAL_DESCRIPTION = 'myJourney.shareTicketsModal.description',
  SHOW_TICKETS = 'myJourney.showTickets',
  JOURNEY_OVERVIEW = 'myJourney.journeyOverview',
  ORDER_INFO_TICKET_TERMS = 'myJourney.orderInfo.ticketTerms',
  ORDER_INFO_RECEIPT = 'myJourney.orderInfo.receipt',
  SERIES_TICKETS = 'myJourney.seriesTicket',
  RESERVED_JOURNEYS = 'myJourney.reservedJourneys',
  RESERVED_JOURNEY = 'myJourney.reservedJourneys.journey',
  EMPTY_RESERVED_JOURNEYS = 'myJourney.placeholder.noReservedJourneys',
  SEAT_CLASS = 'booking.accessibility.seatClass',
  CANCEL_JOURNEY = 'myJourney.cancelJourney',
  CANCEL_VEHICLES = 'myJourney.cancelVehicles',
  REFUND_AMOUNT = 'myJourney.cancellation.refundAmount',
  CONFIRM_CANCELLATION = 'myJourney.cancellation.confirm',
  PICK_CANCELLATION = 'myJourney.cancellation.multiplePassengers.pickCancellation',
  JOURNEY_CANCELLATION_PICK_VEHICLES_PARAGRAPH = 'myJourney.cancellation.multiplePassengers.pickVehicleCancellations.paragraph',
  JOURNEY_CANCELLATION_PICK_VEHICLES_PARAGRAPH_PLURAL = 'myJourney.cancellation.multiplePassengers.pickVehicleCancellations.paragraph.plural',
  VEHICLE_CANCELLATION_NO_REFUND_WARNING = 'myJourney.cancellation.multiplePassengers.noVehicleCancellationNoRefundWarning',
  VEHICLE_CANCELLATION_NO_REFUND_WARNING_PLURAL = 'myJourney.cancellation.multiplePassengers.noVehicleCancellationNoRefundWarning.plural',
  VEHICLE_CANCELLATION_INSURANCE_EXPIRED_WARNING = 'myJourney.cancellation.multiplePassengers.vehicleCancellationInsuranceExpiredWarning',
  VEHICLE_CANCELLATION_INSURANCE_EXPIRED_WARNING_PLURAL = 'myJourney.cancellation.multiplePassengers.vehicleCancellationInsuranceExpiredWarning.plural',
  PICK_PASSENGERS = 'myJourney.cancellation.multiplePassengers.pickPassengers',
  PICK_VEHICLE_CANCELLATION = 'myJourney.cancellation.multipleVehicles.pickCancellation',
  PICK_VEHICLE_CANCELLATION_PLURAL = 'myJourney.cancellation.multipleVehicles.pickCancellation.plural',
  PICK_VEHICLES = 'myJourney.cancellation.multipleVehicles.pickVehicles',
  PICK_VEHICLES_PLURAL = 'myJourney.cancellation.multipleVehicles.pickVehicles.plural',
  NO_CANCELLATION_INSURANCE_PILL = 'myJourney.cancellation.multipleVehicles.noCancellationInsurancePill',
  DURATION = 'booking.accessibility.duration',
  LOAD_MORE = 'myJourney.multiTicket.loadMore',
  FAILED_TO_LOAD_MORE = 'myJourney.multiTicket.failedToLoadMore',
  YOUR_TICKETS = 'myJourney.youTickets',
  YOUR_TICKETS_IN_MOBILE = 'myJourney.youTickets.inMobile',
  CORPORATE_PRODUCT_TITLE = 'myJourney.corporateProduct.title',
  CORPORATE_PRODUCT_LINK = 'myJourney.corporateProduct.link',
  CORPORATE_PRODUCT_BODY = 'myJourney.corporateProduct.widget.body',
  PAYMENT_TITLE = 'myJourney.paymentTitle',
  PAYMENT_INCLUDES_OUTBOUND_AND_RETURN = 'myJourney.groupSales.paymentIncludesOutboundAndReturn',
  PAY_NOW = 'myJourney.groupSales.payNow',
  PRE_PAYMENT = 'myJourney.groupSales.prePayment',
  FINAL_PAYMENT = 'myJourney.groupSales.finalPayment',
  AVECRA_PAYMENT = 'myJourney.groupSales.avecraPayment',
  PAYMENT_IS_DUE = 'myJourney.groupSales.paymentIsDue',
  PAYMENT_DUE = 'myJourney.groupSales.paymentDue',
  PAY_PRE_PAYMENT_FIRST = 'myJourney.groupSales.payPrePaymentFirst',
  GROUP_TICKET_INFO_BOX_TITLE = 'myJourney.groupSales.infoBoxTitle',
  GROUP_TICKET_INFO_BOX_TEXT = 'myJourney.groupSales.infoBoxText',
  OPEN_OUTBOUND_CARD_DESCRIPTION = 'myJourney.groupSales.openOutboundCardButtonDescripion',
  OPEN_OUTBOUND_CARD_BUTTON_TEXT = 'myJourney.groupSales.openOutboundCardButtonText',
  BUSINESS_ID = 'myJourney.businessId',
  BUS_CONNECTION = 'myJourney.busConnection',
  REFUND_INFO = 'myJourney.refundInfo',
  DISCOUNT_CODE_REFUND_INFO = 'myJourney.refundInfo.discountInfo',
  PAY_IN_FULL = 'myJourney.groupSales.payInFull',
  GROUP_SALES_AVECRA_BANNER_TITLE = 'myJourney.groupSalesAvecraBanner.title',
  GROUP_SALES_AVECRA_BANNER_BUTTON = 'myJourney.groupSalesAvecraBanner.button.orderMeals',
  GROUP_SALES_AVECRA_BANNER_BUTTON_OUTBOUND = 'myJourney.groupSalesAvecraBanner.button.orderMeals.outbound',
  GROUP_SALES_AVECRA_BANNER_BUTTON_RETURN = 'myJourney.groupSalesAvecraBanner.button.orderMeals.return',
  GROUP_SALES_AVECRA_BANNER_BODY = 'myJourney.groupSalesAvecraBanner.body',
  DOWNLOAD_RECEIPT = 'myJourney.downloadReceipt',
  VEHICLE_INFORMATION = 'myJourney.vehicleInformationTitle',
  ORDER_HISTORY = 'myJourney.orderHistory',
  ORDER_HISTORY_TOUCHPOINT_WEB = 'myJourney.orderHistory.touchpoint.web',
  ORDER_HISTORY_TOUCHPOINT_MOBILE = 'myJourney.orderHistory.touchpoint.mobile',
  ORDER_HISTORY_TOUCHPOINT_CALLCENTER = 'myJourney.orderHistory.touchpoint.callCenter',
  ORDER_HISTORY_TOUCHPOINT_VR = 'myJourney.orderHistory.touchpoint.vr',
  ORDER_HISTORY_CHANGE_SERVICES = 'myJourney.orderHistory.change.services',
  ORDER_HISTORY_CHANGE_DEPARTURE = 'myJourney.orderHistory.change.departure',
  ORDER_HISTORY_CHANGE_ADD_PASSENGER = 'myJourney.orderHistory.change.addPassenger',
  ORDER_HISTORY_CHANGE_REMOVE_PASSENGER = 'myJourney.orderHistory.change.removePassenger',
  ORDER_HISTORY_CHANGE_RESERVATION = 'myJourney.orderHistory.change.reservation',
  ORDER_HISTORY_CHANGE_SEAT = 'myJourney.orderHistory.change.seat',
  ORDER_HISTORY_CHANGE_CANCEL_TRIP = 'myJourney.orderHistory.change.cancelTrip',
  ORDER_HISTORY_CHANGE_TICKET_BOUGHT = 'myJourney.orderHistory.change.ticketBought',
  ORDER_HISTORY_CHANGE_ADVANCE_FEE_PAID = 'myJourney.orderHistory.change.advanceFeePaid',
  ORDER_HISTORY_CHANGE_FINAL_PAYMENT_PAID = 'myJourney.orderHistory.change.finalPaymentPaid',
  ORDER_HISTORY_CHANGE_WHOLE_PAYMENT_PAID = 'myJourney.orderHistory.change.wholePaymentPaid',
  PASSENGERS = 'booking.passengersLabel',
  PASSENGERS_SELECTED = 'myJourney.passengersSelected',
}

export enum ChangeJourneyMessage {
  ADD_PRE_BOOKED_BREAKFAST = 'myJourney.addPrebookedBreakfast',
  ADD_PRE_BOOKED_BREAKFAST_DESCRIPTION = 'myJourney.addPrebookedBreakfastDescription',
  ADD_GROUP_SALES_MEALS_DESCRIPTION = 'myJourney.addGroupSalesMealsDescription',
  YOU_HAVE_ALREADY_PRE_BOOKED = 'myJourney.youHaveAlreadyPrebooked',
  CHANGE_JOURNEY = 'myJourney.changeYourJourney',
  CHANGE_JOURNEY_BODY = 'myJourney.changeYourJourney.body',
  CANCEL_JOURNEY = 'myJourney.cancelJourney',
  CANCEL_JOURNEY_DEFAULT_DESCRIPTION = 'myJourney.cancelJourneyDefaultDescription',
  CANCEL_JOURNEY_GROUP_DESCRIPTION = 'myJourney.cancelJourneyGroupDescription',
  CANCEL_JOURNEY_GROUP_NOTICE = 'myJourney.cancelJourneyGroup.notice',
  CANCEL_JOURNEY_CORPORATE_DEFAULT_DESCRIPTION = 'myJourney.corporate.cancelJourneyDefaultDescription',
  CANCEL_JOURNEY_CANCELLATION_PERIOD_ENDED_DESCRIPTION = 'myJourney.cancellationPeriodEnded',
  CANCEL_JOURNEY_DISCOUNT_CODE_DESCRIPTION = 'myJourney.cancelJourneyDiscountCodeDescription',
  CANCEL_MODAL_TITLE = 'myJourney.cancelModal.title',
  CANCEL_MODAL_BUTTON = 'myJourney.cancelModal.button',
  CANCEL_MODAL_SECONDARY_BUTTON = 'myJourney.cancelModal.secondaryButton',
  CANCEL_MODAL_CONTENT = 'myJourney.cancelModal.content',
  CANCEL_JOURNEY_MULTITICKET_DESCRIPTION = 'myJourney.cancelJourney.multiTicket.description',
  CANCEL_JOURNEY_MULTITICKET_ERROR_GENERIC_TITLE = 'myJourney.cancelJourney.multiTicket.errors.generic.title',
  CANCEL_JOURNEY_CANCELLATION_INSURANCE_SUCCESS_TITLE = 'myJourney.cancelJourney.cancellationInsurance.success.title',
  CANCEL_JOURNEY_CANCELLATION_INSURANCE_SUCCESS_BODY = 'myJourney.cancelJourney.cancellationInsurance.success.body',
  CANCEL_JOURNEY_CANCELLATION_INSURANCE_ERROR_GENERIC_BODY = 'myJourney.cancelJourney.cancellationInsurance.errors.generic.body',
  CANCEL_JOURNEY_WITHOUT_INSURANCE = 'myJourney.cancelJourney.goodwill.body',
  CANCEL_VEHICLE = 'myJourney.cancelVehicle',
  CANCEL_VEHICLE_DEFAULT_DESCRIPTION = 'myJourney.vehicleCancelJourneyDefaultDescription',
  CANCEL_VEHICLE_TOO_LATE_DESCRIPTION = 'myJourney.vehicleCancel.tooLateToCancel',
  ALREADY_PARTIALLY_CANCELLED_TITLE = 'myJourney.alreadyPartiallyCancelled.title',
  ALREADY_PARTIALLY_CANCELLED_BODY = 'myJourney.alreadyPartiallyCancelled.body',
  ALREADY_PARTIALLY_CANCELLED_OK = 'myJourney.alreadyPartiallyCancelled.ok',
  ALREADY_PARTIALLY_CANCELLED_CANCEL = 'myJourney.alreadyPartiallyCancelled.cancel',
  JOURNEY_HAS_STARTED = 'myJourney.journeyHasStarted',
  CANT_EDIT_JOURNEY_BY_YOURSELF_NOTICE_TITLE = 'myJourney.cantEditJourneyByYourselfNotice.title',
  CANT_EDIT_JOURNEY_BY_YOURSELF_NOTICE_BODY = 'myJourney.cantEditJourneyByYourselfNotice.body',
  CANT_EDIT_JOURNEY_TITLE = 'myJourney.cantEditJourney.title',
  CANT_EDIT_JOURNEY_BODY = 'myJourney.cantEditJourney.body',
  JOURNEY_CAN_ONLY_CHANGED_IN_APP_TITLE = 'myJourney.journeyCanOnlyChangedInApp.title',
  JOURNEY_CAN_ONLY_CHANGED_IN_APP_BODY = 'myJourney.journeyCanOnlyChangedInApp.body',
  CANT_EDIT_JOURNEY_IT_HAS_ALREADY_STARTED = 'myJourney.cantEditJourneyItHasAlreadyStarted',
  NOT_TRAVELING_AFTER_ALL = 'myJourney.cancelJourney.noTravellingAfterAll',
  CHANGE_DEPARTURE_TITLE = 'myJourney.changeDeparture.title',
  CHANGE_DEPARTURE_DESCRIPTION = 'myJourney.changeDeparture.description',
  CHANGE_DEPARTURE_DESCRIPTION_FOR_CORPORATE_PRODUCTS = 'myJourney.changeDeparture.descriptionForCorporateProducts',
  CHANGE_DEPARTURE_CORPORATE_DESCRIPTION = 'myJourney.changeDeparture.corporate.description',
  ORIGINAL_DEPARTURE = 'myJourney.changeDeparture.originalDeparture',
  CHOOSE_NEW_DEPARTURE = 'myJourney.changeDeparture.chooseNewDeparture',
  CHOOSE_PASSENGERS = 'myJourney.changeDeparture.choosePassengers',
  CHANGE_DEPARTURE_INFO_LINK = 'myJourney.changeDeparture.infoLink',
  CHANGE_DEPARTURE_MODAL_TITLE = 'myJourney.changeDeparture.infoModalTitle',
  CHANGE_DEPARTURE_MODAL_CONTENT = 'myJourney.changeDeparture.infoModalContent',
  CHANGE_DEPARTURE_BLOCKED_FDF_CONTRACT = 'myJourney.changeDeparture.blocked.fdfContract',
  CHANGE_DEPARTURE_BLOCKED_VEHICLE_TOO_CLOSE = 'myJourney.changeDeparture.blocked.vehicleTooClose',
  CHANGE_JOURNEY_NAV_ARIA_LABEL = 'myJourney.changeYourJourney.navigationAriaLabel',
  CHANGE_DEPARTURE_TO_COMMUTER_GENERIC_WARNING = 'myJourney.changeDeparture.toCommuter.genericWarning',
  CHANGE_DEPARTURE_TO_COMMUTER_CANCELLATION_INSURANCE_WARNING = 'myJourney.changeDeparture.toCommuter.cancelInsuranceWarning',
  EDIT_PAYMENT_REFERENCES_SECTION_TITLE = 'myJourney.editPaymentReferences.sectionTitle',
  EDIT_PAYMENT_REFERENCES_TITLE = 'myJourney.editPaymentReferences.title',
  EDIT_PAYMENT_REFERENCES_TITLE_GROUP = 'myJourney.editPaymentReferences.title.group',
  EDIT_PAYMENT_REFERENCES_TEXT = 'myJourney.editPaymentReferences.text',
  EDIT_PAYMENT_REFERENCES_TEXT_GROUP = 'myJourney.editPaymentReferences.text.group',
  CHANGE_DEPARTURE_TO_COMMUTER_CORPORATE_PRODUCT_WARNING = 'myJourney.changeDeparture.toCommuter.corporateProductWarning',
  CANCEL_JOURNEY_CANCELLATION_NOT_AVAILABLE = 'myJourney.cancellingNotAvailable',
  GROUP_ANNULLED_PAYMENTS = 'myJourney.group.annulledPayments',
  GROUP_COMPLETED_PAYMENTS = 'myJourney.group.completedPayments',
  GROUP_NOT_REFUNDED_PILL = 'myJourney.group.notRefunded.pill',
  GROUP_NOT_REFUNDED_TITLE = 'myJourney.group.notRefunded.title',
  GROUP_NOT_REFUNDED_BODY = 'myJourney.group.notRefunded.body',
  CANCEL_JOURNEY_BY_TRAVEL_AGENT_DESCRIPTION = 'myJourney.cancelJourney.travelAgent.description',
  BUS_CONNECTION_NOTIFICATION_ON_CHANGE_DEPARTURE = 'changeJourney.busConnectionNotification.onChangeDeparture',
  BUS_CONNECTION_NOTIFICATION_ON_CANCEL_JOURNEY = 'changeJourney.busConnectionNotification.onCancelJourney',
  CHANGE_SEAT_TITLE = 'myJourney.changeSeat.title',
  CHANGE_SEAT_BODY = 'myJourney.changeSeat.body',
  CHANGE_SEAT_SOLD_OUT_BODY = 'myJourney.changeSeat.soldOut.body',
  CHANGE_SEAT_BED_CABIN_BODY = 'myJourney.changeSeat.bedCabin.body',
  CHANGE_SEAT_AVECRA_TOO_CLOSE_BODY = 'myJourney.changeSeat.avecraDepartureTooClose.body',
  ADD_VEHICLE = 'myJourney.addVehicle.title',
  ADD_VEHICLE_BODY = 'myJourney.addVehicle.body',
  ADD_VEHICLE_NO_AVAILABILITY = 'myJourney.addVehicle.noAvailability',
  ADD_VEHICLE_EXTERNAL_BUS_LEG = 'myJourney.addVehicle.externalBusLeg',
  ADD_VEHICLE_NO_PASSENGER_AVAILABLE = 'myJourney.addVehicle.noPassengerAvailable',
  ADD_VEHICLE_FDF_CONTRACT = 'myJourney.addVehicle.journeyIncludesFdfContractPassenger',
  ADD_VEHICLE_SERIES_TICKET_JOURNEY = 'myJourney.addVehicle.journeyRedeemedWithSeriesTicket',
  ADD_BICYCLE = 'myJourney.addBicycle.title',
  ADD_BICYCLE_BODY = 'myJourney.addBicycle.body',
  ADD_BICYCLE_NO_AVAILABILITY = 'myJourney.addBicycle.noAvailability',
  ADD_BICYCLE_UNAVAILABLE_FOR_FDF_CONTRACT = 'myJourney.addBicycle.notForFdfContract',
  NO_PASSENGER_WITHOUT_BICYCLE = 'myJourney.addBicycle.noPassengerWithoutBikeAvailable',
  ADD_BICYCLE_INCLUDES_EXTERNAL_BUS_LEG = 'myJourney.addBicycle.journeyIncludesExternalBusLeg',
  ADD_BUS_LEG_TITLE = 'myJourney.addExternalBusLeg.title',
  ADD_BUS_LEG_AFTER_TITLE = 'myJourney.addExternalBusLeg.after.title',
  ADD_BUS_LEG_BEFORE_TITLE = 'myJourney.addExternalBusLeg.before.title',
  ADD_BUS_LEG_AFTER_BODY = 'myJourney.addExternalBusLeg.after.body',
  ADD_BUS_LEG_BEFORE_BODY = 'myJourney.addExternalBusLeg.before.body',
  ADD_EXTERNAL_BUS_LEG_UNAVAILABLE_FOR_EXTRA_SERVICES = 'myJourney.addExternalBusLeg.notWithExtraServices',
  ADD_EXTERNAL_BUS_LEG_UNAVAILABLE_FOR_FDF = 'myJourney.addExternalBusLeg.notForFdf',
  ADD_EXTERNAL_BUS_INSURANCE_WARNING_TITLE = 'myJourney.addExternalBusLeg.cancelationInsurance.warning.title',
  ADD_EXTERNAL_BUS_INSURANCE_WARNING_BODY = 'myJourney.addExternalBusLeg.cancelationInsurance.warning.body',
  EXTRA_UPSELL_TITLE = 'myJourney.extraUpsell.title',
  EXTRA_UPSELL_BUTTON = 'myJourney.extraUpsell.button',
}

export enum RegistrationMessage {
  SELECT_MONTH = 'login.registration.selectMonth',
  MONTH = 'login.registration.month',
  SELECT_DAY = 'login.registration.selectDay',
  DAY = 'login.registration.day',
  AGE_INFO = 'login.registration.ageInfo',
}

export enum FormsMessage {
  OPTIONAL_FIELD = 'forms.optionalField',
  REQUIRED_FIELD = 'forms.requiredField',
  INVALID_DAY = 'forms.invalidDay',
  ACCEPT_TERMS_TO_CONTINUE = 'forms.acceptTermsToContinue',
  RESPONSE_REQUESTED = 'forms.responseRequested',
  INT_TOO_BIG = 'forms.integerTooBig',
  LOGIN_PROMPT_ALREADY_LOGGED_IN_TITLE = 'forms.loginPrompt.alreadyLoggedInTitle',
  LOGIN_PROMPT_ALREADY_LOGGED_IN_DESCRIPTION = 'forms.loginPrompt.alreadyLoggedInDescription',
  SELECT = 'forms.select',
  INDICATES_MANDATORY = 'forms.indicatesMandatory',

  // Form submitted view
  SUBMIT_SUCCESS_TITLE = 'forms.submitted.title',
  SUBMIT_SUCCESS_ADDITIONAL_INFO = 'forms.submitted.additionalInfo',
  TO_FRONT_PAGE = 'forms.submitted.toFrontPage',
  CHECK_FORM = 'forms.checkForm',
  CHECK_FORM_WITH_FIELDS = 'forms.checkFormWithFields',
}

export enum FormValidationMessage {
  USERNAME_REQUIRED = 'login.usernameRequired',
  INVALID_EMAIL = 'login.invalidEmail',
  PASSWORD_REQUIRED = 'login.passwordRequired',
  PASSWORD_REQUIREMENTS = 'login.resetPassword.passwordRequirements',
  CONFIRM_PASSWORD_REQUIRED = 'login.resetPassword.errors.confirmYourPassword',
  CONFIRM_PASSWORD_UNMATCHED = 'login.resetPassword.errors.passwordsDontMatch',
  INVALID_FIRST_NAME = 'profile.errors.invalidFirstName',
  INVALID_LAST_NAME = 'profile.erros.invalidLastName',
  INVALID_POSTAL_CODE = 'profile.errors.invalidPostalCode',
  INVALID_PHONE = 'profile.errors.invalidPhone',
  AGE_MUST_BE_13_OR_OLDER = 'login.registration.ageMustBe13OrOlder',
  INVALID_REGISTER_NUMBER = 'errors.invalidRegisterNumber',
  INVALID_MANUFACTURER = 'errors.invalidManufacturer',
  INVALID_MODEL = 'errors.invalidModel',
  INVALID_CREDIT_CARD_LAST_DIGITS = 'errors.invalidCreditCardLastDigits',
  INVALID_SUM = 'errors.invalidSum',
  INVALID_BANK_ACCOUNT = 'errors.invalidBankAccount',
  NOT_OWN_BANK_ACCOUNT = 'errors.notOwnBankAccount',
  INVALID_BIC = 'errors.invalidBic',
  INVALID_PASSENGERS_AMOUNT = 'forms.groupSales.passengers.amount.invalid',
  INVALID_COMPANY_NAME = 'errors.invalidCompanyName',
  INVALID_COMPANY_ACCOUNT_NUMBER = 'errors.invalidCompanyAccountNumber',
  INVALID_GROUP_NAME = 'errors.invalidGroupName',
  INVALID_ORDER_NUMBER = 'errors.invalidOrderNumber',
  INVALID_INVOICE_PASSENGER_REFERENCE = 'errors.invalidInvoicePassengerReference',
  TOO_LONG_VALUE = 'errors.tooLongValue',
  NOT_IN_RANGE = 'forms.valueNotInRange',
  CORPORATE_ACCOUNT = 'forms.corporateAccount',
}

export enum JourneySearchMessage {
  SEARCH_FOR_JOURNEYS_BUTTON = 'booking.searchButtonTitle',
  OUTBOUND = 'booking.searchResultsHeading.outbound',
  RETURN = 'booking.searchResultsHeading.return',
  SELECT_OUTBOUND_TRAIN_LEG = 'booking.searchResultsHeading.outboundTrainLeg',
  SELECT_RETURN_TRAIN_LEG = 'booking.searchResultsHeading.returnTrainLeg',
  OUTBOUND_DATE_LABEL = 'booking.departureDateLabel',
  CHOOSE_DEPARTURE_DATE_LABEL = 'calendar.chooseDepartureDateLabel',
  CHOOSE_DEPARTURE_DATE_TITLE = 'calendar.chooseDepartureDateTitle',
  ACCEPT_DEPARTURE_DATE = 'booking.acceptDepartureDate',
  ACCEPT_OUTBOUND = 'booking.acceptOutbound',
  RETURN_DATE_LABEL = 'booking.returnDateLabel',
  CHOOSE_RETURN_DATE_TITLE = 'calendar.chooseReturnDateTitle',
  ACCEPT_RETURN = 'booking.acceptReturn',
  ISSUE_BOTH_HSL_STATIONS = 'booking.searchIssues.bothHSLStations',
  ISSUE_ARRIVAL_NOT_VEHICLE_STATION = 'booking.searchIssues.notVehicleStation.arrival',
  ISSUE_DEPARTURE_NOT_VEHICLE_STATION = 'booking.searchIssues.notVehicleStation.departure',
  ISSUE_NOT_VEHICLE_STATION_PLURAL = 'booking.searchIssues.notVehicleStation.plural',
  DEPARTURE_SWITCHED_TO_PASILA_VEHICLE_STATION = 'booking.searchIssues.departure.switchedToPasila',
  ARRIVAL_SWITCHED_TO_PASILA_VEHICLE_STATION = 'booking.searchIssues.arrival.switchedToPasila',
  INVALID_VEHICLE_CONNECTION = 'booking.searchIssues.invalidVehicleConnection',
  BUNDLE_EXPIRED_ERROR_TITLE = 'journeySearch.bundleExpired.title',
  BUNDLE_EXPIRED_ERROR_BODY = 'journeySearch.bundleExpired.body',
  BUNDLE_EXPIRED_ERROR_BUTTON = 'journeySearch.bundleExpired.button',
  SERVICES = 'journeySearch.services',
  RECENT_ROUTES_NAV_LABEL = 'journeySearch.recentRoutesNavLabel',
  ADD_RETURN = 'journeySearch.addReturn',
  REMOVE_RETURN = 'journeySearch.removeReturn',
}

export enum TimetableSearchMessage {
  WELCOME_TITLE = 'timetableSearch.welcome.title',
  WELCOME_BODY = 'timetableSearch.welcome.body',
  EMPTY_SEARCH_RESULT_TITLE = 'timetableSearch.emptyResult.title',
  EMPTY_SEARCH_RESULT_BODY = 'timetableSearch.emptyResult.body',
}

export enum ConnectionListMessage {
  NIGHT_TRAFFIC = 'booking.nightTraffic',
  SERVICES_LABEL = 'connection.servicesLabel',
  SERVICES_A11Y_LABEL = 'connection.accessibility.servicesLabel',
  LOAD_PAGE_AGAIN = 'booking.loadPageAgain',
  HSL_AREA_TITLE = 'booking.hslAreaTitle',
  HSL_AREA_BODY = 'booking.hslAreaBody',
  CONNECTIONS_LOADING_FAILED_TITLE = 'booking.connectionsLoadingFailedTitle',
  CONNECTIONS_LOADING_FAILED_BODY = 'booking.connectionsLoadingFailedBody',
  CONNECTIONS_NOT_FOUND_TITLE = 'booking.connectionsNotFoundTitle',
  CONNECTIONS_NOT_FOUND_BODY = 'booking.connectionsNotFoundBody',
  TRANSFER_COUNT = 'booking.transferCount',
  TRAIN_DEPARTURES = 'booking.accessibility.trainDepartures',
  TRAIN_ARRIVES = 'booking.accessibility.trainArrives',
  TRANSFER_COUNT_ACC = 'booking.accessibility.transferCount',
  COMMUTER_TICKET_VALIDITY_TIME = 'booking.commuterTicketValidityTime',
  TRANSFER_COUNT_AND_OTHERS = 'booking.transferCountAndOthers',
  TRANSFER_SUB_TEXT_DURATION = 'booking.transferSubTextDuration',
  STOPS_COUNT = 'booking.stopsCount',
  TRAVEL_TIME = 'booking.travelTime',
  GENERAL_ERROR = 'booking.changeSeats.generalError',
  NO_SEAT_RESERVATION = 'booking.noSeatReservation',
  NO_SEAT_RESERVATION_KLA = 'booking.klaNoSeatReservation',
  YOUR_SEAT = 'booking.yourSeat',
  YOUR_SEAT_PLURAL = 'booking.yourSeat.plural',
  YOUR_CABIN = 'booking.yourCabin',
  YOUR_CABIN_PLURAL = 'booking.yourCabin.plural',
  NO_CABINS_AVAILABLE = 'booking.noCabinsAvailable',
  NO_SEATS_AVAILABLE = 'booking.noSeatsAvailable',
  WHOLE_NIGHT_CABIN = 'booking.wholeCabin',
  WHOLE_DAY_CABIN = 'booking.wholeCompartment',
  NOT_ENOUGH_EKO_SEATS_FOR_GROUP = 'booking.notEnoughEkoSeatsForGroup',
  NOT_ENOUGH_MULTI_TICKET_USAGES_LEFT_TITLE = 'booking.notEnoughMultiTicketUsagesLeft.title',
  NOT_ENOUGH_MULTI_TICKET_USAGES_LEFT_BODY = 'booking.notEnoughMultiTicketUsagesLeft.body',
  NOT_ENOUGH_MULTI_TICKET_USAGES_MODAL_TITLE = 'noUsagesLeftModal.title',
  NOT_ENOUGH_MULTI_TICKET_USAGES_MODAL_BODY = 'noUsagesLeftModal.body',
  STATION_LINK_LABEL = 'booking.stationLink.label',
  JOURNEY_INFORMATION = 'booking.journeyInformation',
}

export enum ConnectionListFilterMessage {
  COMMUTER_TRAFFIC_TITLE = 'connections.filters.commuterTraffic.title',
  COMMUTER_TRAFFIC_DESCRIPTION = 'connections.filters.commuterTraffic.description',
  COMMUTER_TRAFFIC_ACTION = 'connections.filters.commuterTraffic.action',
  ACCESSIBLE_JOURNEY_TITLE = 'connections.filters.accessibleJourney.title',
  ACCESSIBLE_JOURNEY_DESCRIPTION = 'connections.filters.accessibleJourney.description',
  ACCESSIBLE_JOURNEY_ACTION = 'connections.filters.accessibleJourney.action',
  BED_CABIN_TITLE = 'connections.filters.bedCabin.title',
  BED_CABIN_DESCRIPTION = 'connections.filters.bedCabin.description',
  BED_CABIN_ACTION = 'connections.filters.bedCabin.action',
}

export enum GenericMessage {
  // PRINT = 'generic.print',
  ABBREVIATION_PERSON = 'generic.abbreviation.person',
  ABBREVIATION_PIECE = 'generic.abbreviation.piece',
  ABBREVIATION_STARTING_FROM = 'generic.abbreviation.startingFrom',
  ADD = 'generic.add',
  ADDED = 'generic.added',
  AND = 'generic.and',
  APPLY_FILTERS = 'generic.applyFilters',
  BACK = 'booking.back',
  BUS = 'generic.bus',
  BUY_TICKET = 'generic.buyTicket',
  CANCEL = 'generic.cancel',
  CHOOSE = 'generic.choose',
  CHOOSE_ALL = 'generic.chooseAll',
  CLEAR = 'generic.clear',
  CLEAR_SELECTIONS = 'generic.clearSelections',
  CLOSE = 'generic.close',
  CLOSE_VIEW = 'generic.closeView',
  COMPANY_NAME = 'generic.companyName',
  CONTINUE = 'generic.continue',
  COPY_LINK = 'generic.share.copy',
  COPY_LINK_TO_RECEIPT = 'generic.share.copyLinkToReceipt',
  DATE = 'generic.date',
  SELECT_DATE = 'generic.selectDate',
  DEPARTURE_TIME = 'generic.departureTime',
  DISCOUNT = 'generic.discount',
  DONE = 'generic.done',
  EDIT = 'generic.edit',
  EMAIL = 'generic.email',
  EXPAND = 'generic.expand',
  FILTER_RESULTS = 'generic.filterResults',
  FILTERS = 'generic.filters',
  FLOOR_DOWNSTAIRS = 'generic.floorDownstairs',
  FLOOR_UPSTAIRS = 'generic.floorUpstairs',
  HIDDEN = 'generic.hidden',
  INVALID_EMAIL = 'generic.invalidEmail',
  JOURNEY = 'generic.journey',
  LEG = 'generic.leg',
  LEG_DEPARTURE_AND_LEG_ARRIVAL = 'generic.legDepartureAndLegArrival',
  LINK_COPIED = 'generic.share.copied',
  NEXT = 'generic.next',
  OK = 'generic.ok',
  ONE_WAY_JOURNEY = 'generic.oneWayJourney',
  OPTIONAL = 'generic.optional',
  ORDER = 'generic.order',
  PAID = 'generic.paid',
  PCS = 'generic.pcs',
  PREVIOUS = 'generic.previous',
  PRICE_DIFFERENCE = 'generic.priceDifference',
  PUBLISHED = 'generic.published',
  READ_LESS = 'generic.readLess',
  READ_MORE = 'generic.readMore',
  REFERENCE = 'generic.reference',
  REMOVE = 'generic.remove',
  SEARCH = 'generic.search',
  SELECT = 'generic.select',
  SELECT_SUBJECT = 'generic.selectSubject',
  SEND = 'generic.send',
  SEND_TO_EMAIL = 'generic.share.sendToEmail',
  SHOW_ALL = 'generic.showAll',
  SHOW_RESULTS = 'generic.showResults',
  SOMETHING_WENT_WRONG = 'generic.somethingWentWrong',
  STARTING_FROM = 'generic.startingFrom',
  STARTING_FROM_SHORT = 'generic.startingFromShort',
  SUMMARY = 'generic.summary',
  TRAIN = 'generic.train',
  TRANSFER = 'generic.transfer',
  TRANSLATION_NOT_AVAILABLE = 'generic.translationNotAvailable',
  TRY_AGAIN = 'generic.tryAgain',
  TWO_WAY_JOURNEY = 'generic.twoWayJourney',
  VISIBLE = 'generic.visible',
  STATION = 'generic.station',
  YES = 'generic.yes',
  NO = 'generic.no',
  LANGUAGE_FI = 'language.fi',
  LANGUAGE_SV = 'language.sv',
  LANGUAGE_EN = 'language.en',
  ERROR_ID = 'generic.errorId',
}

export enum TrafficMessage {
  LOADING = 'traffic.loading',
  FROM_LONG_DISTANCE_TRAINS = 'traffic.fromLongDistanceTrains',
  TRAINS_ON_TIME = 'traffic.trainsOnTime',
  TRAINS_ON_SCHEDULE = 'traffic.trainsOnSchedule',
  FROM_COMMUTER_TRAINS = 'traffic.fromCommuterTrains',
  NO_PUNCTUALITY_AVAILABLE = 'traffic.noPunctualityAvailable',
  NO_TRAFFIC_BULLETINS_AVAILABLE = 'traffic.noTrafficBulletinsAvailable',
  TRAFFIC_BULLETINS_FETCH_FAILED = 'traffic.trafficBulletinsFetchFailed',
  UPCOMING_DISRUPTIONS = 'traffic.upcomingDisruptions',
}

export enum LoginMessage {
  CANCEL = 'login.cancel',
  LOGIN = 'login.login',
  LOGOUT = 'login.logout',
  LOGIN_SHORT_VARIANT = 'login.login.short',
  TITLE = 'login.title',
  LOGOUT_IN_PURCHASE_FLOW_TITLE = 'logout.inPurchaseFlow.title',
  LOGOUT_IN_PURCHASE_FLOW_DESCRIPTION = 'logout.inPurchaseFlow.description',
  AD_CODE_EXCHANGE_FAILED = 'login.adAuthFailed.title',
  AD_CODE_EXCHANGE_FAILED_BODY = 'login.adAuthFailed.body',
  FOR_EMPLOYEES = 'login.forEmployees',
  TOOLTIP_REGISTER = 'login.tooltip.registerPrompt',
  TOOLTIP_LOGIN = 'login.tooltip.loginPrompt',
}

export enum ProfileMessage {
  TITLE = 'profile.title',
  MY_JOURNEY_TITLE = 'profile.myJourney.title',
  MY_JOURNEY_CHANGES_TAB_TITLE = 'profile.myJourney.changesTab.title',
  MY_JOURNEY_RECEIPT_TAB_TITLE = 'profile.myJourney.receiptTab.title',
  TERMS_AND_CONDITIONS = 'login.registration.TermsAndConditions',
  MARKETING_BY_EMAIL_ALLOWED = 'profile.marketingByEmailAllowed',
  BIRTHDAY = 'login.registration.birhday',
  YEAR = 'login.registration.year',
  MONTH = 'login.registration.month',
  DAY = 'login.registration.day',
  PHONE = 'login.registration.phone',
  PHONE_DESCRIPTION = 'login.registration.phoneDescription',
  COUNTRY_CODE = 'login.registration.countryCode',
  POSTAL_CODE = 'login.registration.postalCode',
  COUNTRY = 'login.registration.country',
  LAST_NAME = 'login.registration.lastName',
  EMAIL = 'login.registration.userAccount',
  FIRST_NAME = 'login.registration.firstName',
  CONTACT_INFORMATION = 'login.registration.contactInformation',
  PERSONAL_INFORMATION = 'login.registration.personalInformation',
  PROFILE_NAVIGATION_ACCESSIBILITY_TITLE = 'login.profileNavigation.accessibility.title',
  I_AGREE_TO = 'login.registration.iAgreeTo',
  PERMISSIONS = 'login.registration.permissions',
  CONFIRM = 'login.registration.confirm',
  CUSTOMER_SERVICE_UPDATE_EMAIL_INFORMATION = 'login.registration.customerServiceCanUpdateEmailInformation',
  EMAIL_NOTIFICATION_FOR_B2B_CIAM = 'profile.email.notification.b2bCiam',
  CHECK_JOURNEY_INFORMATION = 'changeDeparture.checkJourneyInformation',
  CHECK_INFORMATION_ABOUT_PRICE = 'changeDeparture.checkInformationAboutPrice',
  PROFILE_NAVIGATION_BUTTON_FIRST_NAME_PLACEHOLDER = 'login.profileNavigation.button.placeholder',
  FAILED_TO_LOAD_USER_DETAILS_TITLE = 'profile.errors.failedToLoadUserDetails.title',
  FAILED_TO_LOAD_USER_DETAILS_BODY = 'profile.errors.failedToLoadUserDetails.body',
  VR_USER = 'login.vrUser',
  MARKETING_PERMISSION_GROUP_TITLE = 'profile.marketingPermissionGroupTitle',
  CUSTOMER_RESEARCH_ALLOWED = 'profile.customerResearchAllowed',
  SERVICE_LANGUAGE = 'login.registration.serviceLanguage',
  CUSTOMER_ID = 'profile.customerId',
}

export enum MultiTicketPurchaseFlowMessage {
  MULTITICKET_VALIDITY_START_TITLE = 'multiTicket.validityStartDate',
  MULTITICKET_VALIDITY_START_VALUE = 'multiTicket.validityStartDate.value',
  MULTITICKET_PASSENGER_TYPE = 'multiTicket.passengerType',
  MULTITICKET_SEARCH_MULTI_TICKETS = 'multiTicket.searchMultiTickets',
  MULTITICKET_OFFER_LIST_HEADER = 'multiTicket.offerList.header',
  MULTITICKET_OFFER_LIST_TITLE_COMMUTER = 'multiTicket.offerList.titleCommuter',
  MULTITICKET_OFFER_LIST_TITLE_LONG_DISTANCE = 'multiTicket.offerList.titleLongDistance',
  MULTITICKET_OFFER_LIST_LOGIN_BANNER_TITLE = 'multiTicket.offerList.loginBannerTitle',
  MULTITICKET_OFFER_LIST_LOGIN_BANNER_TITLE_LINK_TEXT = 'multiTicket.offerList.loginBannerTitle.linkText',
  MULTITICKET_OFFER_LIST_AGE_WARNING_COMMUTER = 'multiTicket.offerList.ageWarning.commuter',
  MULTITICKET_OFFER_LIST_AGE_WARNING_LONG_DISTANCE = 'multiTicket.offerList.ageWarning.longDistance',
  MULTITICKET_OFFER_CARD_CAMPAIGN = 'multiTicket.offerCard.campaign',
  MULTITICKET_OFFER_CARD_TICKET_COUNT = 'multiTicket.offerCard.ticketCount',
  MULTITICKET_OFFER_CARD_REGULAR_PRICE = 'multiTicket.offerCard.regularPrice',
  MULTITICKET_RECEIPT_VALIDITY = 'multiTicket.receipt.validity',
  MULTITICKET_RECEIPT_TOTAL = 'multiTicket.receipt.total',
  MULTITICKET_CONFIRMATION_HEADER = 'multiTicket.confirmation.header',
  MULTITICKET_CONFIRMATION_SHARE_TITLE = 'multiTicket.confirmation.share.title',
  MULTITICKET_CONFIRMATION_SHARE_BODY = 'multiTicket.confirmation.share.body',
  MULTITICKET_CONFIRMATION_SHARE_BUTTON = 'multiTicket.confirmation.share.button',
  MULTITICKET_CONFIRMATION_THANK_YOU_TITLE = 'multiTicket.confirmation.thankYouTitle',
  MULTITICKET_CONFIRMATION_THANK_YOU_BODY = 'multiTicket.confirmation.thankYouBody.v2',
  MULTITICKET_CONFIRMATION_DONE_BUTTON = 'multiTicket.confirmation.doneButton',
  MULTITICKET_CONFIRMATION_COMMUTER_TRAINS = 'multiTicket.confirmation.commuterTrains',
  MULTITICKET_CONFIRMATION_ALL_TRAINS = 'multiTicket.confirmation.allTrains',
  MULTITICKET_CONFIRMATION_REDEEM_NOW_TITLE = 'multiTicket.confirmation.redeemNow.title',
  MULTITICKET_CONFIRMATION_REDEEM_NOW_BODY = 'multiTicket.confirmation.redeemNow.body',
  MULTITICKET_CONFIRMATION_REDEEM_NOW_BUTTON = 'multiTicket.confirmation.redeemNow.button',
  MULTITICKET_CONFIRMATION_LAST_TICKET_TITLE = 'multiTicket.confirmation.lastTicket.title',
  MULTITICKET_CONFIRMATION_LAST_TICKET_BODY = 'multiTicket.confirmation.lastTicket.body',
  UNAVAILABLE_FOR_EMPLOYEES_TITLE = 'multiTicket.unavailableForEmployees.title',
  UNAVAILABLE_FOR_EMPLOYEES_BODY = 'multiTicket.unavailableForEmployees.body',
}

export enum SeatMessage {
  SEAT_CLASS_ECO = 'seat.class.second',
  SEAT_CLASS_ECO_IN_PET_WAGON = 'seat.class.ecoInPetWagon',
  SEAT_CLASS_EXTRA = 'seat.class.first',
  SEAT_CLASS_SINGLE_EXTRA = 'seat.class.singleEkstra',
  SEAT_CLASS_SINGLE_ECO = 'seat.class.singleEco',
  SEAT_CLASS_SINGLE_ECO_PET_SEAT = 'seat.class.singleEcoPetSeat',
  SEAT_CLASS_SINGLE_RESTAURANT_WAGON = 'seat.class.singleRestaurantWagonUpstairs',
  SEAT_CLASS_UPSTAIRS_RESTAURANT_WAGON = 'seat.class.upstairsrestaurantwagon',
  SEAT_POSITION_AISLE = 'seat.type.aisle',
  SEAT_POSITION_MIDDLE = 'seat.type.middle',
  SEAT_POSITION_WINDOW = 'seat.type.window',
  WAGON_ACCESSIBILITY = 'booking.accessibility.wagon.clean',
  SEAT_ACCESSIBILITY = 'booking.accessibility.seats.clean',
  BED_ACCESSIBILITY = 'booking.accessibility.beds.clean',
  SPACE_ACCESSIBILITY = 'booking.accessibility.space.clean',
  SEAT_BICYCLE = 'booking.bicycle.seat',
  SEAT_POSITION_BED = 'seat.type.bed',
  CABINS = 'seat.cabins',
  SEATS = 'seat.groupSeats',
  WHEELCHAIR_SPACE = 'seat.wheelchairSpace',
}

export enum ServicesMessage {
  SEAT_PET = 'seat.service.pet',
  SEAT_WHEELCHAIR = 'seat.service.wheelchair',
  SEAT_OPPOSITE = 'seat.service.opposite',
  SEAT_FAMILY_COMPARTMENT = 'seat.service.familyCompartment',
  SEAT_WORKING_COMPARTMENT = 'seat.service.workingCompartment',
  SEAT_CONFERENCE_COMPARTMENT = 'seat.service.conferenceCompartment',
  SEAT_IN_PET_WAGON = 'seat.service.inPetWagon',
  SEAT_IN_PLAY_WAGON = 'seat.service.playWagon',
}

export enum PassengerTypeShortInfoMessage {
  CHILD = 'passenger.shortInfo.child',
  STUDENT = 'passenger.shortInfo.student',
  CONSCRIPT = 'passenger.shortInfo.conscript',
  PENSIONER = 'passenger.shortInfo.pensioner',
  EMPLOYEE = 'passenger.shortInfo.employee',
  VR_EMPLOYEE_FAMILY_MEMBER = 'passenger.shortInfo.employeeFamilyMember',
  FDF_CONTRACT = 'passenger.shortInfo.fdfContract',
}

export enum PassengerTypeLongInfoMessage {
  CHILD = 'passenger.longInfo.child',
  STUDENT = 'passenger.longInfo.student',
  CONSCRIPT = 'passenger.longInfo.conscript',
  PENSIONER = 'passenger.longInfo.pensioner',
  EMPLOYEE = 'passenger.longInfo.employee',
  FDF_CONTRACT = 'passenger.longInfo.fdfContract',
}

export enum ServiceShortMessage {
  PETS = 'passenger.shortInfo.pets',
  BICYCLES = 'passenger.shortInfo.bicycles',
  WHEELCHAIRS = 'passenger.shortInfo.wheelchairs',
  ASSISTANT = 'passenger.shortInfo.assistants',
  CARS = 'passenger.shortInfo.cars',
  MOTORCYCLES = 'passenger.shortInfo.motorcycles',
}
export enum ServiceLongMessage {
  PETS = 'passenger.longInfo.pets',
  BICYCLES = 'passenger.longInfo.bicycles',
  WHEELCHAIRS = 'passenger.longInfo.wheelchairs',
  ASSISTANTS = 'passenger.longInfo.assistants',
  CARS = 'passenger.longInfo.cars',
  MOTORCYCLES = 'passenger.longInfo.motorcycles',
}

export enum SeatServiceDescriptionsMessage {
  SEAT_PET = 'seat.service.pet.description',
  SEAT_LARGE_PET_PENDOLINO = 'seat.service.largePetPendolino.description',
  SEAT_LARGE_PET_INTERCITY = 'seat.service.largePetIntercity.description',
  SEAT_WHEELCHAIR = 'seat.service.wheelchair.description',
  SEAT_RESTAURANT_WAGON_UPSTAIRS = 'seat.service.restaurantWagonUpstairs.description',
  SEAT_FAMILY_COMPARTMENT = 'seat.service.familyCompartment.description',
  SEAT_WORKING_COMPARTMENT = 'seat.service.workingCompartment.description',
  SEAT_CONFERENCE_COMPARTMENT = 'seat.service.conferenceCompartment.description',
  SEAT_IN_PET_WAGON = 'seat.service.inPetWagon.description',
  SEAT_IN_PLAY_WAGON = 'seat.service.inPlayWagon.description',
  SEAT_EKSTRA = 'seat.service.ekstra.description',
  SINGLE_SEAT = 'seat.services.singleSeat.description',
}

export enum PlaceChangeMessage {
  SELECT_NEW_PASSENGER = 'booking.seatChange.selectNewPassenger',
  SELECTED = 'booking.seatChange.selected',
  NEW_SEAT = 'booking.seatChange.newSeat',
  NOT_FOR_PETS = 'booking.seatChange.notForPets',
  NON_ACCESSIBLE_SEAT = 'booking.seatChange.nonAccessibleSeat',
  ONLY_WITH_PETS = 'booking.seatChange.onlyWithPets',
  ONLY_WITH_WHEELCHAIR = 'booking.seatChange.onlyWithWheelchair',
  MOVE_PASSENGER = 'booking.seatChange.movePassenger',
  CONFIRM_NEW_SEAT = 'booking.seatChange.confirmNewSeat',
  CONFIRM_MOVE = 'booking.seatChange.confirmMove',
  SEAT_IN_EKSTRA = 'booking.seatChange.seatInEkstraClass',
  SEAT_IN_EKSTRA_PLURAL = 'booking.seatChange.seatInEkstraClass.plural',
  SINGLE_SEAT_EKSTRA = 'booking.seatChange.singleSeatEkstra',
  SINGLE_SEAT_ECO = 'booking.seatChange.singleSeatEco',
  SINGLE_SEAT_ECO_WITH_PETS = 'booking.seatChange.singleSeatEcoWithPets',
  SINGLE_SEAT_RESTAURANT_UPSTAIRS = 'booking.seatChange.singleSeatRestaurantUpstairs',
  SEAT_IN_ECO = 'booking.seatChange.seatInEcoClass',
  SEAT_IN_ECO_PLURAL = 'booking.seatChange.seatInEcoClass.plural',
  SEAT_IN_RESTAURANT = 'booking.seatChange.seatInUpstairsRestaurant',
  SEAT_IN_RESTAURANT_PLURAL = 'booking.seatChange.seatInUpstairsRestaurant.plural',
  NO_ADDITIONAL_CHARGE = 'booking.seatChange.noAdditionalCharge',
  CHOOSE_YOUR_SEATS = 'booking.chooseYourSeats',
  YOUR_SEATS = 'booking.yourSeats',
  RETRY_SELECTION = 'booking.changeSeats.retrySeatSelection',
  CONFIRM_SEAT_CHANGE = 'booking.confirmSeatSelection',
  ERROR_NO_OFFERS_AVAILABLE = 'booking.seatChange.errors.noOffers',
  ERROR_WAGONMAP_GENERAL = 'booking.wagonmap.error',
  ERROR_SEAT_UNAVAILABLE = 'booking.changeSeats.seatUnavailableError',
  // Night Cabin specific
  CHOOSE_YOUR_BEDS = 'booking.chooseYourBeds',
  YOUR_BEDS = 'booking.yourBeds',
  CONFIRM_CABIN_CHANGE = 'booking.confirmCabinSelection',
  ERROR_CABIN_UNAVAILABLE = 'booking.cabinChange.cabinUnavailableError',
  CABIN_ADD_OR_REPLACE_TITLE = 'booking.cabingChange.cabinAddOrReplaceTitle',
  REPLACE_ONE_OF_CABINS = 'booking.cabinChange.replaceOneOfCabins',
  ADD_TO_CABINS = 'booking.cabinChange.addToCabins',
  REPLACE = 'booking.cabinChange.replace',
  ADD = 'booking.cabinChange.add',
  NEW_CABIN = 'booking.cabinChange.newCabin',
  REMOVE = 'booking.cabinChange.remove',
  REPLACE_THIS_CABIN = 'booking.cabinChange.replaceThisCabin',
  SELECT_CABIN_TO_REPLACE = 'booking.cabinChange.selectCabinToReplace',
  PRICES_AFTER_CONFIRM = 'booking.cabinChange.pricesAfterConfirm',
  // Other
  BOOK = 'booking.seatChange.book', // Shorter version for new design
  BOOK_PLURAL = 'booking.seatChange.book.plural', // Shorter version for new design
  REMOVE_UPGRADE_SHORT = 'booking.seatChange.removeUpgrade.short', // Shorter version for new design
  REMOVE_UPGRADE_SHORT_PLURAL = 'booking.seatChange.removeUpgrade.short.plural', // Shorter version for new design
  ADJACENT_SEAT_ECO = 'booking.seatChange.adjacentSeatEco',
  ADJACENT_SEAT_ECO_PLURAL = 'booking.seatChange.adjacentSeatEco.plural',
  ADJACENT_SEAT_EKSTRA = 'booking.seatChange.adjacentSeatEkstra',
  ADJACENT_SEAT_EKSTRA_PLURAL = 'booking.seatChange.adjacentSeatEkstra.plural',
  ADJACENT_SEAT_RESTAURANT_WAGON = 'booking.seatChange.adjacentSeatRestaurant',
  ADJACENT_SEAT_RESTAURANT_WAGON_PLURAL = 'booking.seatChange.adjacentSeatRestaurant.plural',
  SHOW_SEAT_UPGRADE_INFO = 'booking.seatChange.showSeatUpgradeInfo',
  PET_NOT_BOOKED_CARD_TITLE = 'booking.seatChange.petNotBookedCardTitle',
  PET_NOT_BOOKED_CARD_DESCRIPTION = 'booking.seatChange.petNotBookedCardDescription',
  PET_NOT_BOOKED_CARD_BUTTON_TEXT = 'booking.seatChange.petNotBookedCardButtonText',
  MOVE_ALL = 'booking.seatChange.moveAllDescription',
  MOVE_ALL_BUTTON = 'booking.seatChange.moveAllButtonText',
  COMMERCIAL_TRAIN_PRICE_SPLIT_ALERT = 'booking.seatChange.commercialTrain.split.priceAlert',
  COMMERCIAL_TRAIN_PRICE_JOIN_ALERT = 'booking.seatChange.commercialTrain.join.priceAlert',
}

export enum AdjacentSeatMessage {
  ADD_ADJACENT_SEAT_NUMBER = 'booking.seatChange.adjacent.adjacentSeatForYourself.specific',
  ADD_SEAT = 'booking.seatChange.adjacent.addSeat',
  REMOVE_SEAT = 'booking.seatChange.adjacent.removeSeat',
  ADJACENT_SEAT_BOOKED = 'booking.seatChange.adjacent.adjacentSeatBooked',
  NEW_ADJACENT_SEAT_NOT_AVAILABLE = 'booking.seatChange.adjacent.seatNotAvailable',
}

export enum MultiTicketMessage {
  BUTTON_BOOK_JOURNEY = 'multiTicket.buttonLabel.bookJourney',
  BUTTON_BUY_AGAIN = 'multiTicket.buttonLabel.buyAgain',
  BUTTON_SHOW_ALL_TICKETS = 'multiTicket.ticketList.buttonLabel.showAllTickets',
  BUTTON_TICKET_INFO = 'multiTicket.buttonLabel.ticketInfo',
  ERROR_LOADING_TICKETS = 'multiTicket.ticketList.errorLoadingTicketsMessage',
  EXPIRES_LABEL = 'multiTicket.expiresLabel',
  JOURNEYS_LEFT = 'multiTicket.journeysLeft',
  COMMUTER = 'multiTicket.type.COMMUTER',
  LONG_DISTANCE_AND_COMMUTER = 'multiTicket.type.LONG_DISTANCE_AND_COMMUTER',
  TICKET_LIST_PLACEHOLDER = 'multiTicket.ticketList.placeholderText',
  YOUR_TICKETS = 'multiTicket.ticketList.yourTicketsTitle',
  REMAINING_JOURNEYS = 'multiTicket.remainingJourneys',
  VALIDITY_PERIOD = 'multiTicket.validityPeriod',
  EXPIRATION = 'multiticket.terms.expiration',
  USE = 'multiticket.terms.use',
  SUMMARY_HEADING = 'multiticket.summaryHeading',
  SUMMARY_CORPORATE_SHARE = 'multiTicket.summary.corporate.share',
  SUMMARY_CORPORATE_CANCEL = 'multiTicket.summary.corporate.cancel',
  DELIVERY_HEADER = 'multiticket.ticketdelivery.header',
  DELIVERY_INFO = 'multiticket.ticketdelivery.infotext',
  TERMS_HEADER = 'multiticket.terms.header',
  COMMUTER_SEAT = 'multiticket.seat.commuter',
  SHARE_TICKET = 'multiTicket.shareTicket',
  SHARE_MODAL_TITLE = 'multiTicket.share.modalTitle',
  SHARE_TITLE = 'multiTicket.share.title',
  SHARE_TEXT = 'multiTicket.share.text',
  SHARED_TRIP = 'multiTicket.sharedTrip',
  NOT_VALID = 'multiTicket.notValid',
}

export enum NewsArticlesMessage {
  READ_MORE = 'newsArticles.readMore',
}

export enum StationSearchMessage {
  SWAP_STATIONS = 'booking.stationSearch.swapStations',
  RECENT = 'booking.stationSearch.recent',
  RESULT = 'booking.stationSearch.result',
  RESULT_PLURAL = 'booking.stationSearch.results',
  CAR_STATIONS = 'booking.stationSearch.carStations',
  CAR_STATION_CONNECTIONS = 'booking.stationSearch.carStationConnections',
  NO_RESULTS = 'booking.stationSearchNoResults',
  BUS_CONNECTIONS_GROUP = 'stationSearch.busConnectionsGroup',
  VIA_STATION = 'stationSearch.viaStation',
}

export enum DateSelectMessage {
  TODAY = 'booking.dateSelect.today',
  TOMORROW = 'booking.dateSelect.tomorrow',
  DAY_AFTER_TOMORROW = 'booking.dateSelect.dayAfterTomorrow',
}

export enum BookingMessage {
  DEPARTURE_STATION = 'booking.departureStation',
  DEPARTURE_STATION_SEARCH = 'booking.departureStationSearch',
  DESTINATION_STATION = 'booking.destinationStation',
  DESTINATION_STATION_SEARCH = 'booking.destinationStationSearch',
  NOT_SET = 'booking.notSet',
  NO_RETURN_TICKET = 'booking.noReturnTicket',
  PASSENGERS_LABEL = 'booking.passengersSelectLabel',
  WHO_IS_TRAVELLING = 'booking.whoIsTravelling',
  GROUP_SALES_INFO_WITH_LINK = 'booking.groupSalesInfoWithLink',
  GROUP_SALES_INFO_LINK_LABEL = 'booking.groupSalesInfoLink.label',
  REMOVE_PASSENGER = 'booking.removePassenger',
  OFFER_CARD_RESERVE = 'booking.offerCard.reserve',
  NO_OFFER_REASON_PAST = 'booking.noOfferReason.past',
  NO_OFFER_REASON_UNAVAILABLE = 'booking.noOfferReason.unavailable',
  NO_OFFER_REASON_SOLD_OUT = 'booking.noOfferReason.soldOut',
  NO_OFFER_REASON_BICYCLE_UNAVAILABLE = 'booking.noOfferReason.bicycleUnavailable',
  NO_OFFER_REASON_WHEELCHAIR_UNAVAILABLE = 'booking.noOfferReason.wheelchairUnavailable',
  NO_OFFER_REASON_PET_SEAT_UNAVAILABLE = 'booking.noOfferReason.petSeatUnavailable',
  SERVICES_HEADING_OUTBOUND = 'booking.servicesHeading.outbound',
  SERVICES_HEADING_RETURN = 'booking.servicesHeading.return',
  OUTBOUND = 'booking.outbound',
  RETURN = 'booking.return',
  CONTINUE_OUTBOUND = 'booking.continueOutbound',
  CONTINUE_RETURN = 'booking.continueReturn',
  CONTINUE_OUTBOUND_WITH_PRICE = 'booking.continueOutboundWithPrice',
  CONTINUE_RETURN_WITH_PRICE = 'booking.continueReturnWithPrice',
  CAR_DIMENSIONS_NOTIFICATION_TEXT = 'booking.car.customerIsResponsibleForMeasurements',
  CAR_DIMENSIONS_MODAL_LINK_LABEL = 'booking.car.measurementsModalLink',
  CAR_HEIGHT = 'booking.car.height',
  CAR_HEIGHT_CHECKED = 'booking.car.heightChecked',
  CAR_WIDTH = 'booking.car.width',
  CAR_WIDTH_CHECKED = 'booking.car.widthChecked',
  CAR_LENGTH = 'booking.car.length',
  CAR_LENGTH_CHECKED = 'booking.car.lengthChecked',
  CAR_SIZE_SELECT = 'booking.car.sizeSelect',
  CAR_SIZE_BELOW = 'booking.car.sizeBelow',
  CAR_SIZE_UP_TO = 'booking.car.sizeUpTo',
  CAR_SIZE_OR_MORE = 'booking.car.sizeOrMore',
  CAR_FILL_MISSING_INFO = 'booking.car.fillMissingInfo',
  CAR_TOO_WIDE_NOTIFICATION = 'booking.car.tooWideNotification',
  CAR_LIMITED_LENGTH_NOTIFICATION = 'booking.car.limitedLengthNotification',
  PREVIOUS_STEP_LINK_TEXT = 'booking.previousStep.linkText',
  SELECT_TICKET_TITLE = 'booking.selectTicket.Title',
  NOT_AVAILABLE = 'booking.selectTicket.notAvailable',
  SWITCH_TO_THIS = 'booking.selectTicketType.switchToThis',
  FOR_ALL_PASSENGERS = 'booking.selectTicket.forAllPassengers',
  FOR_BUSINESS_TRAVELLERS = 'booking.selectTicket.forBusinessTravellers',
  SINGLE_TICKET = 'booking.selectTicket.singleTicket',
  SELECTED = 'booking.selectTicket.selected',
  SINGLE_TICKET_FLEX = 'booking.selectTicket.singleTicketFlex',
  CORPORATE_TICKET = 'booking.selectTicket.corporateTicket',
  SEAT_IN_ECO_CLASS = 'booking.ticketAttributes.seatInEcoClass',
  JOURNEY_CHANGES_FEE = 'booking.ticketAttributes.journeyChangesFee',
  OPTIONAL_CANCELLATION_FEE = 'booking.ticketAttributes.optionalCancellationFee',
  FREE_CHANGES_TO_JOURNEY = 'booking.ticketAttributes.freeChangesToJourney',
  CANCELLATION_INSURANCE_INCLUDED = 'booking.ticketAttributes.cancellationInsuranceIncluded',
  SEAT_IN_EXTRA_CLASS = 'booking.ticketAttribute.seatInExtraClass',
  CORPORATE_DISCOUNTS_ONLY_AVAILABLE_FOR_ADULT_PASSENGERS = 'booking.corporate.discountsOnlyAvailableForAdultPassengers',
  CORPORATE_DISCOUNTS_NOT_AVAILABLE_FOR_WHEELCHAIR_PASSENGERS = 'booking.corporate.discountsNotAvailableForWheelchairPassengers',
  CORPORATE_DISCOUNTS_NOT_AVAILABLE_FOR_PASSENGERS_WITH_VEHICLES = 'booking.corporate.discountsNotAvailableForPassengersWithVehicles',
  CORPORATE_TICKET_NOT_AVAILABLE_FOR_PASSENGERS_WITH_PETS = 'booking.corporate.corporateTicketNotAvailableForPassengerWithPets',
  NO_EXTRA_CLASS_OR_SEAT_IN_UPSTAIRS_OF_RESTAURANT_WAGON = 'booking.corporate.noExtraClassOrSeatInUpstairsOfRestaurantWagon',
  NOT_POSSIBLE_TO_BOOK_SLEEPING_CABIN = 'booking.corporate.notPossibleToBookSleepingCabin',
  INCLUDED = 'booking.corporate.included',
  SERVICE_SOLD_OUT = 'booking.services.soldOut',
  SERVICE_SOLD_OUT_PLURAL = 'booking.services.soldOut.plural',
  SERVICE_SOLD_OUT_TITLE = 'booking.services.soldOut.title',
  SERVICES_MISSING_TITLE = 'booking.services.missing.title',
  ONLY_WITH_CORPORATE_TICKET = 'booking.corporate.onlyWithCorporateTicket',
  WOULD_YOU_LIKE_TO_TRAVEL_MORE_COMFORT = 'booking.seating.wouldYouLikeToTravelMoreComfort',
  SELECTED_ADDITIONAL_COMFORT = 'booking.seating.selectedAdditionalComfort',
  SEAT_AVAILABLE_BUT_NOT_BOOKED = 'booking.services.seatAvailableButNotBooked',
  SEAT_AVAILABLE_BUT_NOT_BOOKED_TITLE = 'booking.services.seatAvailableButNotBooked.title',
  SOME_SERVICES_SOLD_OUT = 'booking.services.someServicesAreSoldOut',
  MORE_COMFORTABLE_SEATS_ARE_SOLD_OUT = 'bookings.services.moreComformtableSeatsAreSoldOut',
  EKO_SEATS_SOLD_OUT_NOTICE = 'bookings.services.ekoSeatsSoldOutNotice',
  TICKET_DOWNGRADED_TO_SINGLE_TICKET_FLEX = 'bookings.ticketDowngradedToSingleTicketFlex',
  TICKET_UPGRADED_TO_CORPORATE_TICKET = 'bookings.ticketUpgradedToCorporateTicket',
  ADDITIONAL_SERVICES_ARIA_HEADING = 'booking.seating.additionalProductsList.ariaHeading',
  ADDITIONAL_SERVICES_ARIA_HEADING_SELECTED = 'booking.seating.additionalProductsList.ariaHeading.selected',
  NOT_AVAILABLE_FOR_EMPLOYEE = 'booking.selectTicket.notAvailableForEmployee',
  ADD_RETURN_JOURNEY = 'booking.addReturnJourney',
  EXPIRATION_WARNING_TITLE = 'booking.expirationWarning.title',
  EXPIRATION_WARNING_BODY = 'booking.expirationWarning.body',
  EXPIRATION_WARNING_BODY_SOON = 'booking.expirationWarning.bodySoon',
  GROUP_SALES_DATE_PICKER_GENERAL_FORM_LINK = 'booking.groupSales.datePickerLinkToGeneralForm',
  STATIONS_NOT_SELECTED_NOTIFICATION_TITLE = 'booking.stationsNotSelectedTitle',
  STATIONS_NOT_SELECTED_NOTIFICATION_DESCRIPTION = 'booking.stationsNotSelectedDescription',
  ADD_BUS_LEG_BEFORE_TITLE = 'booking.addBusLegBefore.title',
  ADD_BUS_LEG_BEFORE_BODY = 'booking.addBusLegBefore.body',
  ADD_BUS_LEG_BEFORE_BUTTON = 'booking.addBusLegBefore.button',
  ADD_BUS_LEG_AFTER_TITLE = 'booking.addBusLegAfter.title',
  ADD_BUS_LEG_AFTER_BODY = 'booking.addBusLegAfter.body',
  ADD_BUS_LEG_AFTER_BUTTON = 'booking.addBusLegAfter.button',
  WHAT_SERVICES = 'booking.whatServices',
  CLEAR_SERVICE = 'booking.clearService',
  ADDED_SERVICES_CANCELLATION = 'booking.addedServicesAndCancellation',
  REMOVED_SERVICES = 'booking.removedServices',
  DISCOUNT_GROUPS = 'booking.discountGroups',
  OTHER_PASSENGER_TYPES = 'booking.otherPassengerTypes',
  FDF_CONTRACT_WARNING = 'booking.fdfContractWarning',
  FDF_B2B_WARNING_TITLE = 'booking.fdfB2b.warning.title',
  FDF_B2B_WARNING_BODY = 'booking.fdfB2b.warning.body',
  OFFERS_EXPIRED_TITLE = 'booking.offersExpiredWarning.title',
  OFFERS_EXPIRED_BODY = 'booking.offersExpiredWarning.body',
  OFFERS_EXPIRED_BUTTON_LABEL = 'booking.offersExpiredWarning.reloadButtonLabel',
  ERROR_FAILED_TO_ADD_BICYCLE_TITLE = 'booking.addBicycle.failedToAdd.title',
  ERROR_FAILED_TO_ADD_BICYCLE_BODY = 'booking.addBicycle.failedToAdd.body',
  PREVIOUSLY_ADDED_BICYCLE = 'booking.addBicycle.previouslyAddedBicycle',
  BICYCLE_PLACES_LEFT = 'booking.addBicycle.placesLeft',
  SPACE_LIMITED = 'booking.space.limited',
  SPACE_MODERATE = 'booking.space.moderate',
  SPACE_PLENTY = 'booking.space.plenty',
  FILL_MODAL_BUTTON = 'booking.fillModal.buttonText',
  FILL_MODAL_TITLE = 'booking.fillModal.title',
  FILL_MODAL_DESCRIPTION = 'booking.fillModal.description',
  FILL_MODAL_NOTE = 'booking.fillModal.note',
  SEAT_EXTRA_SERVICES_TITLE = 'booking.seatExtraServices.title',
  SEAT_EXTRA_SERVICES_ADJACENT_SEAT = 'booking.seatExtraServices.adjacentSeat',
  SEAT_EXTRA_SERVICES_SINGLE_SEAT = 'booking.seatExtraServices.singleSeat',
  SEAT_EXTRA_SERVICES_SINGLE_PET_SEAT = 'booking.seatExtraServices.singlePetSeat',
  UPGRADES_NOT_AVAILABLE_TITLE = 'booking.upgradesNotAvailable.title',
  UPGRADES_NOT_AVAILABLE_BODY = 'booking.upgradesNotAvailable.body',
  PASSENGER = 'booking.passenger',
  PASSENGER_PLURAL = 'booking.passenger.plural',
  SELECT_SEATING_CLASS = 'booking.seating.selectSeatingClass',
  CABIN_CLASS = 'booking.seating.cabinClass',
  USE_GROUP_SALES_FORM = 'booking.useGroupSalesForm',
  CHOOSE_PASSENGERS = 'booking.choosePassengers',
  BOOKED_SEATS = 'booking.bookedSeats',
  BOOKED_BICYCLE_PLACES = 'booking.bookedBicyclePlaces',
  BOOKED_CABINS = 'booking.bookedCabins',
  EXTRA_AVAILABLE_WITH_CORPORATE_TICKET = 'booking.extraAvailableWithCorporateTicket',
  ECO_AVAILABLE_WITH_FLEX_TICKET = 'booking.ecoAvailableWithFlexTicket',
}

export enum BusConnectionNotAvailableNoticesMessage {
  SALE_NOT_STARTED_YET = 'busConnectionNotAvailableNotices.saleNotStartedYet',
  SALE_ENDED_ALREADY = 'busConnectionNotAvailableNotices.saleEndedAlready',
  NO_ONLINE_SALE = 'busConnectionNotAvailableNotices.noOnlineSale',
  NO_PASSENGER_AVAILABILITY = 'busConnectionNotAvailableNotices.noPassengerAvailability',
  NO_BIKE_AVAILABILITY = 'busConnectionNotAvailableNotices.noBikeAvailability',
  NOT_FOR_SALE_WITH_WHEELCHAIR = 'busConnectionNotAvailableNotices.notForSaleWithWheelchairs',
  NOT_FOR_SALE_WITH_PET = 'busConnectionNotAvailableNotices.notForSaleWithPets',
  NOT_FOR_SALE_WITH_VEHICLE = 'busConnectionNotAvailableNotices.notForSaleWithVehicles',
  UNKNOWN_REASON = 'busConnectionNotAvailableNotices.unknonwnReason',
}

export enum AddMealsMessage {
  TITLE = 'addMeals.title',
}
export enum BookingMealsMessage {
  DESCRIPTION = 'booking.meals.description',
  GROUP_SALES_ADD_MEALS_PAGE_DESCRIPTION = 'booking.meals.groupSales.addMealsPageDescription',
  PAGE_CONTINUE_WITHOUT_MEALS = 'booking.meals.page.continueWithoutMeals',
  SELECT_TITLE = 'booking.meals.selectTitle',
  ROW_LABEL = 'booking.meals.row.label',
  ROW_ADD_BUTTON = 'booking.meals.row.addButton',
  ROW_COFFEE = 'booking.meals.row.coffee',
  ROW_TEA = 'booking.meals.row.tea',
  ROW_JUICE = 'booking.meals.row.juice',
  ROW_UNKNOWN_DRINK = 'booking.meals.row.unknownDrink',
  PRICE_TITLE = 'booking.meals.price.title',
  PRICE_DESCRIPTION = 'booking.meals.price.description',
  PRICE_TOTAL = 'booking.meals.price.total',
  NO_MEALS = 'booking.meals.noMeals',
  NO_NEW_MEALS = 'booking.meals.noNewMeals',
  CONFIRM = 'booking.meals.confirm',
  UPDATE_FAILED_TITLE = 'booking.meals.updateFailed.title',
  UPDATE_FAILED_BODY = 'booking.meals.updateFailed.body',
  PURCHASE_LIMIT_REACHED = 'booking.meals.dynamicPurchaseLimitReached',
  GROUP_SALES_PURCHASE_LIMIT_REACHED = 'booking.meals.groupSalesDynamicPurchaseLimitReached',
  GROUP_SALES_PAST_DEADLINE = 'booking.meals.groupSalesPastDeadline',
  NO_MEALS_FOR_FDF_CONTRACT = 'booking.meals.noMealsForFfdContract',
}

export enum MealCategoriesMessage {
  BREAKFAST = 'mealCategory.breakfast',
  SMALL_HUNGER = 'mealCategory.smallHunger',
  BIG_HUNGER = 'mealCategory.bigHunger',
  VEGAN = 'mealCategory.vegan',
  DRINK = 'mealCategory.drink',
  LUNCH = 'mealCategory.lunch',
  SNACK = 'mealCategory.snack',
  SWEET = 'mealCategory.sweet',
  MEAT = 'mealCategroy.meat',
  FISH = 'mealCategory.fish',
  SANDWICH_PACKAGE = 'mealCategory.sandwichPackage',
  MAIN_COURSE = 'mealCategory.mainCourse',
  PREMIUM_TREAT = 'mealCategory.premiumTreat',
  WARM_MEAL = 'mealCategory.warmMeal',
  UNKNOWN = 'mealCategory.unknown',
}

export enum MealOptionsMessage {
  'CLUB-1' = 'mealOption.CLUB-1',
  'BREAD-1' = 'mealOption.BREAD-1',
  'BREAD-2' = 'mealOption.BREAD-2',
  'BREAD-3' = 'mealOption.BREAD-3',
  'BREAD-VEG' = 'mealOption.BREAD-VEG',
  UNKNOWN_MEAL = 'mealOption.unknownMealOption',
}

export enum AccessibilityMessage {
  ADD = 'booking.accessibility.add',
  AND = 'booking.accessibility.and',
  OPENS_IN_NEW_TAB = 'generic.accessibility.opensInNewTab',
  RAMP_SERVICE_PARAGRAPH = 'accessibility.rampService.paragraph',
  RAMP_SERVICE_TITLE = 'accessibility.rampService.title',
  RAMP_SERVICE_TITLE_PLURAL = 'accessibility.rampService.title.plural',
  RAMP_SERVICE_MORE_INFORMATION = 'rampService.moreInformation',
  RAMP_SERVICE_ACCESSIBILITY_PAGE = 'rampService.accessibilityPage',
  STATION_ACCESSIBILITY_NOTICE_TITLE = 'booking.stationAccessibilityNotice.title',
  STATION_ACCESSIBILITY_NOTICE_PARAGRAPH = 'booking.stationAccessibilityNotice.paragraph',
  SKIP_TO_CONTENT = 'generic.accessibility.skipToContent',
  DEPARTURE = 'booking.accessibility.departure',
  ARRIVAL = 'booking.accessibility.arrival',
  BREADCRUMB = 'booking.accessibility.breadcrumb',
  CONNECTION_DISABLED = 'accessibility.connectionDisabled',
  ADDED = 'accessibility.additionalServices.added',
  REMOVED = 'accessibility.additionalServices.removed',
  TO_FRONT_PAGE = 'accessibility.toFrontPage',
  NAV_MAIN_NAV_TITLE = 'accessibility.nav.mainNavTitle',
  WHEELCHAIR_WEIGHT_LIMIT_TITLE = 'accessibility.wheelchairWeightLimit.title',
  WHEELCHAIR_WEIGHT_LIMIT_BODY = 'accessibility.wheelchairWeightLimit.body',
}

export enum PassengerSummaryMessage {
  PET = 'booking.passenger_summary.pet',
  PETS = 'booking.passenger_summary.pet.plural',
  BICYCLE = 'product.type.bicycle',
  BICYCLES = 'product.type.bicycle.plural',
  WHEELCHAIR = 'booking.passenger_summary.wheelchair',
  WHEELCHAIRS = 'booking.passenger_summary.wheelchair.plural',
  ASSISTANT = 'booking.passenger_summary.assistant',
  ASSISTANTS = 'booking.passenger_summary.assistant.plural',
  VEHICLE = 'booking.passenger_summary.vehicle',
  VEHICLES = 'booking.passenger_summary.vehicle.plural',
  CAR = 'booking.passenger_summary.car',
  MOTORCYCLE = 'booking.passenger_summary.motorcycle',
  MOTORCYCLES = 'booking.passenger_summary.motorcycle.plural',
}

export enum ProductTypeMessage {
  PET = 'product.type.pet',
  PET_PLURAL = 'product.type.pet.plural',
  BICYCLE = 'product.type.bicycle',
  BICYCLE_PLURAL = 'product.type.bicycle.plural',
}

/** @knipignore this enum is used dynamically */
export enum ReceiptTypeMessage {
  /** Additional services and other */
  ADDITIONAL_SERVICE_BICYCLE = 'receipt.type.additional_service_bicycle',
  ADDITIONAL_SERVICE_BICYCLE_PLURAL = 'receipt.type.additional_service_bicycle.plural',
  ADDITIONAL_SERVICE_PET = 'receipt.type.additional_service_pet',
  ADDITIONAL_SERVICE_PET_PLURAL = 'receipt.type.additional_service_pet.plural',
  ADJACENT_SEAT_ECO = 'receipt.type.adjacent_seat_eco',
  ADJACENT_SEAT_ECO_PLURAL = 'receipt.type.adjacent_seat_eco.plural',
  ADJACENT_SEAT_EXTRA = 'receipt.type.adjacent_seat_extra',
  ADJACENT_SEAT_EXTRA_PLURAL = 'receipt.type.adjacent_seat_extra.plural',
  ADJACENT_SEAT_RESTAURANT_WAGON = 'receipt.type.adjacent_seat_restaurant_wagon',
  ADJACENT_SEAT_RESTAURANT_WAGON_PLURAL = 'receipt.type.adjacent_seat_restaurant_wagon.plural',
  CANCELLATION_INSURANCE_JOURNEY = 'receipt.type.cancellation_insurance_journey',
  CANCELLATION_INSURANCE_JOURNEY_PLURAL = 'receipt.type.cancellation_insurance_journey.plural',
  CANCELLATION_INSURANCE_VEHICLE = 'receipt.type.cancellation_insurance_vehicle',
  CANCELLATION_INSURANCE_VEHICLE_PLURAL = 'receipt.type.cancellation_insurance_vehicle.plural',
  CHANGE_FEE_JOURNEY = 'receipt.type.change_fee_journey',
  CHANGE_FEE_JOURNEY_PLURAL = 'receipt.type.change_fee_journey.plural',
  SEAT_UPGRADE_EXTRA = 'receipt.type.seat_upgrade_extra',
  SEAT_UPGRADE_RESTAURANT_UPSTAIRS = 'receipt.type.seat_upgrade_restaurant_upstairs',
  SEAT_UPGRADE_SINGLE_EXTRA = 'receipt.type.seat_upgrade_single_extra',
  SEAT_UPGRADE_SINGLE_ECO = 'receipt.type.seat_upgrade_single_eco',
  SEAT_UPGRADE_SINGLE_ECO_PET = 'receipt.type.seat_upgrade_single_eco_pet',
  SEAT_UPGRADE_SINGLE_RESTAURANT_UPSTAIRS = 'receipt.type.seat_upgrade_single_restaurant_upstairs',
  SEAT_IN_WORKING_CABIN = 'receipt.type.seat_in_working_cabin',
  SEAT_IN_WORKING_CABIN_PLURAL = 'receipt.type.seat_in_working_cabin.plural',
  SEAT_IN_FAMILY_CABIN = 'receipt.type.single_seat_in_family_cabin',
  SEAT_IN_NEGOTIATION_CABIN = 'receipt.type.seat_in_negotiation_cabin',
  SEAT_IN_NEGOTIATION_CABIN_PLURAL = 'receipt.type.seat_in_negotiation_cabin.plural',

  BERTH_CABIN_WITH_SHOWER = 'receipt.type.berth_cabin_with_shower',
  BERTH_CABIN_WITH_SHOWER_PLURAL = 'receipt.type.berth_cabin_with_shower.plural',
  BERTH_CABIN_WITH_SHOWER_PET = 'receipt.type.berth_cabin_with_shower_pet',
  BERTH_CABIN_WITH_SHOWER_PET_PLURAL = 'receipt.type.berth_cabin_with_shower_pet.plural',
  BERTH_CABIN_EDM = 'receipt.type.berth_cabin_edm',
  BERTH_CABIN_EDM_PLURAL = 'receipt.type.berth_cabin_edm.plural',
  BERTH_CABIN_EDM_ACC = 'receipt.type.berth_cabin_edm_acc',
  BERTH_CABIN_EDM_ACC_PLURAL = 'receipt.type.berth_cabin_edm_acc.plural',
  BERTH_CABIN_EDM_PET = 'receipt.type.berth_cabin_edm_pet',
  BERTH_CABIN_EDM_PET_PLURAL = 'receipt.type.berth_cabin_edm_pet.plural',
  BERTH_CABIN = 'receipt.type.berth_cabin',
  BERTH_CABIN_PLURAL = 'receipt.type.berth_cabin.plural',
  BERTH_CABIN_CEMT = 'receipt.type.berth_cabin_cemt',
  BERTH_CABIN_CEMT_PET = 'receipt.type.berth_cabin_cemt_pet',
  BERTH_CABIN_CEMT_PET_PLURAL = 'receipt.type.berth_cabin_cemt_pet.plural',

  /** Single tickets */
  SINGLE_TICKET_ADULT = 'receipt.type.single_ticket_adult',
  SINGLE_TICKET_ADULT_PLURAL = 'receipt.type.single_ticket_adult.plural',
  SINGLE_TICKET_CHILD = 'receipt.type.single_ticket_child',
  SINGLE_TICKET_CHILD_PLURAL = 'receipt.type.single_ticket_child.plural',
  SINGLE_TICKET_CONSCRIPT = 'receipt.type.single_ticket_conscript',
  SINGLE_TICKET_CONSCRIPT_PLURAL = 'receipt.type.single_ticket_conscript.plural',
  SINGLE_TICKET_CONSCRIPT_CONTRACT = 'receipt.type.single_ticket_conscript_contract',
  SINGLE_TICKET_CONSCRIPT_CONTRACT_PLURAL = 'receipt.type.single_ticket_conscript_contract.plural',
  SINGLE_TICKET_PENSIONER = 'receipt.type.single_ticket_pensioner',
  SINGLE_TICKET_PENSIONER_PLURAL = 'receipt.type.single_ticket_pensioner.plural',
  SINGLE_TICKET_STUDENT = 'receipt.type.single_ticket_student',
  SINGLE_TICKET_STUDENT_PLURAL = 'receipt.type.single_ticket_student.plural',
  SINGLE_TICKET_ASSISTANT = 'receipt.type.single_ticket_assistant',
  SINGLE_TICKET_ASSISTANT_PLURAL = 'receipt.type.single_ticket_assistant.plural',
  SINGLE_TICKET_FDF_CONTRACT = 'receipt.type.single_ticket_fdf_contract',
  SINGLE_TICKET_FDF_CONTRACT_PLURAL = 'receipt.type.single_ticket_fdf_contract.plural',

  /** Season tickets */
  SEASON_TICKET = 'receipt.type.season_ticket',
  SEASON_TICKET_PLURAL = 'receipt.type.season_ticket.plural',

  /** Corporate tickets */
  SINGLE_TICKET = 'receipt.type.single_ticket',
  SINGLE_TICKET_PLURAL = 'receipt.type.single_ticket.plural',
  SINGLE_TICKET_FLEX = 'receipt.type.single_ticket_flex',
  SINGLE_TICKET_FLEX_PLURAL = 'receipt.type.single_ticket_flex.plural',
  CORPORATE_TICKET = 'receipt.type.corporate_ticket',
  CORPORATE_TICKET_PLURAL = 'receipt.type.corporate_ticket.plural',

  /** Generic multi ticket */
  MULTI_TICKET = 'receipt.type.multi_ticket',
  MULTI_TICKET_PLURAL = 'receipt.type.multi_ticket.plural',
  MULTI_TICKET_EKSTRA = 'receipt.type.multi_ticket_ekstra',

  /** Unknown is used when the type cannot be determined */
  UNKNOWN = 'receipt.type.unknown',
  UNKNOWN_PLURAL = 'receipt.type.unknown.plural',

  /** Helsinki region commuter train ticket */
  HSL_TICKET = 'receipt.type.hslTicket',
  HSL_TICKET_PLURAL = 'receipt.type.hslTicket.plural',
  HSL_SERVICE_FEE = 'receipt.type.hslServiceFee',
  HSL_SERVICE_FEE_PLURAL = 'receipt.type.hslServiceFee.plural',

  /** Vehicles */
  VEHICLE = 'receipt.type.vehicle',
  VEHICLE_PLURAL = 'receipt.type.vehicle.plural',
  LONG_VEHICLE = 'receipt.type.vehicleLong',
  LONG_VEHICLE_PLURAL = 'receipt.type.vehicleLong.plural',
  EXTRA_LONG_VEHICLE = 'receipt.type.vehicleExtraLong',
  EXTRA_LONG_VEHICLE_PLURAL = 'receipt.type.vehicleExtraLong.plural',
  MOTORCYCLE = 'receipt.type.motorcycle',
  MOTORCYCLE_PLURAL = 'receipt.type.motorcycle.plural',

  /** Employee */
  SINGLE_TICKET_VR_EMPLOYEE = 'receipt.type.single_ticket_vr_employee',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SINGLE_TICKET_VR_EMPLOYEE_PLURAL = 'receipt.type.single_ticket_vr_employee',
  SINGLE_TICKET_VR_EMPLOYEE_FAMILY_MEMBER = 'receipt.type.single_ticket_vr_employee_family_member',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SINGLE_TICKET_VR_EMPLOYEE_FAMILY_MEMBER_PLURAL = 'receipt.type.single_ticket_vr_employee_family_member',

  /** External services */
  EXTERNAL_BUS_TRIP = 'receipt.type.externalBusTrip',
  EXTERNAL_BUS_SERVICE_FEE = 'receipt.type.externalBusServiceFee',
  EXTERNAL_BUS_SERVICE_FEE_PLURAL = 'receipt.type.externalBusServiceFee.plural',
  EXTERNAL_BUS_BICYCLE = 'receipt.type.externalBusBicycle',
  EXTERNAL_BUS_BICYCLE_PLURAL = 'receipt.type.externalBusBicycle.plural',
}

export enum PriceSummaryMessage {
  OUTBOUND_AMOUNT = 'payment.outboundAmount',
  RETURN_AMOUNT = 'payment.returnAmount',
  OUTBOUND_HEADING = 'booking.summary.outboundHeading',
  RETURN_HEADING = 'booking.summary.returnHeading',
  PREVIOUSLY_PAID = 'booking.summary.previouslyPaid',
  PRICE_INCLUDES = 'booking.summary.priceIncludes',
  CHANGES_TOTAL = 'booking.summary.changesTotal',
  CHANGE_DEPARTURE_HEADING = 'booking.summary.changeDepartureHeading',
}

export enum PaymentReceiptMessage {
  STATE_PAID = 'receipt.state.paid',
  PAID_BY_MULTI_TICKET = 'receipt.labelText.paidByMultiTicket',
  PAID_BY_SEASON_TICKET = 'receipt.labelText.paidBySeasonTicket',
  PAID_BY_DISCOUNT_CODE = 'receipt.labelText.paidByDiscountCode',
  INCLUDED_IN_PRICE = 'receipt.labelText.includedInPrice',
  NO_ADDITIONAL_CHARGES = 'receipt.total.noAdditionalCharges',
  TOTAL = 'payment.total',
  FREE_OF_CHARGE = 'receipt.labelText.freeOfCharge',
}

export enum PaymentConfirmationMessage {
  BOOKING_CONFIRMATION_HEADING_PART_1 = 'booking.confirmationHeading.pt1',
  BOOKING_CONFIRMATION_HEADING_PART_2 = 'booking.confirmationHeading.pt2',
  BOOKING_CONFIRMATION_TICKETS_SENT_TITLE = 'booking.ticketsSentTitle',
  BOOKING_CONFIRMATION_RECEIPT_SENT_TITLE = 'booking.receiptSentTitle',
  BOOKING_CONFIRMATION_RECEIPT_SENT_CONTENT = 'booking.receiptSentContent.v2',
  VEHICLE_LOADING_HEADING = 'booking.vehicleInfoTitle',
  VEHICLE_LOADING_BODY = 'booking.vehicleInfoBody',
  VEHICLE_LOADING_BODY_PLURAL = 'booking.vehicleInfoBody.plural',
  VEHICLE_OPEN_INSTRUCTIONS = 'booking.vehicleOpenInstructions',
  DONE_BUTTON = 'payment.doneButton',
  PAYMENT_METHOD_DEBIT = 'payment.debitCard',
  PAYMENT_METHOD_NETBANK = 'payment.netBank',
  PAYMENT_METHOD_DESCRIPTION_NETS = 'payment.paymentMethodDescription.nets',
  PAYMENT_METHOD_DESCRIPTION_PAYTRAIL = 'payment.paymentMethodDescription.paytrail',
  PAYMENT_METHOD_DESCRIPTION_MOBILEPAY = 'paymentMethodDescription.mobilePay',
  PAYMENT_METHOD_INVOICE = 'payment.invoice',
  PAYMENT_METHOD_MOBILEPAY = 'payment.mobilePay',
  SELECT_PAYMENT_METHOD = 'payment.selectPaymentMethod',
  ADDITIONAL_SERVICES_PAID_SEPARATELY = 'payment.additionalServicesArePaidSeparately',
  PAYMENT_METHOD_OF_ADDITIONAL_SERVICES = 'payment.paymentMethodOfAdditionalServices',
  MODAL_HEADING = 'payment.confirmEmail.heading',
  MODAL_BODY = 'payment.confirmEmail.body.v2',
  CONFIRM_MODAL_BUTTON_ACCEPT = 'payment.confirmEmailOrLogin.buttonAccept',
  DELIVERY_METHOD_EMAIL_PLACEHOLDER = 'payment.deliveryMethodEmailPlaceholder',
  DELIVERY_METHOD_EMAIL_ADD_ANOTHER = 'payment.deliveryMethodEmailAddAnother',
  DELIVERY_METHOD_MULTIPLE_TICKETS = 'payment.deliveryMethod.multipleTickets',
  DELIVERY_METHOD_SINGLE_TICKET = 'payment.deliveryMethod.singleTicket',
  ACCEPTED_PAYMENT_CARDS = 'payment.acceptedPaymentCards',
  THANK_YOU_EMAIL = 'booking.thankYouMessage.email.v2',
  THANK_YOU_CHANGES = 'booking.thankYouMessage.changes',
  THANK_YOU_GROUP_SALES = 'booking.thankYouMessage.groupSales',
  THANK_YOU_MATKALLA = 'booking.thankYouMessage.matkalla',
  THANK_YOU_SHARING = 'booking.thankYouMessage.sharing',
  EMPLOYEE_NOTICE_TITLE = 'booking.employeeNoticeTitle',
  EMPLOYEE_NOTICE_DESCRIPTION = 'booking.employeeNoticeDescription',
  EMPLOYEE_NOTICE_TITLE_WITH_FAMILY_MEMBER = 'booking.employeeNoticeTitleWithFamilyMember',
  EMPLOYEE_NOTICE_DESCRIPTION_WITH_FAMILY_MEMBER = 'booking.employeeNoticeDescriptionWithFamilyMember',
  TO_OUTBOUND_TRIP = 'booking.toOutboundTrip',
  TO_RETURN_TRIP = 'booking.toReturnTrip',
  TO_TRIP = 'booking.toTrip',
  EXTERNAL_BUS_SERVICES_NOTIFICATION = 'booking.externalBusServicesNotification',
  SMALL_GROUP_NOTICE_TITLE = 'booking.smallGroupNotice.title',
  SMALL_GROUP_NOTICE_BODY = 'booking.smallGroupNotice.body',
}

export enum InvoicePaymentMessage {
  INSTRUCTION = 'payment.invoice.instruction',
  INSTRUCTION_TITLE = 'payment.invoice.instructionTitle',
  SCHEDULE_TITLE = 'payment.invoice.schedule',
  SCHEDULE_OPTION_CYCLE = 'payment.invoice.option.cycle',
  SCHEDULE_OPTION_SINGLE = 'payment.invoice.option.single',
  REFERENCE_FOR_ALL = 'payment.invoice.referenceForAll',
  PASSENGER_REFERENCE = 'payment.invoice.passengerReference',
  CONFIRM_PAYMENT = 'payment.invoice.confirmButton',
  EDIT_REFERENCES = 'payment.invoice.editReferences',
  OUTBOUND = 'booking.outbound',
  RETURN = 'booking.return',
}

export enum EditPaymentReferencesMessage {
  TITLE = 'editPaymentReferences.title',
  TITLE_GROUP = 'editPaymentReferences.title.group',
  PASSENGER_REFERENCE = 'editPaymentReferences.passengerReference',
  REFERENCES_INFO = 'editPaymentReferences.referencesInfo',
  REFERENCES_INFO_GROUP = 'editPaymentReferences.referencesInfo.group',
  SAVE_EDITS = 'editPaymentReferences.saveEdits',
  EDIT_TIME_PASSED_TITLE = 'editPaymentReferences.editTimePassed.title',
  EDIT_TIME_PASSED_BODY = 'editPaymentReferences.editTimePassed.body',
  EDIT_SAVED_TITLE = 'editPaymentReferences.edit.saved.title',
}

export enum PaymentMethodMessage {
  MOBILEPAY = 'payment.method.MOBILEPAY',
  SIIRTO = 'payment.method.SIIRTO',
  ONLINE_BANK_OR_CASH = 'payment.method.ONLINE_BANK_OR_CASH',
  CARD = 'payment.method.CARD',
  OTHER = 'payment.method.OTHER',
  INVOICING = 'payment.method.INVOICE',
}

export enum ReplacementInfoMessage {
  KLA_INFO_NOTICE_TITLE = 'kla.infoNotice.title',
  KLA_INFO_NOTICE_BODY = 'kla.infoNotice.body',
  KLA_INFO_NOTICE_WHEELCHAIR_PASSENGER_TAXI_TITLE = 'kla.infoNotice.wheelchair.taxi.title',
  KLA_INFO_NOTICE_WHEELCHAIR_PASSENGER_TAXI_BODY = 'kla.infoNotice.wheelchair.taxi.body',
  HLA_INFO_NOTICE_TITLE = 'hla.infoNotice.title',
  HLA_INFO_NOTICE_BODY = 'hla.infoNotice.body',
}

export enum PaymentNotificationsMessage {
  GENERAL_ERROR_TITLE = 'payment.generalErrorTitle',
  GENERAL_ERROR_BODY = 'payment.generalErrorBody',
  PAYMENT_FAILED_TITLE = 'payment.paymentFailedTitle',
  PAYMENT_FAILED_BODY = 'payment.paymentFailed',
  PAYMENT_FAILED_USE_DIFFERENT_TITLE = 'payment.paymentFailedUseDifferent.title',
  PAYMENT_FAILED_USE_DIFFERENT_BODY = 'payment.paymentFailedUseDifferent.body',
  PAYMENT_CANCELLED_TITLE = 'payment.paymentCancelledTitle',
  PAYMENT_CANCELLED_BODY = 'payment.paymentCancelled',
  PAYMENT_STATUS_TITLE = 'payment.status.title',
  PAYMENT_STATUS_WARNING_BODY_PLURAL = 'payment.status.warning.body.plural',
  PAYMENT_STATUS_CRITICAL_BODY_PLURAL = 'payment.status.critical.body.plural',
  MISSING_REQUIRED_INFO_TITLE = 'payment.missingInfo.title',
  MISSING_REQUIRED_INFO_BODY = 'payment.missingInfo.body',
  MOBILE_PAY_PAYMENT_DONE_TITLE = 'mobilepay.payment.done.title',
  MOBILE_PAY_PAYMENT_DONE_BODY = 'mobilepay.payment.done.body',
  FINALIZATION_FAILED_TITLE = 'payment.finalizationFailed.title',
  FINALIZATION_FAILED_BODY = 'payment.finalizationFailed.body',
}

export enum ErrorsMessage {
  FAILED_TO_FETCH = 'error.failedToFetchData',
  FAILED_TO_FETCH_BODY = 'error.failedToFetchDataBody',
  ERROR_BOUNDARY_TITLE = 'error.somethingWentWrong.pageTitle',
  ERROR_BOUNDARY_HEADLINE = 'error.somethingWentWrong.headline',
  ERROR_BOUNDARY_BODY = 'error.somethingWentWrong.body',
  ERROR_BOUNDARY_BUTTON = 'error.somethingWentWrong.button',
  NOT_ENOUGH_TRIPS_ON_MULTITICKET_TITLE = 'error.notEnoughTripsMultiTicket.title',
  NOT_ENOUGH_TRIPS_ON_MULTITICKET_BODY = 'error.notEnoughTripsMultiTicket.body',
  LOCAL_STORAGE_DISABLED_TITLE = 'error.localStorageDisabled.title',
  LOCAL_STORAGE_DISABLED_BODY = 'error.localStorageDisabled.body',
  GROUP_SALES_AVECRA_DEADLINE_TITLE = 'error.groupSalesAvecraDeadline.title',
  GROUP_SALES_AVECRA_DEADLINE_BODY = 'error.groupSalesAvecraDeadline.body',
  UNSUPPORTED_BROWSER_TITLE = 'error.unsupportedBrowser.title',
  UNSUPPORTED_BROWSER_BODY = 'error.unsupportedBrowser.body',
}

export enum SalesSessionErrorsMessage {
  TITLE = 'error.salesSession.generic.pageTitle',
  TITLE_EXPIRED = 'error.salesSession.generic.pageTitle.expired',

  SINGLE_TICKET_HEADLINE = 'error.salesSession.singleTicket.headline',
  SINGLE_TICKET_BODY = 'error.salesSession.singleTicket.body',
  SINGLE_TICKET_HEADLINE_EXPIRED = 'error.salesSession.singleTicket.headline.expired',
  SINGLE_TICKET_BODY_EXPIRED = 'error.salesSession.singleTicket.body.expired',

  MULTI_TICKET_HEADLINE = 'error.salesSession.multiTicket.headline',
  MULTI_TICKET_BODY = 'error.salesSession.multiTicket.body',
  MULTI_TICKET_BUTTON = 'error.salesSession.multiTicket.button',
  MULTI_TICKET_HEADLINE_EXPIRED = 'error.salesSession.multiTicket.headline.expired',
  MULTI_TICKET_BODY_EXPIRED = 'error.salesSession.multiTicket.body.expired',

  CHANGE_DEPARTURE_HEADLINE = 'error.salesSession.changeDeparture.headline',
  CHANGE_DEPARTURE_BODY = 'error.salesSession.changeDeparture.body',
  CHANGE_DEPARTURE_BUTTON = 'error.salesSession.changeDeparture.button',
  CHANGE_DEPARTURE_HEADLINE_EXPIRED = 'error.salesSession.changeDeparture.headline.expired',
  CHANGE_DEPARTURE_BODY_EXPIRED = 'error.salesSession.changeDeparture.body.expired',

  CHANGE_SEAT_HEADLINE = 'error.salesSession.changeSeat.headline',
  CHANGE_SEAT_BODY = 'error.salesSession.changeSeat.body',
  CHANGE_SEAT_BUTTON = 'error.salesSession.changeSeat.button',
  CHANGE_SEAT_HEADLINE_EXPIRED = 'error.salesSession.changeSeat.headline.expired',
  CHANGE_SEAT_BODY_EXPIRED = 'error.salesSession.changeSeat.body.expired',

  ADD_VEHICLE_HEADLINE = 'error.salesSession.addVehicle.headline',
  ADD_VEHICLE_BODY = 'error.salesSession.addVehicle.body',
  ADD_VEHICLE_BUTTON = 'error.salesSession.addVehicle.button',
  ADD_VEHICLE_HEADLINE_EXPIRED = 'error.salesSession.addVehicle.headline.expired',
  ADD_VEHICLE_BODY_EXPIRED = 'error.salesSession.addVehicle.body.expired',

  ADD_BICYCLE_HEADLINE = 'error.salesSession.addBicycle.headline',
  ADD_BICYCLE_BODY = 'error.salesSession.addBicycle.body',
  ADD_BICYCLE_BUTTON = 'error.salesSession.addBicycle.button',
  ADD_BICYCLE_HEADLINE_EXPIRED = 'error.salesSession.addBicycle.headline.expired',
  ADD_BICYCLE_BODY_EXPIRED = 'error.salesSession.addBicycle.body.expired',

  PREORDER_HEADLINE = 'error.salesSession.preOrder.headline',
  PREORDER_BODY = 'error.salesSession.preOrder.body',
  PREORDER_BUTTON = 'error.salesSession.preOrder.button',
  PREORDER_HEADLINE_EXPIRED = 'error.salesSession.preOrder.headline.expired',
  PREORDER_BODY_EXPIRED = 'error.salesSession.preOrder.body.expired',

  GROUP_SALES_HEADLINE = 'error.salesSession.groupSales.headline',
  GROUP_SALES_BODY = 'error.salesSession.groupSales.body',
  GROUP_SALES_HEADLINE_EXPIRED = 'error.salesSession.groupSales.headline.expired',
  GROUP_SALES_BODY_EXPIRED = 'error.salesSession.groupSales.body.expired',

  OFFER_NOT_AVAILABLE_TITLE = 'error.salesSession.offerNotAvailable.title',
  OFFER_NOT_AVAILABLE_BODY = 'error.salesSession.offerNotAvailable.body',
  OFFER_NOT_AVAILABLE_BUTTON = 'error.salesSession.offerNotAvailable.button',

  APPLY_LONG_TERM_CODE_FAILED_TITLE = 'error.salesSession.applyLongTermCodeFailed.title',
  APPLY_LONG_TERM_CODE_FAILED_BODY = 'error.salesSession.applyLongTermCodeFailed.body',
  APPLY_LONG_TERM_CODE_FAILED_BODY_DAILY_LIMIT = 'error.salesSession.applyLongTermCodeFailed.body.dailyLimit',
  APPLY_LONG_TERM_CODE_FAILED_BODY_OVERLAPPING = 'error.salesSession.applyLongTermCodeFailed.body.overlapping',
  APPLY_LONG_TERM_CODE_FAILED_BODY_LIMIT = 'error.salesSession.applyLongTermCodeFailed.body.limit',
}

export enum CiamErrorsMessage {
  NO_ROLES_TITLE = 'error.ciam.noRoles.title',
  NO_ROLES_DESCRIPTION = 'error.ciam.noRoles.description',
}

export enum CookieConsentMessage {
  SETTINGS_TITLE = 'cookieConsent.settings.title',
  MESSAGE_HIGHLIGHTED = 'cookieConsent.message.highlighted',
  PRIMARY_CONTENT = 'cookieConsent.message.primaryContent',
  READ_MORE = 'cookieConsent.readMore',
  SECONDARY_CONTENT = 'cookieConsent.message.secondaryContent',
  BUTTON_ACCEPT_ALL = 'cookieConsent.buttonAccept',
  BUTTON_ACCEPT_SELECTED = 'cookieConsent.buttonAcceptSelected',
  CUSTOMIZE_SETTINGS = 'cookieConsent.customiseSettings',
  CONTINUE_WITH_NECESSARY = 'cookieConsent.continueWithNecessary',
  LEVEL_1_TITLE = 'cookieConsent.settings.consentLevel1.title',
  LEVEL_1_DESC = 'cookieConsent.settings.consentLevel1.description',
  LEVEL_2_TITLE = 'cookieConsent.settings.consentLevel2.title',
  LEVEL_2_DESC = 'cookieConsent.settings.consentLevel2.description',
  LEVEL_3_TITLE = 'cookieConsent.settings.consentLevel3.title',
  LEVEL_3_DESC = 'cookieConsent.settings.consentLevel3.description',
  LEVEL_4_TITLE = 'cookieConsent.settings.consentLevel4.title',
  LEVEL_4_DESC = 'cookieConsent.settings.consentLevel4.description',
  READ_MORE_ABOUT_COOKIE_POLICY = 'cookieConsent.settings.readMoreAboutCookiePolicy',
  MODAL_DESCRIPTION = 'cookieConsent.settings.modalDescription',
  DESCRIPTION = 'cookieConsent.settings.description',
  SETTINGS_CHANGED_MESSAGE = 'cookieConsent.settings.settingsChangedMessage',
}

export enum JourneySummaryMessage {
  HEADING = 'booking.summaryHeading',
  MULTI_TICKET_REDEMPTION_HEADING = 'booking.redemptionSummaryHeading',
  CONFIRM_PAYMENT = 'payment.confirmPayment',
  CONFIRM_BOOKING = 'payment.confirmBooking',
  CONFIRM_CHANGE = 'payment.confirmChange',
  I_AGREE_TO = 'termsAndConditions.iAgreeTo',
  TERMS = 'termsAndConditions.terms',
  PERSONAL_INFO = 'termsAndConditions.personalInfo',
  FILL_EMAIL = 'payment.fillEmail',
  LOGIN_PROMPT_TITLE = 'payment.loginPrompt.title',
  LOGIN_PROMPT_DESCRIPTION = 'payment.loginPrompt.description',
  LOGIN_PROMPT_GROUP_SALES_DESCRIPTION = 'login.loginPrompt.groupSalesDescription',
  LOGIN_PROMPT_BUTTON = 'payment.loginPrompt.button',
  LOGIN_PROMPT_SIGN_UP = 'login.registration.button',
  LOGIN_PROMPT_ANONYMOUS_BUTTON = 'login.loginPrompt.anonymousButton',
  LOGIN_PROMPT_PURCHASE_WITHOUT_LOGIN = 'login.loginPrompt.purchaseWithoutLogin',
  ADD_RETURN_TITLE = 'payment.addReturnPrompt.title',
  ADD_RETURN_DESCRIPTION = 'payment.addReturnPrompt.description',
  CHECK_VEHICLE_INFORMATION = 'journeySummary.checkVehicleInformation',
  CONTACT_PERSON = 'journeySummary.contactPerson',
  STATED_DIMENSIONS = 'journeySummary.statedDimensions',
  CONSCRIPT_CONFIRMATION_HEADING = 'journeySummary.conscriptConfirmation.heading',
  CONSCRIPT_CONFIRMATION_DESCRIPTION = 'journeySummary.conscriptConfirmation.description',
  CONSCRIPT_CONFIRMATION_CHECKBOX_LABEL = 'journeySummary.conscriptConfirmation.checkboxLabel',
  CONSCRIPT_CONFIRMATION_CODE_NOT_ADDED_NOTICE_TITLE = 'journeySummary.conscriptConfirmation.codeNotAddedNoticeTitle',
  CONSCRIPT_CONFIRMATION_CODE_NOT_ADDED_NOTICE_BODY = 'journeySummary.conscriptConfirmation.codeNotAddedNoticeBody',
  LONG_TERM_CONSCRIPT_CODE_INFO = 'journeySummary.longTermConscriptCodeInfo',
  SINGLE_USE_CONSCRIPT_CODE_INFO = 'journeySummary.singleUseConscriptCodeInfo',
  CABINS_NOT_DISCOUNTED_FOR_CONSCRIPT_TITLE = 'journeySummary.cabinsNotDiscountedForConscript.title',
  CABINS_NOT_DISCOUNTED_FOR_CONSCRIPT_TEXT = 'journeySummary.cabinsNotDiscountedForConscript.text',
}

export enum ArticlesMessage {
  PREVIEW_CARDS_TITLE = 'articles.previewCards.title',
  FEATURED_ARTICLES_TITLE_LATEST_NEWS_ARTICLES = 'articles.featured.title.latestNewsArticles',
  FEATURED_ARTICLES_TITLE_LATEST_INSPIRATIONAL_ARTICLES = 'articles.featured.title.latestInspirationalArticles',
  FEATURED_ARTICLES_NEWS_CATEGORY = 'articles.featured.category.news',
  TOPIC_CAROUSEL_TITLE = 'articles.topicCarousel.title',
  VIEW_MORE = 'articles.previewCards.viewMoreArticles',
}

export enum WagonMapMessage {
  LOADING_COACH_ERROR = 'wagonmap.coachError',
  SHOW_UPSTAIRS_LABEL = 'wagonmap.showUpstairs',
  SHOW_DOWNSTAIRS_LABEL = 'wagonmap.showDownstairs',
  COACH_LABEL = 'wagonmap.coach',
  UPSTAIRS_LABEL = 'wagonmap.upstairs',
  DOWNSTAIRS_LABEL = 'wagonmap.downstairs',
  CARTRANSPORT_NOTICE = 'wagonmap.cartransportcoach',
  PLANNED_TRAVEL_DIRECTION = 'wagonmap.plannedTrainDirection.plannedDirection',
  FROM_KOUVOLA = 'wagonmap.plannedTrainDirection.fromKouvola',
  FROM_TAMPERE = 'wagonmap.plannedTrainDirection.fromTampere',
  TO_KOUVOLA = 'wagonmap.plannedTrainDirection.toKouvola',
  TO_TAMPERE = 'wagonmap.plannedTrainDirection.toTampere',
  COMMERCIAL_TRAIN_TO = 'wagonmap.commercialTrain.to',
  COMMERCIAL_TRAIN_FROM = 'wagonmap.commercialTrain.from',
  NO_DIRECTION_AVAILABLE = 'wagonmap.plannedTrainDirection.noDirectionAvailable',
  FATAL_ERROR = 'wagonmap.fatalError',
  TRY_AGAIN = 'wagonmap.tryAgain',
  SERVICES = 'wagonmap.services',
  MEASUREMENTS = 'wagonmap.measurements',
  CHANGE_SEAT = 'wagonmap.changeSeat',
  CHANGE_CABIN = 'wagonmap.changeCabin',
  VIEW_IN_WAGON_MAP = 'booking.viewInWagonMap',
  SEAT_LEGEND_TITLE = 'wagonmap.seatLegend.title',
  SEAT_LEGEND_FREE = 'wagonmap.seatLegend.free',
  SEAT_LEGEND_SELECTED = 'wagonmap.seatLegend.selected',
  SEAT_LEGEND_UNAVAILABLE = 'wagonmap.seatLegend.unavailable',
  SEAT_LEGEND_HIGHER_PRICE = 'wagonmap.seatLegend.higherPrice',
}

export enum CancellationInsuranceMessage {
  SUMMARY_SECTION_TITLE = 'cancellationInsurance.summarySectionTitle',
  TITLE = 'cancellationInsurance.title',
  TITLE_VEHICLE = 'cancellationInsurance.title.vehicle',
  TITLE_VEHICLE_PLURAL = 'cancellationInsurance.title.vehicle.plural',
  TITLE_VEHICLE_UNAVAILABLE = 'cancellationInsurance.title.vehicle.unavailable',
  TITLE_VEHICLE_UNAVAILABLE_PLURAL = 'cancellationInsurance.title.vehicle.unavailable.plural',
  DESCRIPTION = 'cancellationInsurance.description',
  DESCRIPTION_VEHICLE = 'cancellationInsurance.description.vehicle',
  DESCRIPTION_VEHICLE_UNAVAILABLE = 'cancellationInsurance.description.vehicle.unavailable',
  DESCRIPTION_FOR_CORPORATE_PRODUCT = 'cancellationInsurance.descriptionForCorporateProduct',
  COVERAGE_WARNING_OUTBOUND_FULL_RETURN_PARTIAL = 'cancellationInsurance.coverageWarning.outboundFull.returnPartial',
  COVERAGE_WARNING_OUTBOUND_FULL_RETURN_NONE = 'cancellationInsurance.coverageWarning.outboundFull.returnNone',
  COVERAGE_WARNING_OUTBOUND_PARTIAL_RETURN_FULL = 'cancellationInsurance.coverageWarning.outboundPartial.returnFull',
  COVERAGE_WARNING_OUTBOUND_PARTIAL_RETURN_PARTIAL = 'cancellationInsurance.coverageWarning.outboundPartial.returnPartial',
  COVERAGE_WARNING_OUTBOUND_PARTIAL_RETURN_NONE = 'cancellationInsurance.coverageWarning.outboundPartial.returnNone',
  COVERAGE_WARNING_OUTBOUND_PARTIAL_NO_RETURN = 'cancellationInsurance.coverageWarning.outboundPartial.noReturn',
  COVERAGE_WARNING_OUTBOUND_NONE_RETURN_FULL = 'cancellationInsurance.coverageWarning.outboundNone.returnFull',
  COVERAGE_WARNING_OUTBOUND_NONE_RETURN_PARTIAL = 'cancellationInsurance.coverageWarning.outboundNone.returnPartial',
  COVERAGE_WARNING_NO_VEHICLES = 'cancellationInsurance.coverageWarning.noVehicles',
  COVERAGE_WARNING_OUTBOUND_COVERED_BY_CORPORATE_TICKET = 'cancellationInsurance.coverageWarning.outboundCoveredByCorporateTicket',
  COVERAGE_WARNING_RETURN_COVERED_BY_CORPORATE_TICKET = 'cancellationInsurance.coverageWarning.returnCoveredByCorporateTicket',
  COVERAGE_WARNING_CORPORATE_CANCELLATION_FEE = 'cancellationInsurance.coverageWarning.corporate.cancellationFeeNotReturned',
  INFO_LINK = 'cancellationInsurance.infoLink',
  INFO_LINK_VEHICLE = 'cancellationInsurance.infoLink.vehicle',
  INFO_LINK_CORPORATE_PRODUCT = 'cancellationInsurance.infoLinkCorporateProduct',
  ADD_TO_ORDER_ERROR_NOTIFICATION_TITLE = 'cancellationInsurance.addToOrderErrorNotification.title',
  ADD_TO_ORDER_ERROR_NOTIFICATION_BODY = 'cancellationInsurance.addToOrderErrorNotification.body',
  REMOVE_FROM_ORDER_ERROR_NOTIFICATION_TITLE = 'cancellationInsurance.removeFromOrderErrorNotification.title',
  REMOVE_FROM_ORDER_ERROR_NOTIFICATION_BODY = 'cancellationInsurance.removeFromOrderErrorNotification.body',
  NO_CANCELLATION_INSURANCE = 'cancellationInsurance.noCancellationInsurance',
  CANCELLATION_INSURANCE_FOR_YOUR_TRIP = 'cancellationInsurance.cancellationInsuranceForYourTrip',
  BUS_CONNECTIONS_NOT_COVERED = 'cancellationInsurance.busConnectionsNotCovered',
  MATKAHUOLTO_INFO_LINK = 'cancellationInsurance.matkahuoltoInfoLink',
  GOODWILL_CANCELLATION_NOTICE_TITLE = 'cancellationInsurance.goodwill.notice.title',
  GOODWILL_CANCELLATION_NOTICE_BODY = 'cancellationInsurance.goodwill.notice.body',
}

/** @knipignore this enum is used dynamically */
export enum CabinsMessage {
  ADD_MORE_CABINS = 'cabins.wantMoreCabins',
  SEAT_IN_WORKING_CABIN = 'cabins.seatInWorkingCabin',
  SEAT_IN_FAMILY_CABIN = 'cabins.seatInFamilyCabin',
  SEAT_IN_NEGOTIATION_CABIN = 'cabins.seatInNegotiationCabin',
  SEAT_IN_NEGOTIATION_CABIN_PENDOLINO = 'cabins.seatInNegotiationCabin.pendolino',
  CABIN_ARIA_HEADING = 'cabins.accessibility.ariaHeading',
  CABIN_ARIA_HEADING_SELECTED = 'cabins.accessibility.ariaHeading.selected',
  BERTH_CABIN = 'cabins.berthCabin',
  BERTH_CABIN_EDM = 'cabins.berthCabinEDM',
  BERTH_CABIN_EDM_PET = 'cabins.berthCabinEDMpet',
  BERTH_CABIN_EDM_ACC = 'cabins.berthCabinEDMacc',
  BERTH_CABIN_WITH_SHOWER = 'cabins.berthCabinWithShower',
  BERTH_CABIN_WITH_SHOWER_PET = 'cabins.berthCabinWithShowerPet',
  BERTH_CABIN_CEMT = 'cabins.berthCabinCEMT',
  BERTH_CABIN_CEMT_PET = 'cabins.berthCabinCEMTpet',
  DROP_DOWN_TITLE_BED_CABINS = 'cabins.dropDown.titleBedCabins',
  DROP_DOWN_BODY_BED_CABINS = 'cabins.dropDown.bodyBedCabins',
  DROP_DOWN_TITLE_DAY_CABINS = 'cabins.dropDown.titleDayCabins',
  DROP_DOWN_BODY_DAY_CABINS = 'cabins.dropDown.bodyDayCabins',
  PRICE = 'cabins.price',
  NIGHT_CABINS = 'cabins.nightCabins',
  DAY_COMPARTMENTS = 'cabins.dayCompartments',
}

export enum NoticesMessage {
  ONLY_SEATS_WITH_ADDITIONAL_FEE_LEFT = 'notice.onlySeatsWithAdditionalFeeLeft',
  ONLY_UPSTAIRS_RESTAURANT_WAGON_SEATS_LEFT = 'notice.onlyUpstairsRestaurantWagonSeatsLeft',
  ONLY_EKSTRA_SEATS_LEFT = 'notice.onlyEkstraSeatsLeft',
  LIMITED_CABIN_AVAILABILITY = 'notice.limitedCabinAvailability',
  NO_CABINS_AVAILABLE = 'notice.noCabinsAvailable',
  NO_ACCESSIBLE_CABINS_AVAILABLE = 'notice.noAccessibleCabinsAvailable',
  NO_PET_CABINS_AVAILABLE = 'notice.noPetCabinsAvailable',
  NO_ACCESSIBLE_OR_PET_CABINS_AVAILABLE = 'notice.noPetOrAccessibleCabinsAvailable',
}

export enum FeedbackMessage {
  TITLE = 'feedback.title',
  BODY = 'feedback.body',
  BUTTON = 'feedback.button',
  BUTTON_LINK = 'feedback.button.link',
}

export enum PasswordMessage {
  HIDE = 'password.hide',
  SHOW = 'password.show',
}

export enum ContentPagesMessage {
  UPDATE_LABEL = 'newsSection.articleUpdatedLabel',
  CLOSE_MOBILE = 'foldingList.closeMobile',
  INPUT_PLACEHOLDER = 'stationPageSearch.inputPlaceholder',
  STATION_SEARCH_LABEL = 'stationSearch.label',
  CLOCK = 'traffic.clockTranslation',
  ANCHOR_ID = 'anchorId.tripSearch',
  PHOTO_CREDIT_LABEL = 'photoCreditLabel',
}

export enum DynamicMapMessage {
  TVM = 'interactiveMap.tvm',
  NO_TVM = 'interactiveMap.noTvm',
  HAS_TICKET_SALES = 'interactiveMap.hasTicketSales',
  NO_TICKET_SALES = 'interactiveMap.noTicketSales',
  ASSISTANCE = 'interactiveMap.assistance',
  NO_ASSISTANCE = 'interactiveMap.noAssistance',
  STATION_LINK_LABEL = 'interactiveMap.stationPageLinkLabel',
  READ_MORE = 'dynamicMap.readMore',
}

export enum ProductMessage {
  P300 = 'product.P300',
  P100 = 'product.P100',
  COMMUTER = 'product.COMMUTER',
}

export enum FrontPageMessage {
  APP_STORE_LINK = 'frontpage.accessibility.AppStoreLink',
  PLAY_STORE_LINK = 'frontpage.accessibility.PlayStoreLink',
}

export enum StationMessage {
  TICKET_SALES_OPENING_HOURS = 'station.ticketSalesOpeningHours',
  WAITING_HALL_OPENING_HOURS = 'station.waitingHallOpeningHours',
  EXCEPTIONS_IN_OPENING_HOURS = 'station.exceptionsInOpeningHours',
  NO_SERVICE = 'station.noServices',
  SHOW_ON_MAP = 'station.showOnMap',
  SERVICE_DETAILS_TITLE = 'station.serviceDetailsTitle',
  VR_SERVICES = 'station.serviceCategory.VRServices',
  GENERAL_SERVICES = 'station.serviceCategory.generalServices',
  ACCESSIBILITY = 'station.serviceCategory.accessibility',
}

export enum HeaderMessage {
  MENU = 'header.menu',
}

/** @knipignore this enum is used dynamically */
export enum JourneyLegServicesMessage {
  PETS = 'services.PETS',
  BICYCLE = 'services.BICYCLE',
  'PLAY-AREA' = 'services.PLAY-AREA',
  'DUETTO-PLUS' = 'services.DUETTO-PLUS',
  'POWER-SOCKET' = 'services.POWER-SOCKET',
  'WHEELCHAIR-PLACES' = 'services.WHEELCHAIR-PLACES',
  EKSTRA = 'services.EKSTRA',
  'SLEEPING-CABIN' = 'services.SLEEPING-CABIN',
  SHOWER = 'services.SHOWER',
  'FAMILY-COMPARTMENT' = 'services.FAMILY-COMPARTMENT',
  'WORKING-COMPARTMENT' = 'services.WORKING-COMPARTMENT',
  'CONFERENCE-COMPARTMENT' = 'services.CONFERENCE-COMPARTMENT',
  RESTAURANT = 'services-RESTAURANT',
  'MINIBISTRO-TROLLEY' = 'services.MINIBISTRO-TROLLEY',
}

export enum NextAndPreviousDayButtonsMessage {
  NEXT_DAY = 'NextAndPreviousDayButtons.nextDay',
  PREVIOUS_DAY = 'NextAndPreviousDayButtons.previousDay',
}

export enum TrainTypeMessage {
  IC = 'train.type.ic',
  BUS = 'train.type.bus',
  KLA = 'train.type.kla',
  KVL = 'train.type.kvl',
  H = 'train.type.h',
  LOL = 'train.type.lol',
  LOC = 'train.type.loc',
  LKB = 'train.type.lkb',
  P = 'train.type.p',
  TAX = 'train.type.tax',
  PYO = 'train.type.pyo',
  S = 'train.type.s',
  AE = 'train.type.ae',
  TP = 'train.type.tp',
  IC2 = 'train.type.ic2',
  HDM = 'train.type.hdm',
  HLA = 'train.type.hla',
  JLA = 'train.type.jla',
  BHS = 'train.type.bhs',
  BLV = 'train.type.blv',
}

/** @knipignore this enum is used dynamically */
export enum PassengerTypeMessage {
  PERSONAL_ASSISTANT = 'passenger.type.personal_assistant',
  PERSONAL_ASSISTANT_PLURAL = 'passenger.type.personal_assistant.plural',
  ASSISTANT = 'passenger.type.assistant',
  ASSISTANT_PLURAL = 'passenger.type.assistant.plural',
  NONMILITARY = 'passenger.type.nonmilitary',
  NONMILITARY_PLURAL = 'passenger.type.nonmilitary.plural',
  STUDENT = 'passenger.type.student',
  STUDENT_PLURAL = 'passenger.type.student.plural',
  PENSIONER = 'passenger.type.pensioner',
  PENSIONER_PLURAL = 'passenger.type.pensioner.plural',
  CHILD = 'passenger.type.child',
  CHILD_PLURAL = 'passenger.type.child.plural',
  ADULT = 'passenger.type.adult',
  ADULT_PLURAL = 'passenger.type.adult.plural',
  CONSCRIPT = 'passenger.type.conscript',
  CONSCRIPT_PLURAL = 'passenger.type.conscript.plural',
  VR_EMPLOYEE = 'passenger.type.vr_employee',
  VR_EMPLOYEE_PLURAL = 'passenger.type.vr_employee.plural',
  VR_EMPLOYEE_FAMILY_MEMBER = 'passenger.type.vr_employee_family_member',
  VR_EMPLOYEE_FAMILY_MEMBER_PLURAL = 'passenger.type.vr_employee_family_member.plural',
  VR_PENSIONER = 'passenger.type.vr_pensioner',
  VR_PENSIONER_PLURAL = 'passenger.type.vr_pensioner.plural',
  FIP_50_CARD = 'passenger.type.fip_50_card',
  FIP_50_CARD_PLURAL = 'passenger.type.fip_50_card.plural',
  FIP_FREE_COUPON = 'passenger.type.fip_free_coupon',
  FIP_FREE_COUPON_PLURAL = 'passenger.type.fip_free_coupon.plural',
  SPECIAL_FREE_PASS = 'passenger.type.special_free_pass',
  SPECIAL_FREE_PASS_PLURAL = 'passenger.type.special_free_pass.plural',
  REPLACMENT = 'passenger.type.replacement',
  REPLACMENT_PLURAL = 'passenger.type.replacement.plural',
  INTERRAIL_EURAIL_1ST = 'passenger.type.interrail_eurail_1st',
  INTERRAIL_EURAIL_1ST_PlURAL = 'passenger.type.interrail_eurail_1st.plural',
  INTERRAIL_EURAIL_2ND = 'passenger.type.interrail_eurail_2nd',
  INTERRAIL_EURAIL_2ND_PLURAL = 'passenger.type.interrail_eurail_2nd.plural',
  FDF_CONTRACT = 'passenger.type.fdf_contract',
  FDF_CONTRACT_PLURAL = 'passenger.type.fdf_contract.plural',
}

export enum VehicleTypeMessage {
  CAR = 'booking.car',
  MOTORCYCLE = 'booking.motorcycle',
}

/** @knipignore this enum is used dynamically */
export enum StationServicesMessage {
  lockers = 'station.service.lockers',
  lockers_not_available = 'station.service.lockers.notAvailable',
  toilets = 'station.service.toilets',
  toilets_not_available = 'station.service.toilets.notAvailable',
  tvmStation = 'station.service.tvmStation',
  tvmStation_not_available = 'station.service.tvmStation.notAvailable',
  ticketSalesStation = 'station.service.ticketSalesStation',
  ticketSalesStation_not_available = 'station.service.ticketSalesStation.notAvailable',
  assistanceService = 'station.service.assistanceService',
  assistanceService_not_available = 'station.service.assistanceService.notAvailable',
  shippingStation = 'station.service.shippingStation',
  shippingStation_not_available = 'station.service.shippingStation.notAvailable',
  accessibility = 'station.service.accessibility',
  accessibility_not_available = 'station.service.accessibility.notAvailable',
}

export enum FooterMessage {
  APP_STORE_LINKS = 'footer.appStoreLinks',
}

export enum DataProtectionFormMessage {
  TITLE = 'forms.dataProtection.subject',
  DATA_ACCESS = 'forms.dataProtection.subject.dataAccess',
  DATA_ACCESS_DESCRIPTION = 'forms.dataProtection.subject.dataAccess.description',
  DATA_RECTIFICATION = 'forms.dataProtection.subject.dataRectification',
  DATA_RECTIFICATION_DESCRIPTION = 'forms.dataProtection.subject.dataRectification.description',
  DATA_PORTABILITY = 'forms.dataProtection.subject.dataPortability',
  DATA_PORTABILITY_DESCRIPTION = 'forms.dataProtection.subject.dataPortability.description',
  DATA_ERASURE = 'forms.dataProtection.subject.dataErasure',
  DATA_ERASURE_DESCRIPTION = 'forms.dataProtection.subject.dataErasure.description',
  EDIT_MARKETING_PREFERENCES = 'forms.dataProtection.subject.editMyMarketingPreferences',
  EDIT_MARKETING_PREFERENCES_DESCRIPTION = 'forms.dataProtection.subject.editMyMarketingPreferences.description',
  RESTRICT_MY_DATA_USAGE = 'forms.dataProtection.subject.restrictProcessing',
  RESTRICT_MY_DATA_USAGE_DESCRIPTION = 'forms.dataProtection.subject.restrictProcessing.description',
  DETAILED_DESCRIPTION_TITLE = 'forms.dataProtection.detailedDescription.title',
  DETAILED_DESCRIPTION = 'forms.dataProtection.detailedDescription.description',
  SELECT_SUBJECT_TITLE = 'forms.dataProtection.selectSubject.title',
  DESCRIBE_YOUR_REQUEST = 'forms.dataProtection.describeYourRequest.label',
  SUBMIT_FAILURE_TITLE = 'forms.dataProtection.submitted.failure.title',
  SUBMIT_FAILURE_BODY = 'forms.dataProtection.submitted.failure.body',
  BACK_TO_FORM = 'forms.dataProtection.backToForm',
}

export enum GeneralFeedbackFormMessage {
  TOPIC = 'forms.generalFeedback.topic',
  TOPIC_LABEL = 'forms.generalFeedback.topic.label',
  TOPIC_PLACEHOLDER = 'forms.generalFeedback.topic.placeholder',
  SUBJECT_LABEL = 'forms.generalFeedback.subject.label',
  SUBJECT_PLACEHOLDER = 'forms.generalFeedback.subject.placeholder',
  DESCRIPTION = 'forms.generalFeedback.description',
  SELECT_STATIONS = 'forms.generalFeedback.selectStations',
  SUBMIT_FAILURE_TITLE = 'forms.generalFeedback.submitted.failure.title',
  SUBMIT_FAILURE_BODY = 'forms.generalFeedback.submitted.failure.body',
  BACK_TO_FORM = 'forms.generalFeedback.backToForm',
}

export enum GeneralFeedbackFormSubjectMessage {
  LONG_DISTANCE = 'forms.generalFeedback.subject.LongDistance',
  COMMUTER = 'forms.generalFeedback.subject.Commuter',
  GROUP_JOURNEY = 'forms.generalFeedback.subject.GroupJourney',
  CUSTOMERSHIP = 'forms.generalFeedback.subject.customership',
  OTHER = 'forms.generalFeedback.subject.Other',
}

export enum GeneralFeedbackFormTopicMessage {
  SERVICE_ON_TRAIN = 'forms.generalFeedback.topic.ServiceOnTrain',
  SERVICE_AT_STATION = 'forms.generalFeedback.topic.ServiceAtStation',
  TICKET_PURCHASE = 'forms.generalFeedback.topic.TicketPurchase',
  ADDITIONAL_SERVICES = 'forms.generalFeedback.topic.AdditionalServices',
  ACCESSIBILITY_PHYSICAL = 'forms.generalFeedback.topic.AccessibilityPhysical',
  ACCESSIBILITY_DIGITAL = 'forms.generalFeedback.topic.AccessibilityDigital',
  SCHEDULES_AND_ROUTES = 'forms.generalFeedback.topic.SchedulesAndRoutes',
  OTHER = 'forms.generalFeedback.topic.Other',
}

export enum TrainDelayCompensationFormMessage {
  WHEN_TO_APPLY_COMPENSATION = 'forms.delayedTrain.whenToApplyCompensation',
  SEARCH_YOUR_TRIP = 'forms.delayedTrain.searchYourTrip',
  ORDER_NUMBER_FIELD_TITLE = 'forms.delayedTrain.orderNumberFieldTitle',
  PAYMENT_REFERENCE_FIELD_TITLE = 'forms.delayedTrain.paymentReferenceFieldTitle',
  PAYMENT_REFERENCE_LABEL = 'forms.paymentReference.label',
  MORE_THAN_ONE_HOUR = 'forms.delayedTrain.moreThanOneHour',
  MORE_THAN_TWO_HOURS = 'forms.delayedTrain.moreThanTwoHours',
  HOW_LATE_WAS_TRAIN_SECTION_TITLE = 'forms.delayedTrain.howLateWasTrainSectionTitle',
  HOW_LATE_WAS_TRAIN = 'forms.delayedTrain.howLateWasTrain',
  I_APPLY_FOR_REIMBURSEMENT_CARD_TITLE = 'forms.delayedTrain.iApplyForReimbursementCardTitle',
  I_APPLY_FOR_REIMBURSEMENT_CARD_DESCRIPTION = 'forms.delayedTrain.iApplyForReimbursementCardDescription',
  I_APPLY_FOR_REIMBURSEMENT = 'forms.delayedTrain.iApplyForReimbursement',
  CONTACT_INFO_INTRO_TITLE = 'forms.delayedTrain.teemaIntroTitle',
  CONTACT_INFO_INTRO_DESCRIPTION = 'forms.delayedTrain.teemaIntroDescription',
  STEP_3_4_TITLE = 'forms.delayedTrain.stepTeema2Title',
  SUBMIT_FAILURE_TITLE = 'forms.delayedTrain.submitFailureTitle',
  SUBMIT_FAILURE_BODY = 'forms.delayedTrain.submitFailureBody',
  CHOOSE_JOURNEY = 'forms.ticketCancellation.chooseJourney',
  LATEST_JOURNEYS = 'forms.delayedTrain.latestJourneys',
  STEP_2_TITLE = 'forms.delayedTrain.delayCompensation',
  STEPS_ARIA_LABEL = 'forms.delayedTrain.navigationAriaLabel',
  LOGIN_TO_TRAIN_DELAY_NOTICE_TITLE = 'forms.delayedTrain.loginToTrainDelayCancellationTitle',
  LOGIN_TO_TRAIN_DELAY_NOTICE_DESCRIPTION = 'forms.delayedTrain.loginToTrainDelayCancellationDescription',
  PAYMENT_REFERENCE_INFO_TITLE = 'forms.delayedTrain.paymentReference.info.title',
  PAYMENT_REFERENCE_INFO_BODY = 'forms.delayedTrain.paymentReference.info.body',
  PURCHASED_WITH_MULTITICKET = 'forms.delayedTrain.multiticket',

  // Errors
  JOURNEY_HAS_NOT_STARTED_HEADING = 'forms.delayedTrain.journeyHasNotStarted.heading',
  JOURNEY_HAS_NOT_STARTED_TITLE = 'forms.delayedTrain.journeyHasNotStarted.title',
  JOURNEY_HAS_NOT_STARTED_DESCRIPTION = 'forms.delayedTrain.journeyHasNotStarted.description',
  CLAIM_TIME_HAS_EXPIRED_HEADING = 'forms.delayedTrain.claimTimeHasExpired.heading',
  CLAIM_TIME_HAS_EXPIRED_TITLE = 'forms.delayedTrain.claimTimeHasExpired.title',
  ORDER_CANCELLED_NOTICE_HEADING = 'forms.delayedTrain.orderCancelled.heading',
  ORDER_CANCELLED_NOTICE_TITLE = 'forms.delayedTrain.orderCancelled.title',
  ORDER_CANCELLED_NOTICE_DESCRIPTION = 'forms.delayedTrain.orderCancelled.description',
  ORDER_DIFFERENT_OWNER_NOTICE_HEADING = 'forms.delayedTrain.differentOwner.heading',
  ORDER_DIFFERENT_OWNER_NOTICE_TITLE = 'forms.delayedTrain.differentOwner.title',
  ORDER_DIFFERENT_OWNER_NOTICE_DESCRIPTION = 'forms.delayedTrain.differentOwner.description',
  UNKNOWN_ERROR_TITLE = 'forms.delayedTrain.unknownError.title',
  UNKNOWN_ERROR_HEADING = 'forms.delayedTrain.unknownError.heading',
  UNKNOWN_ERROR_DESCRIPTION = 'forms.delayedTrain.unknonwError.description',

  BACK_TO_FORM = 'forms.delayedTrain.backToForm',
}

export enum OtherCompensationFormMessage {
  STEP1_CARD_TITLE = 'forms.otherCompensation.step1CardTitle',
  STEP1_CARD_DESCRIPTION = 'forms.otherCompensation.step1CardDescription',
  APPLY_FOR_COMPENSATION = 'forms.otherCompensation.applyForCompensation',
  STEP2_PAGE_TITLE = 'forms.otherCompensation.step2PageTitle',
  DATE_SECTION_TITLE = 'forms.otherCompensation.dateSectionTitle',
  STATION_SECTION_TITLE = 'forms.otherCompensation.stationSectionTitle',
  STATION_SECTION_DESCRIPTION = 'forms.otherCompensation.stationSectionDescription',
  EXPENSE_SECTION_TITLE = 'forms.otherCompensation.expenseSectionTitle',
  EXPENSE_SECTION_DESCRIPTION = 'forms.otherCompensation.expenseSectionDescription',
  EXPENSE = 'forms.otherCompensation.expense',
  SELECT_DEPARTURE_STATION = 'forms.otherCompensation.selectDepartureStation',
  EXPENSE_TYPE_LABEL = 'forms.otherCompensation.expenseTypeLabel',
  EXPENSE_AMOUNT_LABEL = 'forms.otherCompensation.expenseAmountLabel',
  EXPENSE_DESCRIPTION_LABEL = 'forms.otherCompensation.expenseDescriptionLabel',
  EXPENSE_DESCRIPTION_HELPER_TEXT = 'forms.otherCompensation.expenseDescriptionHelperText',
  ADD_NEW_EXPENSE = 'forms.otherCompensation.addNewExpense',
  DESCRIPTION_SECTION_TITLE = 'forms.otherCompensation.descriptionSectionTitle',
  DESCRIPTION_SECTION_DESCRIPTION = 'forms.otherCompensation.descriptionSectionDescription',
  DESCRIPTION_LABEL = 'forms.otherCompensation.descriptionLabel',
  SUMMARY_SECTION_TITLE = 'forms.otherCompensation.summarySectionTitle',
  CHOOSE_EXPENSE_TYPE = 'forms.otherCompensation.chooseExpenseType',
  ACCIDENT = 'forms.expenseType.accident',
  LUGGAGE = 'forms.expenseType.luggage',
  TAXI = 'forms.expenseType.taxi',
  TRAIN_TICKET = 'forms.expenseType.trainTicket',
  BUS = 'forms.expenseType.bus',
  HOTEL = 'forms.expenseType.hotel',
  MEALS = 'forms.expenseType.meals',
  VEHICLE_DAMAGE = 'forms.expenseType.vehicleDamage',
  OWN_CAR = 'forms.expenseType.ownCar',
  OTHER = 'forms.expenseType.other',
  FLIGHT_OR_FERRY_TICKET = 'forms.expenseType.flightOrFerryTicket',
  NO_EXPENSES_ADDED = 'forms.otherCompensation.noExpensesAdded',
  STEP3_PAGE_TITLE = 'forms.otherCompensation.step3PageTitle',
  REIMBURSEMENT_PAYMENT_INFO = 'forms.otherCompensation.paymentInfo',
  SUBMIT_FAILURE_TITLE = 'forms.otherCompensation.submitFailureTitle',
  SUBMIT_FAILURE_BODY = 'forms.otherCompensation.submitFailureBody',
  BACK_TO_FORM = 'forms.otherCompensation.backToForm',
  STEPS_ARIA_LABEL = 'forms.otherCompensation.navigationAriaLabel',
}

export enum TicketCancellationFormMessage {
  CHOOSE_DIRECTION = 'forms.ticketCancellation.chooseDirection',
  CHOOSE_JOURNEY = 'forms.ticketCancellation.chooseJourney',
  CHOOSE_JOURNEY_PAGE_SUBHEADING = 'forms.ticketCancellation.chooseJourneyPageSubheading',
  COMPENSATION_FORM_NOTICE = 'forms.ticketCancellation.compensationFormNotice',
  SEARCH_ORDER_INSTRUCTIONS = 'forms.ticketCancellation.searchOrderInstructions',
  ERROR_ORDER_NUMBER_AND_REFERENCE_NUMBER_WONT_MATCH = 'forms.ticketCancellation.orderNumberAndReferenceNumberWontMatch',
  CONTACT_DETAILS = 'forms.ticketCancellation.stepTeema2Title',
  CONTACT_DETAILS_DESCRIPTION_SECTION_TITLE = 'forms.ticketCancellation.teemaDescriptionSectionTitle',
  CONTACT_DETAILS_DESCRIPTION_SECTION_DESCRIPTION = 'forms.ticketCancellation.teemaDescriptionSectionDescription',
  SUBMIT_FAILURE_TITLE = 'forms.ticketCancellation.submitFailureTitle',
  SUBMIT_FAILURE_BODY = 'forms.ticketCancellation.submitFailureBody',
  CANNOT_BE_CANCELLED = 'forms.ticketCancellation.cannotBeCancelled',
  NO_CANCELLATION_INSURANCE_NOTICE_TITLE = 'forms.ticketCancellation.noCancellationInsuranceTitle',
  NO_CANCELLATION_INSURANCE_NOTICE_DESCRIPTION = 'forms.ticketCancellation.noCancellationInsuranceDescription',
  IF_VR_CANCELLED_TRAIN_NOTICE_TITLE = 'forms.ticketCancellation.ifVrCancelledYourTrainNoticeTitle',
  IF_VR_CANCELLED_TRAIN_NOTICE_DESCRIPTION = 'forms.ticketCancellation.ifVrCancelledYourTrainDescription',
  ORDER_REFUNDED_COMPLETELY_NOTICE_TITLE = 'forms.ticketCancellation.orderCompletelyRefundedNoticeTitle',
  ORDER_REFUNDED_COMPLETELY_NOTICE_DESCRIPTION = 'forms.ticketCancellation.orderCompletelyRefundedNoticeDescription',
  JOURNEY_HAS_ALREADY_STARTED_NOTICE_TITLE = 'forms.ticketCancellation.journeyHasAlreadyStartedTitle',
  JOURNEY_HAS_ALREADY_STARTED_NOTICE_DESCRIPTION = 'forms.ticketCancellation.journeyHasAlreadyStartedDescription',
  UNKNOWN_ERROR_NOTICE_TITLE = 'forms.ticketCancellation.unknownErrorNoticeTitle',
  UNKNOWN_ERROR_NOTICE_DESCRIPTION = 'forms.ticketCancellation.unknownErrorNoticeDescription',
  LOGIN_TO_CANCEL_JOURNEY_NOTICE_TITLE = 'forms.ticketCancellation.loginToCancelJourneyTitle',
  LOGIN_TO_CANCEL_JOURNEY_NOTICE_DESCRIPTION = 'forms.ticketCancellation.loginToCancelJourneyDescription',
  LOGIN_PROMPT_ADDITIONAL_INFO = 'forms.ticketCancellation.loginPromptAdditionalInfo',
  THIRD_PARTY_NOTICE_TITLE = 'forms.ticketCancellation.thirdPartyNoticeTitle',
  THIRD_PARTY_NOTICE_DESCRIPTION = 'forms.ticketCancellation.thirdPartyNoticeDescription',
  ASK_CONTACT_DETAILS_STEP_INTRO_TITLE = 'forms.ticketCancellation.askContactDetailsIntroTitle',
  ASK_CONTACT_DETAILS_STEP_INTRO_DESCRIPTION = 'forms.ticketCancellation.askContactDetailsIntroDescription',
  STEP_WHAT_TO_CANCEL_TITLE = 'forms.ticketCancellation.stepWhatToCancelTitle',
  PURCHASE_CHANNEL_TITLE = 'forms.ticketCancellation.purchaseChannelTitle',
  UPCOMING_JOURNEYS_WITH_CANCELLATION_INSURANCE = 'forms.ticketCancellation.upcomingJourneysWithCancellationInsurance',
  SUBMIT_SUCCESS_ADDITIONAL_INFO = 'forms.ticketCancellation.submitted.additionalInfo',
  SUBMIT_SUCCESS_NO_REFUND = 'forms.ticketCancellation.submitted.noRefund',
  BACK_TO_FORM = 'forms.ticketCancellation.backToForm',
  BACK_TO_JOURNEYS = 'forms.ticketCancellation.backToJourneys',
}

/** @knipignore this enum is used dynamically */
export enum GeneralFeedbackFormTravelReasonMessage {
  LEISURE = 'forms.travelReason.Leisure',
  BUSINESS = 'forms.travelReason.Business',
  OTHER = 'forms.travelReason.Other',
}

export enum B2bCustomerInviteFormMessage {
  SUMMARY_TITLE = 'forms.b2bInvite.summaryTitle',
  CONTACT_INFO_SUMMARY_SECTION_TITLE = 'forms.b2bInvite.summary.contactInfoTitle',
  COMPANY_BUSINESS_ID_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.companyBusinessId',
  COMPANY_ADDRESS_INFO_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.companyAddressInfo',
  APPLY_FOR_BILLING_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.applyForBilling',
  BILLING_METHOD_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.billingMethod',
  BILLING_INFO_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.billingInfo',
  CONTACT_INFO_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.contactInfo',
  ADMINISTRATOR_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.administrator',
  ACCEPT_TERMS_SECTION_TITLE = 'forms.b2bInvite.sectionTitle.acceptTerms',
  COMPANY_BUSINESS_ID_DESCRIPTION = 'forms.b2bInvite.businessId.description',
  BUSINESS_ID_LABEL = 'forms.b2bInvite.businessId.label',
  BUSINESS_ID_NOT_FOUND = 'forms.b2bInvite.businessId.error.notFound',
  BUSINESS_ID_SUMMARY_TITLE = 'forms.b2bInvite.businessId.summaryLabel',
  PRH_LICENSE = 'forms.b2bInvite.businessId.prhLicense',
  COMPANY_NAME = 'forms.b2bInvite.companyName.label',
  COMPANY_NAME_ROW2 = 'forms.b2bInvite.companyNameRow2.label',
  DEPARTMENT = 'forms.b2bInvite.department.label',
  DEPARTMENT_ROW2 = 'forms.b2bInvite.departmentRow2.label',
  ADDRESS_STREET_OR_PO = 'forms.b2bInvite.addressStreatOrPoBox',
  ADDRESS_STREET = 'forms.b2bInvite.addressStreet.label',
  ADDRESS_PO_BOX = 'forms.b2bInvite.addressPoBox.label',
  ADDRESS_POSTAL_CODE = 'forms.b2bInvite.addressPostalCode.label',
  ADDRESS_CITY = 'forms.b2bInvite.addressCity.label',
  HAS_PARENT_COMPANY = 'forms.b2bInvite.hasParentCompmany.label',
  PARENT_COMPANY_NUMBER = 'forms.b2bInvite.parentCompanyNumber.label',
  PARENT_COMPANY_NUMBER_DESCRIPTION = 'forms.b2bInvite.parentCompanyNumber.desciption',
  APPLY_FOR_BILLING_INFO = 'forms.b2bInvite.invoicingRequested.description',
  APPLY_FOR_BILLING_LABEL = 'forms.b2bInvite.invoicingRequested.label',
  APPLY_FOR_BILLING_YES = 'forms.b2bInvite.invoicingRequested.yes',
  APPLY_FOR_BILLING_NO = 'forms.b2bInvite.invoicingRequested.no',
  INVOICING_METHOD_LABEL = 'froms.b2bInvite.invoicingMethod.label',
  INVOICING_METHOD_EMAIL = 'froms.b2bInvite.invoicingMethod.email',
  INVOICING_METHOD_E_INVOICING = 'froms.b2bInvite.invoicingMethod.eInvoicing',
  INVOICING_EMAIL_ADDRESS_LABEL = 'froms.b2bInvite.invoicingEmailAddress.label',
  E_INVOICING_ADDRESS_LABEL = 'froms.b2bInvite.eInvoiceAddress.label',
  E_INVOICING_ADDRESS_INVALID = 'froms.b2bInvite.eInvoiceAddress.invalid',
  INVOICED_COMPANY_NAME_LABEL = 'forms.b2bInvite.invoicedCompanyName.label',
  INVOICED_COMPANY_NAME_ROW_2_LABEL = 'forms.b2bInvite.invoicedCompanyNameRow2.label',
  INVOICE_REFERENCE_LABEL = 'forms.b2bInvite.invoiceReference.label',
  INVOICING_LANGUAGE = 'forms.b2bInvite.invoicingLanguage.label',
  INVOICING_LANGUAGE_FI = 'forms.b2bInvite.invoicingLanguage.fi',
  INVOICING_LANGUAGE_EN = 'forms.b2bInvite.invoicingLanguage.en',
  SEPARATE_BILLING_ADDRESS_LABEL = 'forms.b2bInvite.separateBillingAddress.label',
  SEPARATE_BILLING_ADDRESS_YES = 'forms.b2bInvite.separateBillingAddress.yes',
  SEPARATE_BILLING_ADDRESS_NO = 'forms.b2bInvite.separateBillingAddress.no',
  JOB_TITLE_LABEL = 'forms.b2bInvite.jobTitle.label',
  ADMINISTRATOR_INFO = 'forms.b2bInvite.administrator.description',
  ADMINISTRATOR_LABEL = 'forms.b2bInvite.administrator.label',
  ACCEPT_TERMS_DESCRIPTION = 'forms.b2bInvite.acceptTerms.description',
  ACCEPT_TERMS_LABEL = 'forms.b2bInvite.acceptTerms.label',
  FAILED_TO_SEND_TITLE = 'forms.b2bInvite.failedToSend.title',
  FAILED_TO_SEND_BODY = 'forms.b2bInvite.failedToSend.body',
  SUBMIT_SUCCESS_BODY = 'forms.b2bInvite.success.body',
  SUBMIT_SUCCESS_BODY_INVOICING = 'forms.b2bInvite.success.invoicing',
  SUBMIT_DRAFT_TITLE = 'forms.b2bInvite.draft.title',
  SUBMIT_DRAFT_BODY = 'forms.b2bInvite.draft.body',
}

export enum CustomerServiceFormMessage {
  TEXT_AREA_MAX_LENGTH_REACHED = 'forms.textarea.characterLimitReached',
  FEEDBACK_LABEL = 'forms.generalFeedback.feedback.label',
  FEEDBACK_TITLE = 'forms.feedback.title',
  EVENT_DATE_TITLE = 'forms.eventDate.title',
  JOURNEY_DETAILS_TITLE = 'forms.journeyDetails.title',
  ORDER_NUMBER_LABEL = 'forms.orderNumber.label',
  ORDER_NUMBER_UNKNOWN = 'forms.orderNumber.unknown',

  TRAVEL_REASON_PLACEHOLDER = 'forms.travelReason.placeholder',
  TRAVEL_REASON_LABEL = 'forms.travelReason.label',

  TRAIN_AND_TICKET_DETAILS_TITLE = 'forms.trainAndTicketDetails.title',
  TRAIN_ID_LABEL = 'forms.trainId.label',
  TRAIN_ID_PLACEHOLDER = 'forms.trainId.placeholder',
  TRAIN_ID_HELPERTEXT = 'forms.trainId.helperText',

  ATTACHMENTS_TITLE = 'forms.attachments.title',
  ATTACHMENTS_INSTRUCTIONS = 'forms.attachments.instructions2',
  ATTACHMENTS_LABEL = 'forms.attachments.label',
  ATTACHMENTS_BUTTON = 'forms.attachments.button',
  ATTACHMENTS_ERROR_TOO_MANY_FILES = 'forms.attachments.error.tooManyfiles',
  ATTACHMENTS_ERROR_TOO_LARGE_FILE = 'forms.attachments.error.tooLargeFile',
  ATTACHMENTS_ERROR_INVALID_FILE_TYPE = 'forms.attachments.error.invalidFileType',
  ATTACHMENTS_ERROR_UPLOADING_FILE_FAILED = 'forms.attachments.error.uploadingFileFailed',
  ATTACHMENTS_REQUIRED_WITH_EXPENSE = 'forms.attachments.requiredWithExpense',

  ATTACHMENTS_ARIA_DONE = 'forms.attachments.aria.done',
}

export enum FailedPurchaseFormMessage {
  LOGIN_PROMPT_TITLE = 'forms.failedPurchase.loginPrompt.title',
  LOGIN_PROMPT_TEXT = 'forms.failedPurchase.loginPrompt.text',
  INFO_TITLE = 'forms.failedPurchase.info.title',
  INFO_TEXT = 'forms.failedPurchase.info.text',
  INFO_BUTTON = 'forms.failedPurchase.info.button',
  PURCHASE_CHANNEL_TITLE = 'forms.failedPurchase.purchaseChannel.title',
  PURCHASE_CHANNEL_LABEL = 'forms.failedPurchase.purchaseChannel.label',
  PURCHASE_CHANNEL_PLACEHOLDER = 'forms.failedPurchase.purchaseChannel.placeholder',
  DATE_TITLE = 'forms.failedPurchase.date.title',
  DESCRIPTION_TITLE = 'forms.failedPurchase.description.title',
  DESCRIPTION_TEXT = 'forms.failedPurchase.description.text',
  DESCRIPTION_LABEL = 'forms.failedPurchase.description.label',
  PURCHASE_INFO_TITLE = 'forms.failedPurchase.purchaseInfo.title',
  PURCHASE_INFO_METHOD_LABEL = 'forms.failedPurchase.purchaseInfo.methodLabel',
  PURCHASE_INFO_METHOD_PLACEHOLDER = 'forms.failedPurchase.purchaseInfo.methodPlaceholder',
  PURCHASE_INFO_AMOUNT_LABEL = 'forms.failedPurchase.purchaseInfo.amountLabel',
  PURCHASE_INFO_BANK_ACCOUNT = 'forms.failedPurchase.purchaseInfo.bankAccount',
  PURCHASE_INFO_BIC = 'forms.failedPurchase.purchaseInfo.bic',
  PURCHASE_INFO_CREDIT_CARD_LAST_DIGITS = 'forms.failedPurchase.purchaseInfo.creditCardLastDigits',
  PAYMENT_REFERENCE_TITLE = 'forms.failedPurchase.paymentReference.title',
  PAYMENT_REFERENCE_LABEL = 'forms.failedPurchase.paymentReference.label',
  PAYMENT_REFERENCE_HELPER_TEXT = 'forms.failedPurchase.paymentReference.helperText',
  SUBMIT_FAILURE_TITLE = 'forms.failedPurchase.submitted.failure.title',
  SUBMIT_FAILURE_BODY = 'forms.failedPurchase.submitted.failure.body',
  BACK_TO_FORM = 'forms.failedPurchase.backToForm',
}

/** @knipignore this enum is used dynamically */
export enum PurchaseChannelMessage {
  VR_FI = 'purchaseChannel.VR_FI',
  MOBILE_APP_MATKALLA = 'purchaseChannel.MOBILE_APP_MATKALLA',
  TICKET_VENDING_MACHINE = 'purchaseChannel.TICKET_VENDING_MACHINE',
  CALL_CENTER = 'purchaseChannel.CALL_CENTER',
  OTHER = 'purchaseChannel.OTHER',
  STATION = 'purchaseChannel.STATION',
}

export enum UnitMessage {
  MINUTE = 'unit.minute',
  DAY = 'unit.day',
  MONTH = 'unit.month',
}

export enum PriceCalendarMessage {
  TOTAL_PRICE_OUTBOUND = 'priceCalendar.totalPrice.outbound',
  TOTAL_PRICE_RETURN = 'priceCalendar.totalPrice.return',
  PREVIOUS_WEEK = 'priceCalendar.previousWeek',
  NEXT_WEEK = 'priceCalendar.nextWeek',
  WEEK = 'priceCalendar.week',
  CHEAPEST_PRICE = 'priceCalendar.cheapestPrice',
  SOLD_OUT = 'priceCalendar.soldOut',
  NO_OPTIONS_FOUND = 'priceCalendar.noOptionsFound',
  SHOW = 'priceCalendar.show',
  FAILED_TO_LOAD_HEADING = 'priceCalendar.failedToLoad.heading',
  FAILED_TO_LOAD_BODY = 'priceCalendar.failedToLoad.body',
  NO_PRICE_AVAILABLE = 'priceCalendar.noPriceAvailable',
  SELECTED_DAY = 'priceCalendar.selectedDay',
}

export enum SeriesTicketProductNameMessage {
  COMMUTER = 'multi_ticket.productCategory.commuter',
  MULTITICKET_ECO = 'multi_ticket.productCategory.eco',
  MULTITICKET_EXTRA = 'multi_ticket.productCategory.extra',
}

export enum VehicleJourneyMessage {
  LOADING_TIME = 'vehicle.journey.loadingTime',
  VEHICLE_LOADING = 'vehicle.journey.loading',
  LOADING_TIME_BEGINS = 'vehicle.journey.loadingTimeBegins',
  LOADING_TIME_ENDS = 'vehicle.journey.loadingTimeEnds',
  WHERE_IS_LOADING_AREA = 'vehicle.journey.whereIsLoadingArea',
  WHAT_TO_REMEMBER = 'vehicle.journey.whatToRemember',
  READ_MORE_ABOUT_LOADING = 'vehicle.journey.readMoreAboutLoading',
  VEHICLE_DETAILS_HEADING = 'vehicle.details.heading',
  VEHICLE_DETAILS_HEADING_PLURAL = 'vehicle.details.heading.plural',
  VEHICLE_DETAILS_SUBHEADING_CAR = 'vehicle.details.subheading.car',
  VEHICLE_DETAILS_SUBHEADING_MOTORCYCLE = 'vehicle.details.subheading.motorcycle',
  VEHICLE = 'vehicle.details.vehicle',
  FORMS_LOADING_HEADING_CAR = 'vehicle.forms.loading.heading.car',
  FORMS_LOADING_HEADING_MOTORCYCLE = 'vehicle.forms.loading.heading.motorcycle',
  FORMS_LOADING_WHO_LOADS_LABEL = 'vehicle.forms.loading.whoLoads.label',
  FORMS_LOADING_WHO_LOADS_CUSTOMER = 'vehicle.forms.loading.whoLoads.customer',
  FORMS_LOADING_WHO_LOADS_VR = 'vehicle.forms.loading.whoLoads.vr',
  FORMS_CONTACT_HEADING_CAR = 'vehicle.forms.contact.heading.car',
  FORMS_CONTACT_HEADING_MOTORCYCLE = 'vehicle.forms.contact.heading.motorcycle',
  FORMS_CONTACT_FIRST_NAME = 'vehicle.forms.contact.firstName',
  FORMS_CONTACT_LAST_NAME = 'vehicle.forms.contact.lastName',
  FORMS_CONTACT_PHONE = 'vehicle.forms.contact.phone',
  FORMS_VEHICLE_HEADING_CAR = 'vehicle.forms.vehicle.heading.car',
  FORMS_VEHICLE_HEADING_MOTORCYCLE = 'vehicle.forms.vehicle.heading.motorcycle',
  FORMS_VEHICLE_MAKE = 'vehicle.forms.vehicle.make',
  FORMS_VEHICLE_MODEL = 'vehicle.forms.contact.model',
  FORMS_VEHICLE_LICENSE = 'vehicle.forms.contact.license',
  FORMS_SUBMIT = 'vehicle.forms.submit',
  FORMS_SUBMIT_PLURAL = 'vehicle.forms.submit.plural',
  ERROR_FAILED_TO_UPDATE_TITLE = 'vehicle.error.failedToUpdate.title',
  ERROR_FAILED_TO_UPDATE_BODY = 'vehicle.error.failedToUpdate.body',
  CUSTOMER_LOADS_MOTORCYCLE = 'vehicle.journey.customerLoadsMotorcycles',
  VEHICLE_DIMENSIONS = 'vehicle.journey.vehicleDimensions',
  ERROR_FAILED_TO_ADD_TITLE = 'vehicle.error.failedToAdd.title',
  ERROR_FAILED_TO_ADD_BODY = 'vehicle.error.failedToAdd.body',
}

export enum ExampleCarsMessage {
  CHOOSE_DIMENSIONS = 'exampleCars.chooseDimensions',
  HEIGHT = 'exampleCars.height',
  LENGTH = 'exampleCars.length',
  CLOSE_BUTTON = 'exampleCars.closeButton',
}

export enum CompanyInformationFormSectionMessage {
  TITLE = 'forms.company.information',
  COMPANY_NAME = 'forms.company.companyName.input.label',
  ACCOUNT_NUMBER = 'forms.company.accountNumber.input.label',
  ACCOUNT_NUMBER_HELPER = 'forms.company.accountNumber.input.helper',
}

export enum GroupSalesFormMessage {
  INTRO_TITLE = 'forms.groupSales.intro.title',
  INTRO_DESCRIPTION = 'forms.groupSales.intro.description',
  INTRO_CONFIRM_BUTTON = 'forms.groupSales.intro.confirmButton',
  INTRO_CONFIRM_BUTTON_SMALL_GROUP = 'forms.groupSales.intro.confirmButton.smallGroup',
  LOGIN_PROMPT_TITLE = 'forms.groupSales.intro.loginPrompt.title',
  LOGIN_PROMPT_DESCRIPTION = 'forms.groupSales.intro.loginPrompt.description',
  LOGIN_PROMPT_BUTTON_LABEL = 'forms.groupSales.intro.loginPrompt.button.label',
  OUTBOUND_TITLE = 'forms.groupSales.outbound.title',
  STATIONS_TITLE = 'forms.groupSales.stations.title',
  OUTBOUND_TIME_TITLE = 'forms.groupSales.outbound.time.title',
  OUTBOUND_PASSENGERS_TITLE = 'forms.groupSales.outbound.passengers.title',
  OUTBOUND_SEATS_TITLE = 'forms.groupSales.outbound.seats.title',
  OUTBOUND_TIME_LABEL = 'forms.groupSales.outbound.time.label',
  PASSENGERS_AMOUNT_LABEL = 'forms.groupSales.passengers.amount.label',
  PASSENGERS_AMOUNT_HELPER = 'forms.groupSales.passengers.amount.helper',
  SEATS_PREFERENCES_LABEL = 'forms.groupSales.seats.preferences.label',
  SEATS_PREFERENCES_PLACEHOLDER = 'forms.groupSales.seats.preferences.placeholder',
  ADD_RETURN = 'forms.groupSales.addReturn.label',
  REMOVE_RETURN = 'forms.groupSales.removeReturn.label',
  INBOUND_TITLE = 'forms.groupSales.inbound.title',
  INBOUND_PASSENGERS_TITLE = 'forms.groupSales.inbound.passengers.title',
  INBOUND_TIME_TITLE = 'forms.groupSales.inbound.time.title',
  INBOUND_TIME_LABEL = 'forms.groupSales.inbound.time.label',
  INBOUND_SEATS_TITLE = 'forms.groupSales.inbound.seats.title',

  ADDITIONAL_INFO_TITLE = 'forms.groupSales.additionalInfo.title',
  ADDITIONAL_INFO_DESCRIPTION = 'forms.groupSales.additionalInfo.description',
  ADDITIONAL_INFO_INPUT_LABEL = 'forms.groupSales.additionalInfo.input.label',

  GROUP_NAME_TITLE = 'forms.groupSales.groupName.title',
  GROUP_NAME_LABEL = 'forms.groupSales.groupName.input.label',

  SUBMIT_FAILURE_TITLE = 'forms.groupSales.submitted.failure.title',
  SUBMIT_FAILURE_BODY = 'forms.groupSales.submitted.failure.body',
  BACK_TO_FORM = 'forms.groupSales.backToForm',

  CONFIRM_OUTBOUND_JOURNEY = 'forms.groupSales.confirmOutboundJourney',
  CONFIRM_JOURNEY = 'forms.groupSales.confirmJourney',
  NO_PAYMENT_DISCLAIMER = 'forms.groupSales.noPaymentYetDisclaimer',
  ECO_DESCRIPTION = 'forms.groupSales.seatDescription.eco',
  EXTRA_DESCRIPTION = 'forms.groupSales.seatDescription.extra',
  UPSTAIRS_RESTAURANT_WAGON_DESCRIPTION = 'forms.groupSales.seatDescription.upstairsRestaurantWagon',
  FOR_ADDITIONAL_COST = 'forms.groupSales.forAdditionalCost',
  PASSENGERS_COUNT = 'booking.passengerCount',
  BED_COUNT = 'forms.groupSales.bedCount',
  SMALL_GROUP_INTRO_TITLE = 'forms.groupSales.smallGroup.intro.title',
  SMALL_GROUP_INTRO_DESCRIPTION = 'forms.groupSales.smallGroup.intro.description',
  GROUP_SALES_AVECRA_BANNER_TITLE = 'forms.groupSales.avecraBanner.title',
  GROUP_SALES_AVECRA_BANNER_BODY = 'forms.groupSales.avecraBanner.body',
}

export enum GroupSalesPassengerSelectStepMessage {
  DESCRIPTION = 'forms.groupSales.passengerSelectStep.description',
  DETAILS_READ_LESS = 'forms.groupSales.passengerSelectStep.details.readLess',
  DETAILS_READ_MORE = 'forms.groupSales.passengerSelectStep.details.readMore',
  NIGHTCABINS_ADULTS_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.adults.description',
  NIGHTCABINS_ADULTS_TITLE = 'forms.groupSales.passengerSelectStep.nightCabins.adults.title',
  NIGHTCABINS_CHILDREN_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.children.description',
  NIGHTCABINS_CHILDRENBETWEENFOURANDTENWITHSHAREDBERTH_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.childrenBetweenFourAndTenWithSharedBerth.description',
  NIGHTCABINS_CHILDRENUNDERFOURWITHSHAREDBERTH_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.childrenUnderFourWithSharedBerth.description',
  NIGHTCABINS_CHILDRENWITHBERTH_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.childrenWithBerth.description',
  NIGHTCABINS_CHILDRENWITHBERTH_TITLE = 'forms.groupSales.passengerSelectStep.nightCabins.childrenWithBerth.title',
  NIGHTCABINS_CHILDRENWITHSHAREDBERTH_TITLE = 'forms.groupSales.passengerSelectStep.nightCabins.childrenWithSharedBerth.title',
  NIGHTCABINS_WHEELCHAIR_ADULTS_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.wheelchair.adults.description',
  NIGHTCABINS_WHEELCHAIR_ADULTS_TITLE = 'forms.groupSales.passengerSelectStep.nightCabins.wheelchair.adults.title',
  NIGHTCABINS_WHEELCHAIR_BUTTON_LABEL = 'forms.groupSales.passengerSelectStep.nightCabins.wheelchair.button.label',
  NIGHTCABINS_WHEELCHAIR_CHILDREN_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.wheelchair.children.description',
  NIGHTCABINS_WHEELCHAIR_CHILDREN_TITLE = 'forms.groupSales.passengerSelectStep.nightCabins.wheelchair.children.title',
  NIGHTCABINS_WHEELCHAIR_DESCRIPTION = 'forms.groupSales.passengerSelectStep.nightCabins.wheelchair.description',
  SEARCHBUTTON_LABEL = 'forms.groupSales.passengerSelectStep.searchButton.label',
  SEATS_ADULTS_DESCRIPTION = 'forms.groupSales.passengerSelectStep.seats.adults.description',
  SEATS_ADULTS_TITLE = 'forms.groupSales.passengerSelectStep.seats.adults.title',
  SEATS_ADULTSWITHWHEELCHAIR_DESCRIPTION = 'forms.groupSales.passengerSelectStep.seats.adultsWithWheelchair.description',
  SEATS_CHILDREN_BUTTON_LABEL = 'forms.groupSales.passengerSelectStep.seats.children.button.label',
  SEATS_CHILDREN_DESCRIPTION = 'forms.groupSales.passengerSelectStep.seats.children.description',
  SEATS_CHILDREN_DETAILS = 'forms.groupSales.passengerSelectStep.seats.children.details',
  SEATS_CHILDRENWITHOUTSEAT_DESCRIPTION = 'forms.groupSales.passengerSelectStep.seats.childrenWithoutSeat.description',
  SEATS_CHILDRENWITHOUTSEAT_TITLE = 'forms.groupSales.passengerSelectStep.seats.childrenWithoutSeat.title',
  SEATS_CHILDRENWITHSEAT_DESCRIPTION = 'forms.groupSales.passengerSelectStep.seats.childrenWithSeat.description',
  SEATS_CHILDRENWITHSEAT_TITLE = 'forms.groupSales.passengerSelectStep.seats.childrenWithSeat.title',
  SEATS_CHILDRENWITHWHEELCHAIR_DESCRIPTION = 'forms.groupSales.passengerSelectStep.seats.childrenWithWheelchair.description',
  SEATS_CHILDRENWITHWHEELCHAIR_TITLE = 'forms.groupSales.passengerSelectStep.seats.childrenWithWheelchair.title',
  SEATS_WHEELCHAIR_ADULTS_TITLE = 'forms.groupSales.passengerSelectStep.seats.adultsWithWheelchair.title',
  TABGROUP_NIGHTCABINS = 'forms.groupSales.passengerSelectStep.tabGroup.nightCabins',
  TABGROUP_SEATS = 'forms.groupSales.passengerSelectStep.tabGroup.seats',
  TITLE = 'forms.groupSales.passengerSelectStep.title',
  TOTAL_PASSENGERS = 'forms.groupSales.passengerSelectStep.totalPassangers',
  VALIDATION_CHILDREN_WITHOUT_SEATS_MESSAGE = 'forms.groupSales.passengerSelectStep.validation.childrenWithoutSeats.message',
  VALIDATION_CHILDREN_WITHOUT_BERTH_MESSAGE = 'forms.groupSales.passengerSelectStep.validation.childrenWithoutBerth.message',
  WHEELCHAIR_DESCRIPTION = 'forms.groupSales.passengerSelectStep.wheelchair.description',
  WHEELCHAIR_DETAILS = 'forms.groupSales.passengerSelectStep.wheelchair.details',
  SUM_NOT_IN_RANGE = 'forms.groupSales.passengerSelectStep.sumNotInRange',
}

export enum GroupSalesStepNavMessage {
  ARIA_TITLE = 'forms.groupSales.stepNav.aria.title',
  ARIA_SELECT_PASSENGERS = 'forms.groupSales.stepNav.aria.selectPassengers',
  ARIA_SEARCH_OUTBOUND = 'forms.groupSales.stepNav.aria.search.outbound',
  ARIA_SEARCH_RETURN = 'forms.groupSales.stepNav.aria.search.return',
  ARIA_DETAILS_OUTBOUND = 'forms.groupSales.stepNav.aria.details.outbound',
  ARIA_DETAILS_RETURN = 'forms.groupSales.stepNav.aria.details.return',
  ARIA_CONTACT_DETAILS = 'forms.groupSales.stepNav.aria.contactDetails',
  ARIA_SUBMITTED = 'forms.groupSales.stepNav.aria.submitted',
}

export enum GroupSeatPreferenceMessage {
  ECO = 'forms.groupSales.seats.eco',
  EXTRA = 'forms.groupSales.seats.extra',
  WAGON = 'forms.groupSales.seats.wagon',
  TRAIN = 'forms.groupSales.seats.train',

  CABIN_1PAX = 'forms.groupSales.seats.cabin.pax1',
  CABIN_1PAX_WITH_SHOWER = 'forms.groupSales.seats.cabin.pax1.shower',
  CABIN_2PAX = 'forms.groupSales.seats.cabin.pax2',
  CABIN_2PAX_WITH_SHOWER = 'forms.groupSales.seats.cabin.pax2.shower',

  RUSSIA_VIP_2PAX = 'forms.groupSales.seats.cabin.pax2.vip',

  FIRST_CLASS = 'forms.groupSales.seats.firstClass',
  SECOND_CLASS = 'forms.groupSales.seats.secondClass',
}

export enum BreakfastMessage {
  TITLE = 'breakfast.title',
  SUMMARY_TITLE = 'breakfast.summary.title',
  SUB_TITLE = 'breakfast.subTitle',
  SUMMARY_SUB_TITLE = 'breakfast.summary.subtitle',
  BEFORE_PURCHASE_INFO = 'breakfast.beforePurchaseInfo',
  AFTER_PURCHASE_INFO = 'breakfast.afterPurchaseInfo',
  BUTTON_LABEL = 'breakfast.buttonLabel',
  DELIVERY_TITLE = 'breakfast.deliveryTitle',
  MODIFY_BUTTON = 'breakfast.modifyButton',

  ERROR_FAILED_TO_LOAD_SESSION_TITLE = 'breakfast.failedToLoadSession.title',
  ERROR_FAILED_TO_LOAD_SESSION_DESCRIPTION = 'breakfast.failedToLoadSession.description',

  ERROR_FAILED_TO_CREATE_SESSION_TITLE = 'breakfast.failedToCreateSession.title',
  ERROR_FAILED_TO_CREATE_SESSION_DESCRIPTION = 'breakfast.failedToCreateSession.description',

  DELIVERY_METHOD = 'breakfast.deliveryMethod',
}

export enum ProfilePurchaseFlowMessage {
  PAYMENT_TITLE = 'profile.purchaseFlow.paymentTitle',
  SUMMARY_TITLE = 'profile.purchaseFlow.summaryTitle',
  BACK_TO_JOURNEY = 'profile.purchaseFlow.backToJourney',
  BREAKFAST_TAG = 'profile.purchaseFlow.newBreakfastTag',
  CHANGE_DEPARTURE_TAG = 'profile.purchaseFlow.newDepartureTag',
  CHANGE_SEAT_TAG = 'profile.purchaseFlow.newSeatTag',
  ADD_VEHICLES_TAG = 'profile.purchaseFlow.newVechiclesTag',
  ADD_BICYCLE_TAG = 'profile.purchaseFlow.newBicyclesTag',
}

export enum GroupSalesPurchaseFlowMessage {
  GROUP_TICKET = 'groupSales.purchaseFlow.groupTicket',
  GROUP_SALES_PAYMENT_PAGE_TITLE = 'groupSales.purchaseFlow.paymentPageTitle',
  PRE_PAYMENT_TITLE = 'groupSales.purchaseFlow.prePaymentTitle',
  FINAL_PAYMENT_TITLE = 'groupSales.purchaseFlow.finalPaymentTitle',
  FULL_PAYMENT_TITLE = 'groupSales.purchaseFlow.fullPaymentTitle',
  GROUP_TICKET_PRODUCT_NAME = 'receipt.type.group_ticket',
  PRE_PAYMENT_PRICE_SUMMARY_SUB_ROW = 'receipt.group_ticket.prePaymentSummarySubRow',
  FINAL_PAYMENT_PRICE_SUMMARY_SUB_ROW = 'receipt.group_ticket.finalPaymentSummarySubRow',
  ALL_PAYMENT_PRICE_SUMMARY_SUB_ROW = 'receipt.group_ticket.allPaymentSummarySubRow',
  PURCHASE_SUMMARY_TITLE_FOR_PRE_PAYMENT = 'groupSales.summaryPage.titleForPrePayment',
  PURCHASE_SUMMARY_TITLE_FOR_FINAL_PAYMENT = 'groupSales.summaryPage.titleForFinalPayment',
  YOU_CAN_SEE_GROUP_TICKET_AFTER_FINAL_PAYMENT = 'groupSales.orderView.youCanSeeGroupTicketAfterFinalPayment',
  MEALS_DELIVERY_TITLE = 'groupSales.meals.deliveryTitle',
  MEALS_DELIVERY_INFO = 'groupSales.meals.deliveryInfo',
  WARM_MEALS_DELIVERY_TITLE = 'groupSales.warmMeals.deliveryTitle',
  WARM_MEALS_DELIVERY_INFO = 'groupSales.warmMeals.deliveryInfo',
}
export enum StationScheduleMessage {
  VIEW_ON_RADALLA = 'stationSchedule.radallaLink.description',
  RADALLA_LINK_LABEL = 'stationSchedule.radallaLink.label',
  TITLE = 'stationSchedule.title',
}

export enum TrainMapMessage {
  STATION = 'trainMap.station',
  DEPARTURE = 'trainMap.departing',
  ARRIVAL = 'trainMap.arriving',
  COMMUTER_TRAIN = 'trainMap.commuterTrain',
  SCHEDULE_ON_TIME = 'trainMap.schedule.onTime',
  SCHEDULE_LATE = 'trainMap.schedule.late',
  DEPARTS_AT = 'trainMap.station.table.departsAt',
  ARRIVES_AT = 'trainMap.station.table.arrivesAt',
  DEPARTURE_STATION = 'trainMap.station.table.departureStation',
  ARRIVAL_STATION = 'trainMap.station.table.arrivalStation',
  TRACK = 'trainMap.station.table.track',
  READ_MORE_ABOUT_YOUR_RIGHTS = 'trainMap.trainModal.readAboutYourRights',
  CANCELLED = 'trainMap.cancelled',
  ERROR = 'trainMap.error',
  ERROR_FAILED_TO_LOAD_TIMETABLE = 'trainMap.station.table.failedToLoadTimetable',
  SEARCH_PLACEHOLDER = 'trainMap.search.placeHolder',
  NO_TIMETABLE_DATA = 'trainMap.station.noTimetableData',
  NO_DEPARTING_TRAINS = 'trainMap.station.noDepartingTrains',
  NO_ARRIVING_TRAINS = 'trainMap.station.noArrivingTrains',
  COUNT_TRAINS = 'trainMap.cluster.trainCount',
  SHOW_INFORMATION_ABOUT_TRAIN = 'trainMap.showInformationAbout.train',
  SHOW_INFORMATION_ABOUT_STATION = 'trainMap.showInformationAbout.station',
  NO_USER_LOCATION = 'trainMap.userLocationError',
  VIA = 'trainMap.via',
  FIND_CLOSEST = 'trainMap.findClosest',
  CLOSEST_TRAINS = 'trainMap.closestTrains',
  CLOSEST_STATIONS = 'trainMap.closestStations',
  HAS_NOT_DEPARTED = 'trainMap.train.hasNotDeparted',
  ARRIVED = 'trainMap.train.arrived',
  NEXT_STATION = 'trainMap.train.nextStation',
  AT_STATION = 'trainMap.train.atStation',
  SPEED = 'trainMap.train.speed',
  LOCATION_TRACKING_FAILURE = 'trainMap.train.locationFailure',
  TRAIN_TIME_TABLE_TITLE = 'trainMap.train.timetableTitle',
  NO_TRAIN_AVAILABLE = 'trainMap.train.noTrainOnThisDate',
  TRAINS_ON_A_MAP = 'trainMap.trainsOnAMap',
  COMMUTE_DEPARTURES = 'trainMap.search.commute.departures',
  COMMUTE_ARRIVALS = 'trainMap.search.commute.arrivals',
  TRAIN_COMMUTE_COUNT = 'trainMap.search.commuteCount',
  FILTERS_TITLE = 'trainMap.filters.title',
  FILTERS_TRAIN_TYPE_ALL = 'trainMap.filters.trainType.all',
  FILTERS_TRAIN_TYPE_ONLY_LONG_DISTANCE = 'trainMap.filters.trainType.onlyLongDistance',
  FILTERS_TRAIN_TYPE_ONLY_COMMUTER = 'trainMap.filters.trainType.onlyCommuter',
  DO_YOU_HAVE_CONCERNS_TITLE = 'trainMap.notices.doYouHaveConcerns.title',
  DO_YOU_HAVE_CONCERNS_BODY = 'trainMap.notices.doYouHaveConcerns.body',
  LESS_THAN_AN_HOUR_TITLE = 'trainMap.notices.lessThanOneHour.title',
  LESS_THAN_AN_HOUR_BODY = 'trainMap.notices.lessThanOneHour.body',
  MORE_THAN_AN_HOUR_TITLE = 'trainMap.notices.moreThanOneHour.title',
  MORE_THAN_AN_HOUR_BODY = 'trainMap.notices.moreThanOneHour.body',
  FILTERS_NO_RESULTS = 'trainMap.filters.noResults',
  SEARCH_LABEL = 'trainMap.search.label',
  RECENT_STATIONS_TITLE = 'trainMap.search.recentStationsTitle',
  SHOW_MAP = 'trainMap.showMap',
  CLOSE_MAP = 'trainMap.closeMap',
  MY_TRIP_TITLE = 'trainMap.myTrip.title',
  TRAIN_STATUS_ARRIVED = 'trainMap.trainStatus.arrived',
  TRAIN_STATUS_CANCELLED = 'trainMap.trainStatus.cancelled',
  TRAIN_STATUS_DELAYED = 'trainMap.trainStatus.delayed',
  TRAIN_STATUS_ONGOING = 'trainMap.trainStatus.ongoing',
  TRANSFER_NOTICE_TITLE = 'trainMap.transferNotice.title',
  TRANSFER_NOTICE_BODY = 'trainMap.transferNotice.body',
  LOAD_FAILED_TITLE = 'trainMap.loadFailed.title',
  LOAD_FAILED_BODY = 'trainMap.loadFailed.body',
  COMMERCIAL_TRAIN = 'trainMap.commercialTrains',
  SCHEDULED_STOP_TIME = 'trainMap.scheduledStopTime',
  NEW_STOP_TIME = 'trainMap.newStopTime',
  PRESS_KEY_TO_SCROLL = 'trainMap.pressKeyToScroll',
  USE_TWO_FINGERS_TO_SCROLL = 'trainMap.useTwoFingersToScroll',
}

export enum CarbonCalculatorMessage {
  YOUR_TRAVEL = 'carbonCalculator.yourTravel',
  BUS_DATA = 'carbonCalculator.busData',
  PLANE_DATA = 'carbonCalculator.planeData',
  TRAIN_DATA = 'carbonCalculator.trainData',
  CAR_DATA = 'carbonCalculator.carData',
  SELECT_LABEL = 'carbonCalculator.select.label',
  NO_FLIGHT_DATA_AVAILABLE = 'carbonCalculator.error.noFlightData',
  USING_TRAIN_INSTEAD_OF = 'carbonCalculator.trainInsteadOf',
  SAVES_EMISSIONS_IN_ROUNDTRIP = 'carbonCalculator.savesEmissionsRound',
  SAVES_EMISSIONS_IN_ONE_WAY = 'carbonCalculator.savesEmissionsOneWay',
  SAVED_BURGERS = 'carbonCalculator.savedBurgers',
  SAVED_GLACIERS = 'carbonCalculator.savedGlaciers',
  ROUND_TRIP = 'carbonCalculator.generic.roundTrip',
  COMPARED_TO_PLANE = 'carbonCalculator.comparedTo.plane',
  COMPARED_TO_BUS = 'carbonCalculator.comparedTo.bus',
  COMPARED_TO_CAR = 'carbonCalculator.comparedTo.car',
}

export enum ReCaptchaMessage {
  ERROR_TITLE = 'recaptcha.error.title',
  ERROR_DESCRIPTION = 'recaptcha.error.description',
  REQUIRED_BRANDING = 'recaptcha.requiredBranding',
  RECAPTCHA_FAILED_FORM_SUBMIT_DESCRIPTION = 'recaptcha.failedFormSubmit.description',
}

export enum ContentComponentsMessage {
  NORMAL_PRICE = 'menuList.normalPrice',
  MATKALLA_PRICE = 'menuList.matkallaPrice',
}

export enum JourneyPlannerMessage {
  TITLE = 'journeyPlanner.title',
  SUBTITLE = 'journeyPlanner.subtitle',
  DESTINATION = 'journeyPlanner.destination',
  DEPARTURE = 'journeyPlanner.departure',
  NO_CONNECTIONS_FROM = 'journeyPlanner.noConnectionsFrom',
  NO_CONNECTIONS_TO = 'journeyPlanner.noConnectionsTo',
}

export enum TravelAgentMessage {
  IS_BUSINESS_TRIP = 'travelAgent.isBusinessTrip',
  SELECT_BUSINESS = 'travelAgent.selectBusiness',
  SELECT_BUSINESS_LABEL = 'travelAgent.selectBusiness.inputLabel',
  SEARCH_FAILED = 'travelAgent.searchFailed',
  FAILED_TO_FIND_COMPANY = 'travelAgent.failedToFindCompany',
  ON_BEHALF_OF_TITLE = 'travelAgent.onBehalfOfTitle',
  BUSINESS_ID = 'travelAgent.businessId',
}

export enum ProfileSelectionMessage {
  ADMIN_TOOL_LINK_TEXT = 'profileSelection.adminToolLinkText',
  CODE_GENERATOR_LINK_TEXT = 'profileSelection.codeGeneratorLinkText',
  REPORT_LINK = 'profileSelection.reportLink',
  COMPANY_PROFILE = 'profileSelection.companyProfile',
  COMPANY_PROFILES_DESCRIPTION = 'profileList.description',
  COMPANY_PROFILES_TITLE = 'profileList.title',
  CUSTOMER_PROFILE = 'profileSelection.customerProfile',
  GREETING = 'generic.greeting',
  IS_JOURNEY_MISSING_DESCRIPTION = 'profileSelection.isProfileMissingNotice.description',
  IS_JOURNEY_MISSING_TITLE = 'profileSelection.isProfileMissingNotice.title',
  JOURNEYS_LINK_TEXT = 'profileSelection.journeysLinkText',
  PURCHASE_FLOW_WARNING = 'profileSelection.purchaseFlow.profileChangeWarning',
  SELECT_PROFILE_TO_START = 'profileSelection.selectProfileToStart',
  TRAVEL_AGENT_PROFILE = 'profileSelection.travelAgentProfile',
  COMPANY = 'profileSelection.company',
  NO_PURCHASE_RIGHT = 'profileSelection.noPurchaseRight',
  PROFILE_TOOLTIP_TITLE = 'profileSelection.tooltip.title',
  PROFILE_TOOLTIP_DESCRIPTION = 'profileSelection.tooltip.description',
}

export enum BusConnectionsMessage {
  SELECT_DESTINATION_BEFORE = 'busConnections.selectDestination.before',
  SELECT_DESTINATION_AFTER = 'busConnections.selectDestination.after',
  SELECT_OUTBOUND_TITLE = 'busConnections.selectOutboundTitle',
  SELECT_RETURN_TITLE = 'busConnections.selectReturnTitle',
  SKIP_BUTTON = 'busConnections.skipButton',
  DESCRIPTION = 'busConnections.description',
  CONFIRM_BUTTON = 'busConnections.confirmButton',
  CONFIRM_WITHOUT_BUS_BUTTON = 'busConnections.confirmWithoutBusButton',
  REMOVE_CONNECTION = 'busConnections.removeConnection',
  NOT_FOUND_ERROR_TITLE = 'busConnections.search.connectionsNotFoundErrorTitle',
  NOT_FOUND_ERROR_DESCRIPTION = 'busConnections.search.connectionsNotFoundErrorDescription',
  UNEXPECTED_ERROR_TITLE = 'busConnections.search.unexpectedErrorTitle',
  UNEXPECTED_ERROR_DESCRIPTION = 'busConnections.search.unexpectedErrorDescription',
  SCHEDULES_NOT_AVAILABLE_TITLE = 'busConnections.schedulesNotAvailable.title',
  SCHEDULES_NOT_AVAILABLE_BODY = 'busConnections.schedulesNotAvailable.body',
  THIS_BUS_HAS_RESERVATION_FOR_BICYCLE = 'busConnections.thisBusHasReservationForBicycle',
  ADDING_BUS_OFFER_FAILED_TITLE = 'busConnections.addingBusOfferFailedTitle',
  ADDING_BUS_OFFER_FAILED_BODY = 'busConnections.addingBusOfferFailedBody',
  REMOVING_BUS_OFFER_FAILED_TITLE = 'busConnections.removingBusOfferFailedTitle',
  REMOVING_BUS_OFFER_FAILED_BODY = 'busConnections.removingBusOfferFailedBody',
  TOTAL_PRICE = 'busConnections.summary.TotalPrice',
  FACILITIES_LABEL = 'busConnections.facilities.label',
  FACILITIES_WC = 'busConnections.facilities.wc',
  FACILITIES_WIFI = 'busConnections.facilities.wifi',
  FACILITIES_AC = 'busConnections.facilities.ac',
  FACILITIES_POWER_PLUG = 'busConnections.facilities.powerPlug',
  FACILITIES_PETS = 'busConnections.facilities.pets',
  DESTINATIONS_BEFORE = 'busConnections.destinations.before',
  DESTINATIONS_AFTER = 'busConnections.destinations.after',
  PAYMENT_TOO_CLOSE_TITLE = 'busConnections.paymentTooClose.title',
  PAYMENT_TOO_CLOSE_BODY = 'busConnections.paymentTooClose.body',
  EXPIRING_PAYMENT_NOTIFICATION_TITLE = 'busConnections.expiringPaymentNotification.title',
  EXPIRING_PAYMENT_NOTIFICATION_BODY = 'busConnections.expiringPaymentNotification.body',
  EXPIRING_PAYMENT_NOTIFICATION_BODY_SOON = 'busConnections.expiringPaymentNotification.bodySoon',
  MULTIPLE_OPERATORS = 'busConnections.multipleOperators',
}

export enum DiscountCodeMessage {
  OPEN_DISCOUNT_CODE_MODAL = 'discountCode.openModal',
  CODE_MODAL_TITLE = 'discountCode.modal.title',
  CODE_INPUT_LABEL = 'discountCode.modal.inputLabel',
  ERROR_CODE_NOT_FOUND = 'discountCode.error.notFound',
  ERROR_TOO_MANY_USAGES = 'discountCode.error.tooManyUsages',
  ERROR_CODE_USED = 'discountCode.error.used',
  ERROR_NOT_VALID = 'discountCode.error.notValid',
  ERROR_MUST_BE_LOGGED_IN = 'discountCode.error.mustBeLoggedIn',
}

export enum B2BAdminCompanyInfoMessage {
  Title = 'b2badmin.companyInfo.title',
  Name = 'b2badmin.companyInfo.name',
  Name2 = 'b2badmin.companyInfo.name2',
  CompanyId = 'b2badmin.companyInfo.companyId',
  Address = 'b2badmin.companyInfo.address',
  City = 'b2badmin.companyInfo.city',
  Country = 'b2badmin.companyInfo.country',
  PostalCode = 'b2badmin.companyInfo.postalCode',
  POBox = 'b2badmin.companyInfo.poBox',
  Department = 'b2badmin.companyInfo.department',
  Department2 = 'b2badmin.companyInfo.department2',
  BusinessId = 'b2badmin.companyInfo.businessId',
}

export enum B2BAdminInvoicingInfoMessage {
  InfoTitle = 'b2badmin.invoicingInfo.infoTitle',
  MethodTitle = 'b2badmin.invoicingInfo.methodTitle',
  ReferenceNo = 'b2badmin.invoicingInfo.referenceNo',
  CompanyName = 'b2badmin.invoicingInfo.companyName',
  CompanyName2 = 'b2badmin.invoicingInfo.companyName2',
  EInvoiceAddress = 'b2badmin.invoicingInfo.eInvoiceAddress',
  Email = 'b2badmin.invoicingInfo.email',
  Interval = 'b2badmin.invoicingInfo.interval',
  Interval_EVERY_DAY = 'b2badmin.invoicingInfo.INTERVAL_EVERY_DAY',
  Interval_EVERY_FRIDAY = 'b2badmin.invoicingInfo.INTERVAL_EVERY_FRIDAY',
  Interval_15TH_AND_LAST_DAY_OF_MONTH = 'b2badmin.invoicingInfo.INTERVAL_15TH_AND_LAST_DAY_OF_MONTH',
  Interval_1ST_OF_JANUARY_AND_1ST_OF_JULY = 'b2badmin.invoicingInfo.INTERVAL_1ST_OF_JANUARY_AND_1ST_OF_JULY',
  Interval_LAST_DAY_OF_MONTH = 'b2badmin.invoicingInfo.INTERVAL_LAST_DAY_OF_MONTH',
  Interval_NO_INVOICING = 'b2badmin.invoicingInfo.INTERVAL_NO_INVOICING',
  Language = 'b2badmin.invoicingInfo.language',
  Language_fi = 'b2badmin.invoicingInfo.language.fi',
  Language_en = 'b2badmin.invoicingInfo.language.en',
  PaymentTerm = 'b2badmin.invoicingInfo.paymentTerm',
  PaymentTerm_Z00N = 'b2badmin.invoicingInfo.paymentTerm.Z00N',
  PaymentTerm_Z07N = 'b2badmin.invoicingInfo.paymentTerm.Z07N',
  PaymentTerm_Z14N = 'b2badmin.invoicingInfo.paymentTerm.Z14N',
  PaymentTerm_Z21N = 'b2badmin.invoicingInfo.paymentTerm.Z21N',
  PaymentTerm_Z30N = 'b2badmin.invoicingInfo.paymentTerm.Z30N',
  PaymentTerm_Z35N = 'b2badmin.invoicingInfo.paymentTerm.Z35N',
  PaymentTerm_Z45N = 'b2badmin.invoicingInfo.paymentTerm.Z45N',
  PaymentTerm_Z60N = 'b2badmin.invoicingInfo.paymentTerm.Z60N',
  PaymentTerm_Z90N = 'b2badmin.invoicingInfo.paymentTerm.Z90N',
  Method = 'b2badmin.invoicingInfo.method',
  Method_EINVOICE = 'b2badmin.invoicingInfo.method.EINVOICE',
  Method_EMAIL = 'b2badmin.invoicingInfo.method.EMAIL',
}

export enum B2BAdminFormMessage {
  InvalidValue = 'b2badmin.form.invalidValue',
  TooLongValue = 'b2badmin.form.tooLongValue',
  StreetOrPoRequired = 'b2badmin.form.streetOrPoRequired',
}

export enum B2BAdminUsersMessage {
  NotFound = 'b2badmin.users.notFound',
  Users = 'b2badmin.users.users',
  Role_TRAVEL_AGENT_ADMIN = 'b2badmin.users.role.TRAVEL_AGENT_ADMIN',
  Role_FORTE_TRAVEL_AGENCY_CLERK = 'b2badmin.users.role.FORTE_TRAVEL_AGENCY_CLERK',
  Role_B2B_ADMIN = 'b2badmin.users.role.B2B_ADMIN',
  Role_FORTE_CORPORATE_USER = 'b2badmin.users.role.FORTE_CORPORATE_USER',
  Role_FORTE_DISCOUNT_CODE_MANAGER = 'b2badmin.users.role.FORTE_DISCOUNT_CODE_MANAGER',
  Role_B2B_REPORT_VIEWER = 'b2badmin.users.role.B2B_REPORT_VIEWER',
  InviteNew = 'b2badmin.users.inviteNew',
  Email = 'b2badmin.users.email',
  CompanyRole = 'b2badmin.users.companyRole',
  WaitingForUserToAccept = 'b2badmin.users.waitingForUserToAccept',
  AddRole = 'b2badmin.users.addRole',
  AttachedRoles = 'b2badmin.users.attachedRoles',
  RemoveSuccessTitle = 'b2badmin.users.removeSuccess.title',
  RemoveSuccessText = 'b2badmin.users.removeSuccess.text',
  RemoveAdminRight = 'b2badmin.users.removeAdminRight',
  RemoveUser = 'b2badmin.users.removeUser',
  Status_ACTIVE = 'b2badmin.users.status.ACTIVE',
  Status_INVITED = 'b2badmin.users.status.INVITED',
  Status_INACTIVE = 'b2badmin.users.status.INACTIVE',
  Status_INVITATION_EXPIRED = 'b2badmin.users.status.INVITATION_EXPIRED',
  AddModalTitle = 'b2badmin.users.addModal.title',
  SendInvitation = 'b2badmin.users.sendInvitation',
  SelectRoles = 'b2badmin.users.selectRoles',
  SelectLanguage = 'b2badmin.users.selectLanguage',
  InvitedRolesExistTitle = 'b2badmin.users.invitedRolesExist.title',
  InvitedRolesExistBody = 'b2badmin.users.invitedRolesExist.body',
}

export enum B2BAdminRemoveUserModalMessage {
  TitleTraveller = 'b2badmin.users.removeModal.traveller.title',
  TitleAdmin = 'b2badmin.users.removeModal.admin.title',
  Warning = 'b2badmin.users.removeModal.warning',
  WarningDataRemoval = 'b2badmin.users.removeModal.traveller.warningDataRemoval',
  WarningAdminRoleRevoked = 'b2badmin.users.removeModal.traveller.warningAdminRoleRevoked',
  DeleteUser = 'b2badmin.users.removeModal.otherRoles',
  DeleteAlsoRole = 'b2badmin.users.removeModal.deleteAlsoRole',
}

export enum B2BAdminGenericMessage {
  Save = 'b2badmin.save',
  Company = 'b2badmin.company',
  NextPage = 'b2badmin.nextPage',
  PrevPage = 'b2badmin.prevPage',
  ErrorOccurred = 'b2badmin.errorOccurred',
  Show = 'b2badmin.show',
  Cancel = 'b2badmin.cancel',
  Edit = 'b2badmin.edit',
  ReportLoadFailed = 'b2badmin.reportLoadFailed',
  NoReports = 'b2badmin.noReports',
}

export enum B2BAdminTravelCodeMessage {
  TabLabel = 'b2badmin.travelCodes.tabLabel',
  CreateCodesButtonLabel = 'b2badmin.travelCodes.createCodes.label',
  CreateCodesButtonDescription = 'b2badmin.travelCodes.createCodes.description',
  RemoveCodesButtonLabel = 'b2badmin.travelCodes.removeCodes.label',
  RemoveCodesButtonDescription = 'b2badmin.travelCodes.removeCodes.description',
}

export enum B2BAdminCreateCodesMessage {
  Title = 'b2badmin.createCodes.title',
  Description = 'b2badmin.createCodes.description',
  CodesLabel = 'b2badmin.createCodes.codesLabel',
  ValidityLabel = 'b2badmin.createCodes.validityLabel',
  CreatingCodes = 'b2badmin.createCodes.creatingCodes',
  CodesGenerated = 'b2badmin.createCodes.codesGenerated',
  Amount = 'b2badmin.createCodes.amount',
  MaxAmount = 'b2badmin.createCodes.maxAmount',
  TooManyCodesError = 'b2badmin.createCodes.tooManyCodesError',
  EndDateBeforeStart = 'b2badmin.createCodes.endDateBeforeStart',
  StartDate = 'b2badmin.createCodes.startDate',
  EndDate = 'b2badmin.createCodes.endDate',
  UsageType = 'b2badmin.createCodes.usageType',
  UsageType_SINGLE_USE = 'b2badmin.createCodes.usageType.SINGLE_USE',
  UsageType_MULTI_USE = 'b2badmin.createCodes.usageType.MULTI_USE',
  GeneratingTitle = 'b2badmin.createCodes.generating.title',
  GeneratingText = 'b2badmin.createCodes.generating.text',
  Button = 'b2badmin.createCodes.button',
  ErrorTitle = 'b2badmin.createCodes.error.title',
  ErrorText = 'b2badmin.createCodes.error.text',
  NotificationTitle = 'b2badmin.createCodes.notification.title',
  NotificationText = 'b2badmin.createCodes.notification.text',
  CodeCount = 'b2badmin.createCodes.codeCount',
  Download = 'b2badmin.createCodes.download',
  CreateMore = 'b2badmin.createCodes.createMore',
  Created = 'b2badmin.createCodes.created',
}

export enum B2BAdminRemoveCodesMessage {
  Title = 'b2badmin.removeCodes.title',
  Description = 'b2badmin.removeCodes.description',
  Info = 'b2badmin.removeCodes.info',
  Button = 'b2badmin.removeCodes.button',
  CodeCount = 'b2badmin.removeCodes.codeCount',
  ReadErrorTitle = 'b2badmin.removeCodes.readError.title',
  ReadErrorText = 'b2badmin.removeCodes.readError.text',
  NoCodesFoundTitle = 'b2badmin.removeCodes.noCodesFound.title',
  NoCodesFoundText = 'b2badmin.removeCodes.noCodesFound.text',
  InvalidFileTitle = 'b2badmin.removeCodes.invalidFile.title',
  InvalidFileText = 'b2badmin.removeCodes.invalidFile.text',
  TooManyCodesTitle = 'b2badmin.removeCodes.tooManyCodes.title',
  TooManyCodesText = 'b2badmin.removeCodes.tooManyCodes.text',
  CodesRemoved = 'b2badmin.removeCodes.codesRemoved',
  RemoveMore = 'b2badmin.removeCodes.removeMore',
  RequestFailedTitle = 'b2badmin.removeCodes.requestFailed.title',
  RequestFailedText = 'b2badmin.removeCodes.requestFailed.text',
  ConfirmTitle = 'b2badmin.removeCodes.confirm.title',
  ConfirmText = 'b2badmin.removeCodes.confirm.text',
  ConfirmButton = 'b2badmin.removeCodes.confirm.button',
}

export enum DynamicMapMessage {
  WifiQosDescription = 'wifiQos.description',
  WifiQosGood = 'wifiQos.good',
  WifiQosMeasurementInfoText = 'wifiQos.measurementInfo.text',
  WifiQosMeasurementInfoTitle = 'wifiQos.measurementInfo.title',
  WifiQosSelectorShowInfo = 'wifiQos.showInfo',
  WifiQosSlow = 'wifiQos.slow',
  WifiQosVarying = 'wifiQos.varying',
}
